import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import createHistory from 'history/createBrowserHistory'
import 'react-accessible-accordion/dist/fancy-example.css';

import BasicButton from '../../component/atom/basicButton';
import Header from '../../component/header/Header'
import Footer from '../../component/footer/Footer'
import Loading from '../../component/loading/Loading';

import { useAlert } from "react-alert";

import { oder_confirmation } from '../../util/order_Confirmation';
import { findCart_Detail } from '../../util/findCartdetail';
import { getCart, cartCreation, cart_switch_address, cartSubmit, cart_dueDate_update } from '../../Context/actions/cart/cart';
import {
    placeOrder1,
    afsMethod, sectionMethod, paymentRequestMethod
} from '../../Context/actions/payment/payment';
import { GlobalContext } from '../../Context/Provider';
import { BASE } from '../../api/api';

const Confirmation = () => {

    const alert = useAlert();
    const [loading, setLoading] = useState(false)
    const historys = createHistory()
    let count = 0
    let history = useHistory()
    const { push } = useHistory()

    const {
        addressDispatch,
        addressState: {
            address: { address, deladdress },
        },
        cartDispatch,
        cartState: {
            cart: { cart_status, cart_loading, cartname, getcart, get_data },
        },
        paymentDispatch,
        paymentState: {
            payment: { pay_loading }
        }
    } = useContext(GlobalContext);

    const [html, sethtml] = useState('')
    useEffect(() => {
        getCart()(cartDispatch);
        const script = document.createElement("script");
        script.src = "https://afs.gateway.mastercard.com/checkout/version/61/checkout.js";
        script.async = true;
        document.head.appendChild(script);
    }, []);
    useEffect(async () => {
        if (get_data) {
            // console.log("confirmation page is loading", state.confirmation.message.doc)
            sethtml(get_data.shipping_address)
        }
    });


    const cartcheck = ({ cart_detail }) => {
        getCart()(cartDispatch)
            .catch(e => {
                console.log('part b success full')

                if (e == "GETCART-FAIL-NO-OBJECT") {
                    console.log('part c success full')

                    findCart_Detail(cart_detail)
                        .then(async (e) => {
                            console.log('part d success full')

                            let Item_Array = e.map(
                                ({ item_code, qty }) => ({
                                    item_code: item_code,
                                    qty: qty
                                }),
                            );
                            console.log('item_array -->', Item_Array)
                            cartCreation({ group_of_item: Item_Array })(cartDispatch)
                                .then(e => {
                                    console.log('part f success full')

                                    let address = localStorage.getItem('shipment_name -->')
                                    cart_switch_address({ shipment_name: address, qtn: e, customer_name: localStorage.getItem('user_name') })(cartDispatch)
                                        .then(r => {
                                            console.log('part g success full')

                                            console.log('confirmation is commented ')
                                            localStorage.removeItem('payment_detail')

                                            // Confirmation()
                                            //     .catch(console.error('switch-address function fail in Context'))
                                        })
                                        .catch(console.error('switch-address function fail in Context'))
                                })
                                .catch(e => {
                                    console.error('creat Creatin failed')
                                })
                            // localStorage.removeItem('payment_detail')

                        })
                        .catch(e => console.error('error in finddetailof cart', e))

                }

            })
            .catch(e => {
                console.error('get cart error')
            })
    }


    // useEffect(async () => {
    //     await getCart()(cartDispatch)
    //         .catch(e => {
    //             if (e == "GETCART-FAIL-NO-OBJECT") {
    //                 console.log('heleo')
    //                 let a = JSON.parse(localStorage.getItem('payment_detail'))
    //                 console.log('aaaa', a)
    //                 // if (get_data.items == '' || get_data.items == undefined || get_data.items == []) {
    //                 if (a) {
    //                     console.log('condition one true')
    //                     if (a[0].cart_item) {
    //                         console.log('condition two true')

    //                         oder_confirmation(a[0].order_id).then(e => e == 'ERROR' ? (
    //                             setLoading(true),
    //                             findCart_Detail(a[0].cart_item)
    //                                 .then(async (e) => {


    //                                     console.log('value s are -->', e)
    //                                     let Item_Array = e.map(
    //                                         ({ item_code, qty }) => ({
    //                                             item_code: item_code,
    //                                             qty: qty
    //                                         }),
    //                                     );
    //                                     console.log('item_array -->', Item_Array)
    //                                     cartCreation({ group_of_item: Item_Array })(cartDispatch)
    //                                         .then(e => {
    //                                             let address = localStorage.getItem('shipment_name -->')
    //                                             cart_switch_address({ shipment_name: address, qtn: e, customer_name: localStorage.getItem('user_name') })(cartDispatch)
    //                                                 .then(r => {
    //                                                     console.log('confirmation is commented ')
    //                                                     localStorage.removeItem('payment_detail')

    //                                                     // Confirmation()
    //                                                     //     .catch(console.error('switch-address function fail in Context'))
    //                                                 })
    //                                                 .catch(console.error('switch-address function fail in Context'))
    //                                         })
    //                                         .then(e => {
    //                                             setLoading(false)
    //                                         })
    //                                         .catch(e => {
    //                                             setLoading(false)
    //                                         })
    //                                     // localStorage.removeItem('payment_detail')

    //                                 })
    //                                 .catch(e => console.log('error in finddetailof cart', e))
    //                         )
    //                             :
    //                             getCart()(cartDispatch)
    //                         )
    //                     }
    //                 }

    //             }

    //         })
    //         .catch(e => {
    //             console.log('get cart error')
    //         })


    // }, [window.location.pathname])

    const payFunc = () => {
        let cart__name = cartname ? cartname : localStorage.getItem('cart_name')
        console.log('cart ---- name -->', cart__name)
        cart_dueDate_update()(cartDispatch)
            .then(e => {

                cartSubmit({ qtn: cart__name })(cartDispatch)
                    .then(e => {
                        placeOrder1({ qtn: cart__name, price: get_data.total })(paymentDispatch)
                            .then(e => {
                                paymentRequestMethod(e)(paymentDispatch)
                                    .then(e =>
                                        sectionMethod(e, { cart__name })(paymentDispatch)
                                            .then(e => afsMethod(e)(paymentDispatch))
                                            .catch(e => alert.error("Server is Busy ,Please try again later"))
                                    )
                                    .catch(e => {
                                        cartcheck({ cart_detail: cart__name })
                                        alert.error("Server is Busy ,Please try again later")
                                    })
                            })
                            .catch(e => {
                                cartcheck({ cart_detail: cart__name })

                                alert.error("Server is Busy ,Please try again later")
                            })
                    })
                    .catch(e => {
                        cartcheck({ cart_detail: cart__name })

                        alert.error("Server is Busy ,Please try again later")
                    })

            })
            .catch(e => {
                alert.error("Server is Busy ,Please try again later")
            })


    }





    return (
        <div>
            <Header />
            {
                loading ?
                    <div>
                        <label>Loading</label>
                    </div>
                    :
                    <div id="profile-edit" className="profile-edit">
                        {/* <p className="container mt-3 small-normal pad-0">
                        Manage all the shipping addresses you want (work place, home address ...) This way you won't have to enter the shipping address manually with each order.
                    </p> */}
                        <div className="shipping-list-container container ">
                            <h3 className="h3-bold  border-1  border-dark border-bottom pb-2">Order Confirmation </h3>
                            <div className=" pad-0 mob py-4">
                                <div className="border-1  border-black  border p-4 rounded text-uppercase">
                                    <h6 className="bolder h4-bold ">ShippingAddress</h6>
                                    {/* <p>{ReactHtmlParser(html)}</p>; */}
                                    {get_data.shipping_address ?
                                        (<div>
                                            <span>{get_data.shipping_address_name.replace(/-Shipping\S*/g, '')}</span>
                                            <p>{ReactHtmlParser(get_data.shipping_address)}</p>
                                        </div>)
                                        : ""
                                    }
                                </div>
                            </div>
                            <div className=" pad-0 mob py-4">
                                <div className="border-1  border-black  border p-4 rounded">
                                    <h6 className="bolder h4-bold ">Order Items</h6>
                                    <div className="row">
                                        <p className="col-sm-4 col-6 text-start">Product</p>
                                        <p className="col-sm-4 col-3 text-center">Quantity</p>
                                        <p className="col-sm-4 col-3 text-end">Price</p>
                                    </div>
                                    {
                                        get_data.items ? get_data.items.map((x) => (

                                            <div className="row border-1 border-top">
                                                <div className="col-sm-4 col-6 text-start d-flex py-2">
                                                    {/* <img src={process.env.PUBLIC_URL + "/assets/square.jpeg"} className="px-2" style={{ aspectRatio: 144/144, width: "100px" }} /> */}


                                                    {JSON.parse(localStorage.getItem('cartpic')) ?
                                                        (count = 0,
                                                            JSON.parse(localStorage.getItem('cartpic')).map(
                                                                (s) => {
                                                                    // console.log('product images -->',s)
                                                                    return (
                                                                        s.id == x.item_name
                                                                            ?
                                                                            (
                                                                                < img src={s.img ? `${BASE}${s.img}` : process.env.PUBLIC_URL + "/assets/square.jpeg"} className="px-2" style={{ aspectRatio: 144 / 144, width: "100px" }} />)
                                                                            :
                                                                            (
                                                                                count = count + 1,
                                                                                JSON.parse(localStorage.getItem('cartpic')).length == count
                                                                                    ?
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/square.jpeg"} className="px-2" style={{ aspectRatio: 144 / 144, width: "100px" }} />
                                                                                    : ""
                                                                            )
                                                                    )

                                                                    // <img src={process.env.PUBLIC_URL + "/assets/square.jpeg"} className="cart-img img-fluid" />
                                                                }
                                                            )
                                                        )
                                                        :
                                                        <img src={process.env.PUBLIC_URL + "/assets/square.jpeg"} className="px-2" style={{ aspectRatio: 144 / 144, width: "100px" }} />

                                                    }


                                                    <p className="h4-bold">{x.item_name}</p>
                                                </div>
                                                <p className="col-sm-4 col-3 h4-bold text-center py-2 ">
                                                    {x.qty}
                                                </p>
                                                <p className="col-sm-4 col-3 text-end h4-bold py-2">
                                                    BHD {x.base_amount}
                                                </p>
                                            </div>
                                        ))
                                            :
                                            ""
                                    }

                                </div>
                            </div>
                            <div className=" pad-0 mob py-4">
                                <div className="border-1  border-top border-dark   p-4  d-flex justify-content-between">
                                    <h6 className="bolder h3-bold ">Total</h6>

                                    <h6 className=" h3-bold" >
                                        BHD {get_data ? get_data.total : ""}
                                    </h6>

                                </div>
                            </div>
                            <div className="justify-content-end d-flex">
                                {/* <button className="bg-royalblue bth  btn-royalblue py-2 my-5 "   ><div className='h8 text-align-center'>Pay</div></button> */}
                                {/* <button className="whitebtn btn bg-royalblue btn-royalblue bgsecondary whitecolor change-details-btn p14-500 text-center" onClick={async () => {
                                payFunc()
                            }}>
                                Pay
                            </button> */}
                                <BasicButton name="Pay" function_call={() => payFunc()} />

                            </div>
                        </div>
                    </div>

            }

            <Footer />
        </div>
    );
}

export default Confirmation;
