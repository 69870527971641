import React, { useEffect, useState, useRef } from 'react'
import Header from '../../component/header/Header'
import Footer from '../../component/footer/Footer'
import { Link } from 'react-router-dom'
const NotFound = () => {

    useEffect(() => {
    }, [])

    return (
        <div>
            <Header />
            <div className="position-relative  " >
                <div className="d-flex justify-content-center" style={{ minHeight: "460px" }}>
                    <div className="align-self-center text-center">
                        <img src={process.env.PUBLIC_URL + "/assets/page not found.png"} className="img-fluid" />
                        <h5 className="royal-blue-text">We can’ t find the page</h5>
                        <p className="royal-blue-text">Unfortunately we can’t find the page you are looking for.</p>
                        <Link to='/' className="text-decoration-none"><button className="btn btn-royalblue btn-block bg-royalblue text-white h5 mouse-pointer" >Go to Home page</button></Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default NotFound;


