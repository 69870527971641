import { isNumeric } from 'jquery'
import validator from 'validator'

export const validationform = (e) => {

    if (e.name == 'email' || e.name == 'register-email') {
        // console.log('name', e.name, 'value', e.value)
        if (validator.isEmail(e.value)) {
            // console.log('conditoin is true')
            return true
        } else {
            // console.log('conditoin is false')
            return false

        }
    }
    else
        if (e.name == 'password' || e.name == 'register-password') {
            // console.log('name', e.name, 'value', e.value)
            if (validator.isStrongPassword(e.value, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })) {
                // console.log('conditoin is true')
                return true
            } else {
                // console.log('conditoin is false')
                return false
            }
        }
        else
            if (e.name == 'fullname' || e.name == 'register-fullname') {
                // console.log('name', e.name, 'value', e.value)
                if (e.value.length > 2) {
                    // console.log('conditoin is true')
                    return true
                } else {
                    // console.log('conditoin is false')
                    return false

                }
            }
            else
                if (e.name == 'phone' || e.name == 'register-phone') {
                    if (validator.isMobilePhone(e.value, 'ar-BH')) {
                        return true
                    } else {
                        return false

                    }
                }
                else
                    if (e.name == 'postalcode') {
                        const reg = new RegExp('^[0-9]+$');
                        console.log(reg, '< - - - postalcode ')
                        if (e.value.length == 3 && reg.test(e.value) || e.value.length == 4 && reg.test(e.value)) {
                            return true
                        } else {
                            return false

                        }
                    }
                    else
                        if (e.name == 'address') {
                            const reg = new RegExp(/^[a-zA-Z0-9\s,'-/]*$/);
                            console.log(reg, '< - - - address ')
                            if (reg.test(e.value)) {
                                return true
                            } else {
                                return false

                            }
                        }
                        else
                            if (e.name == 'city') {
                            const reg = new RegExp(/^[a-zA-Z\s,']*$/);


                                if (reg.test(e.value)) {
                                    return true
                                } else {
                                    return false

                                }
                            }


}