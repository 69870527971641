import Axios from 'axios'
import { BASE } from '../api/api'

export const findCart_Detail = (QTN_ID) => new Promise((resolve, reject) => {
    var user_tocken = localStorage.getItem('tocken')
    var config = {
        method: 'get',
        url: `${BASE}/api/resource/Quotation/${QTN_ID}`,
        headers: {
            'Authorization': `Bearer ${user_tocken}`,
        },
    }
    Axios(config)
        .then(Response => {
            // console.log(Response.data, '--Response Data is --')
            resolve(Response.data.data.items)
        }
        )
        .catch(Error => { console.error(Error.data, '--Error Data is --'); reject('FIND_CART_DETAIL_FAILED') })
})