import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
import '../home.css'
import Header from '../../../component/header/Header'
import { validationform } from '../../../util/validation'
import BasicButtonSimple from '../../../component/atom/BasicButtonSimple'
import { useHistory } from 'react-router-dom';
import { forgottenPassword } from '../../../Context/actions/auth/forgotten_email'
import { GlobalContext } from '../../../Context/Provider'
const MobForgottenpassword = () => {
    let history = useHistory();
    const [email, setemail] = useState('')

    const [validEmail, setvalidEmail] = useState(true)

    const {
        authDispatch,
        authState: {
            auth: { user_loading, loading, error, data, isAuthenticated },
        },
    } = useContext(GlobalContext);

    const forgotten_password = () => {
        forgottenPassword(email)(authDispatch)
            .then(e => {
                if (e.message == 'OTP sent successfully') {
                    alert.show('Please Check Email');
                    history.push("/reset")
                } else if (e.message == undefined) {
                    alert.error('Email not Exist');
                }
            }
            )
    }


    return (

        <div>
            <Header />
            <div className="desktop-visible">
                <div className="pt-5 text-center">
                    <Link className="display-6 bold text-black mt-5 text-decoration-none" to="/">OOPs webpage only available in mobile version  Click to return Home page</Link>
                </div>
            </div>
            <div className="tablet-visible mob-visible">
                <div className='overlay-mob bg-white ' style={{ marginTop: "4rem" }} >
                    <div className="h-100">
                        <div className="login  mx-5 ">
                            <form className="pt-5">
                                <div className="inp-fields">
                                    <input onChanges={(e) => setemail(e.target.value)} name="email" onBlur={e => { setemail(e.target.value); validationform(e.target) ? setvalidEmail(true) : setvalidEmail(false) }} className="profile-input d-block my-3" placeholder="E-mail*" />
                                    {validEmail ?
                                        ' '
                                        :
                                        <label className='valid-error'>Enter valid email address </label>
                                    }                                </div>
                                <div className="select-default">
                                </div>
                                {/* <input onClick={() => { forgotten_password() }} type="button" className="btn-royalblue bg-royalblue my-5" value="Reset Password" style={{ height: "30px", width: "100%" }} /> */}
                                <BasicButtonSimple name="Reset Password" submitbtn={false} function_call={() => forgotten_password()} />

                            </form>
                            <div className="my-3" style={{ textAlign: "center" }}>

                                <Link to="/login" className="text-black text-decoration-none"> <p className="login-grey frgt-pwd" >Login</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MobForgottenpassword;


