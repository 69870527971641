import React, { useContext, useEffect, useState } from 'react';
import { Logout } from '../../Context/actions/auth/logout';
import { GlobalContext } from '../../Context/Provider';
import { Link } from 'react-router-dom';
import { useAlert } from "react-alert";
import { Redirect, useHistory } from 'react-router-dom'
import 'react-accessible-accordion/dist/fancy-example.css';
import './profiledetails.css'

const DesktopMenu = () => {
    const alert = useAlert();
    const history = useHistory();
    const {
        authDispatch,
        authState: {
            auth: { user_loading, loading, error, data, isAuthenticated },
        },
    } = useContext(GlobalContext);
    React.useEffect(() => {
    }, []);

    return (
        <div>
            <div id="select_size">
                <div className="d-menu">
                    <h2 className="d-menu-heading">
                        My Account
                    </h2>
                    <div>
                        <div className="d-menu-item1">
                            <p>
                                <img src={process.env.PUBLIC_URL + "/assets/profile_edit.png"} />  Personal Details
                            </p>
                        </div>
                        <div className="d-menu-item pl-4">
                            <p>
                                <Link to="/profile-details/passwordchange">
                                    My profile
                                </Link>
                            </p>
                        </div>
                        <div className="profile-menu">
                            <div className="d-menu-item1">
                                <p>
                                    <img src={process.env.PUBLIC_URL + "/assets/shipping_address.png"} style={{ width: "32px", height: "32px" }} /> Shipping Address
                                </p>
                            </div>
                            <div className="d-menu-item pl-4">
                                <p>
                                    <Link to="/profile-details/shippinglist">
                                        Manage Address
                                    </Link>
                                </p>
                            </div>
                            <div className="d-menu-item1">
                                <p>
                                    <img src={process.env.PUBLIC_URL + "/assets/order_history.png"} style={{ width: "32px", height: "32px" }} /> Orders
                                </p>
                            </div>
                            <div className="d-menu-item pl-4">
                                <p>
                                    <Link to="/profile-details/orders">
                                        Order history
                                    </Link>
                                </p>
                            </div>
                            <div onClick={() => { Logout()(authDispatch); localStorage.removeItem("loggedin"); history.push("/"); alert.show("User logout") }} className="d-menu-item1 mouse-pointer">
                                <p>
                                    <img src={process.env.PUBLIC_URL + "/assets/profile_logout.png"} style={{ width: "36px", height: "36px" }} /> Logout
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesktopMenu;
