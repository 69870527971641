import React, { useEffect, useState, useRef } from 'react'
import Footer from '../../component/footer/Footer'
const Payment = () => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://afs.gateway.mastercard.com/checkout/version/61/checkout.js";
        script.error="errorCallback";
        script.dataCancel="cancelCallback";
        script.async = true;
        // script.onload = () => scriptLoaded();
        document.body.appendChild(script);
        // window.A.sort(); 

    }, [])
    function errorCallback(error) {
        console.log(JSON.stringify(error));
  }
  function cancelCallback() {
        console.log('Payment cancelled');
  }
  const Checkout=()=>[{
    session: { 
      id: '<your_create_checkout_session_ID>'
         },
    interaction: {
          merchant: {
              name: 'Your merchant name',
              address: {
                  line1: '200 Sample St',
                  line2: '1234 Example Town'            
              }    
          }
     }
  }];

    return (
        <div>
          <input type="button" value="Pay with Lightbox" onClick={()=>Checkout()} />
      <input type="button" value="Pay with Payment Page" onClick={()=>Checkout()} />
            <Footer />
        </div>
    )
}


export default Payment;


