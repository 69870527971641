import {
    order_loading,
    order_history,
    single_order,
} from '../../../actionTypes';
import { BASE } from '../../../api/api';
import Axios from 'axios';
//adding to shopping list



export const Order_history_api = () => dispatch => new Promise(async (resolve, reject) => {
    var user_tocken = localStorage.getItem('tocken')
    dispatch({ type: 'order_loading', payload: true });

    var config = {
        method: 'get',
        url: BASE+'/api/method/algolia_sync.zoomcart_api.order',
        headers: {
            'Authorization': `Bearer ${user_tocken}`,
        }
    };

    Axios(config)
        .then(function (response) {
            // console.log(response.data);
            resolve('order_history_api working')
            dispatch({ type: order_loading, payload: false });
            dispatch({ type: order_history, payload: response.data.message });

        })
        .catch(function (error) {
            console.error(error);
            dispatch({ type: order_loading, payload: false });
            reject('order_history_api failed')
        });

})
export const Singel_order_api = () => dispatch => new Promise(async (resolve, reject) => {
    var user_tocken = localStorage.getItem('tocken')
    dispatch({ type: 'order_loading', payload: true });

    var config = {
        method: 'get',
        url: BASE+'/api/method/algolia_sync.zoomcart_api.orderhistory',
        headers: {
            'Authorization': `Bearer ${user_tocken}`,
        }
    };

    Axios(config)
        .then(function (response) {
            console.log(response.data, 'single order api');
            dispatch({ type: order_loading, payload: false });
            dispatch({ type: single_order, payload: response.data.message });
            resolve('order_history_api working')

        })
        .catch(function (error) {
            console.error(error);
            dispatch({ type: order_loading, payload: false });
            reject('order_history_api failed')
        });

})