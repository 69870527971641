
import {
  getaddress,
  switchaddress,
  deladdress,
  add_loading,

} from '../../actionTypes';

const address = (state, { payload, type }) => {
  switch (type) {
    case add_loading:
      return {
        ...state,
        address: {
          ...state.address,
          error: false,
          loading: true,
        },
      };

    case getaddress:
      return {
        ...state,
        address: {
          ...state.address,
          loading: false,
          address: payload,
        },
      };

    case deladdress:
      return {
        ...state,
        address: {
          ...state.address,
          loading: false,
          status: 'DELETE TO ADDRESS',
        },
      };

    case switchaddress:
      return {
        ...state,
        address: {
          ...state.address,
          loading: false,
          switchaddress: payload,
        },
      };

    // case EDIT_ADDRESS:
    //   return {
    //     ...state,
    //     address: {
    //       ...state.address,
    //       status: 'EDIT ADRESS SUCCESFULLY',
    //     },
    //   };

    // case ADDRESS_ERROR:
    //   return {
    //     ...state,
    //     address: {
    //       ...state.address,
    //       loading: false,
    //       error: payload,
    //     },
    //   };
    default:
      return state;
  }
};

export default address;
