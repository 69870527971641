import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../Context/Provider';
import { Order_history_api } from '../../Context/actions/order/order';
const Myorders = () => {
    const location = useLocation()
    const {
        orderDispatch,
        orderState: {
            order: { order_history }
        }
    } = useContext(GlobalContext)
    useEffect(() => {
        Order_history_api()(orderDispatch)
    }, [2])
    const
        order_img = (img) => {
            switch (img) {
                case "Ordered":
                    return process.env.PUBLIC_URL + "/assets/shipping.png";
                case "Delivered":
                    return process.env.PUBLIC_URL + "/assets/delived.png";
                case "InTransit":
                    return process.env.PUBLIC_URL + "/assets/inTransit.png";
                case "Cancelled":
                    return process.env.PUBLIC_URL + "/assets/cancelled.png";
            }
        }
    return (
        <div id="profile-edit" className="profile-edit">
            <p className="container mt-3 small-normal pad-0">
                Check the details and status of your orders in the online store.
            </p>
            <div className="shipping-list-container container pad-0 mob my-orders-list">
                {order_history ? order_history.map((x) => {
                    let qty = 0
                    x.Response.map(x => {
                        qty = qty + x.qty;
                    })
                    return (
                        <Link to={`/profile-details/single_order/#${x.Response[0].id}`} state={{ data: 'x.Response[0].id' }}>
                            <div className="card border border-1 black rounded my-3 reset-bg">
                                <div className="d-flex p-3">
                                    <div>
                                        <img src={x.Response[0].order_status ? order_img(x.Response[0].order_status) : ""} width="75px" height="75px" alt="order status" />
                                        <h6 className="align-self-center text-center">{x.Response[0].order_status}</h6>
                                    </div>

                                    <div className="d-flex flex-column w-100 justify-content-center" >
                                        <p className="d-menu-item align-self-start pl-5 mx-md-5 mx-0  ">Order id : {x.Response[0].id}</p>
                                        <p className="d-menu-item align-self-start pl-5 mx-md-5 mx-0  ">Qty : {qty}</p>
                                        <p className="d-menu-item align-self-start pl-5 mx-md-5 mx-0  ">Order Price : {x['Grand Total']}</p>

                                    </div>
                                </div>
                            </div>

                        </Link>
                    )
                })
                    : ''
                }
            </div>
        </div>
    );
}

export default Myorders;
