import Axios from 'axios'
import {
  cart_status,
  getcart,
  carts,
  loading_freezz,
  cart_loading,
  stock_data,
  stock_error,
  cart_name
} from '../../../actionTypes';
import { BASE } from '../../../api/api';
import { finding_img } from '../../../util/findingimg';
export const getCart = () => dispatch => new Promise(async (resolve, reject) => {
  let user_mail = localStorage.getItem('usermail')
  dispatch({ type: cart_loading, payload: true })

  // console.log(`%c getCart function calling `, 'background:yellow;color:blue')
  var user_tocken = localStorage.getItem('tocken')
  var data = '';
  var config = {
    method: 'get',
    url: BASE + `/api/resource/Quotation?filters=[["docstatus", "=", "0"],["owner", "like", "${user_mail}"]]`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
    },
    data: data
  };

  Axios(config)
    .then(async (response) => {
      dispatch({ type: cart_loading, payload: false })

      if (response.data.data.length == 0) {
        dispatch({ type: cart_status, payload: false });
        reject('GETCART-FAIL-NO-OBJECT')
      } else {
        dispatch({ type: cart_status, payload: true });
        dispatch({ type: cart_name, payload: response.data.data[0].name });
        resolve(response.data.data[0].name)
      }



    })
    .catch(function (error) {
      dispatch({ type: cart_status, payload: true })
      dispatch({ type: cart_loading, payload: false })

      console.error('errpr is', error);
      reject('GETCART-FAIL')
    });

}
)
export const fetchCart = ({ qtn }) => dispatch => new Promise(async (resolve, reject) => {
  dispatch({ type: cart_loading, payload: true })

  // console.log(`%c getCart function calling `, 'background:yellow;color:blue')
  var user_tocken = localStorage.getItem('tocken')
  var data = '';
  var config = {
    method: 'get',
    url: BASE + `/api/resource/Quotation/${qtn}`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
    },
    data: data
  };

  Axios(config)
    .then(async (response) => {
      console.log('reponse of fetch cart api', response.data.data.items)
      localStorage.setItem('payment_schedule_name', response.data.data.payment_schedule[0].name)
      dispatch({ type: cart_status, payload: true })
      localStorage.setItem('cart_available', true)

      dispatch({ type: getcart, payload: response.data.data })
      response.data.data.items.map((x) => {
        finding_img(x.item_code, x.item_name)
      })

      localStorage.setItem('sales_qtn', response.data.data.name)
      localStorage.setItem('cartitems', JSON.stringify(response.data.data.items));
      localStorage.setItem('cartnetprice', JSON.stringify(response.data.data.total));
      dispatch({ type: cart_loading, payload: false })
      resolve('FETCH-CART-API-SUCCESSFULL')


    })
    .catch(function (error) {
      dispatch({ type: cart_loading, payload: false })

      console.error(error);
      reject('FETCH-CART-API-FAIL')
    });

}
)

export const cartupdate = ({ items_array, qtn }) => dispatch => new Promise(async (resolve, reject) => {


  // localStorage.removeItem("sales_qtn")
  // localStorage.removeItem("sales_order")
  // localStorage.removeItem("order_id")
  // localStorage.removeItem("session_id")

  dispatch({ type: cart_loading, payload: true })
  var data = JSON.stringify({
    "data": {
      "items": items_array
    }
  });

  // var data = JSON.stringify({ "qty": "3", "item_code": "COR-TP-BLU-S" });
  var user_tocken = localStorage.getItem('tocken')
  var config = {
    method: 'put',
    url: BASE + `/api/resource/Quotation/${qtn}`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      dispatch({ type: getcart, payload: response.data.data })
      response.data.data.items.map((x) => {
        finding_img(x.item_code, x.item_name)
      })
      dispatch({ type: cart_loading, payload: false })

      // localStorage.setItem('cartupdates', 1)
      // dispatch({ type: cart_loading, payload: false })
      // dispatch({ type: carts, payload: response });
      // localStorage.setItem("cart_qtn", response.data.message.name)
      resolve('cartupdate_success')
    })
    .catch(function (error) {
      dispatch({ type: cart_loading, payload: false })
      console.error("cartupdate funciton error ", error);
      reject(error)

    });

})
export const cartSubmit = ({ qtn }) => dispatch => new Promise(async (resolve, reject) => {


  // localStorage.removeItem("sales_qtn")
  // localStorage.removeItem("sales_order")
  // localStorage.removeItem("order_id")
  // localStorage.removeItem("session_id")

  dispatch({ type: cart_loading, payload: true })
  var data = JSON.stringify({
    "data": {
      "docstatus": 1
    }
  });

  // var data = JSON.stringify({ "qty": "3", "item_code": "COR-TP-BLU-S" });
  var user_tocken = localStorage.getItem('tocken')
  var config = {
    method: 'put',
    url: BASE + `/api/resource/Quotation/${qtn}`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      dispatch({ type: cart_loading, payload: false })
      resolve('cartupdate_success')
    })
    .catch(function (error) {
      dispatch({ type: cart_loading, payload: false })
      console.error("cartupdate funciton error ", error);
      reject(error)

    });

})
export const cart_switch_address = ({ shipment_name, qtn, customer_name }) => dispatch => new Promise(async (resolve, reject) => {
  console.log('1 cart_switch_address is working', shipment_name)
  console.log('2 cart_switch_address is working', qtn)
  console.log('3 cart_switch_address is working', customer_name)

  // localStorage.removeItem("sales_qtn")
  // localStorage.removeItem("sales_order")
  // localStorage.removeItem("order_id")
  // localStorage.removeItem("session_id")

  dispatch({ type: cart_loading, payload: true })
  var data = JSON.stringify({
    "data": {
      "shipping_address_name": shipment_name,
      "party_name": customer_name
    }
  });
  console.log('zoomcart on the floor -->', data)

  // var data = JSON.stringify({ "qty": "3", "item_code": "COR-TP-BLU-S" });
  var user_tocken = localStorage.getItem('tocken')
  var config = {
    method: 'put',
    url: BASE + `/api/resource/Quotation/${qtn}`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      dispatch({ type: cart_loading, payload: false })
      dispatch({ type: getcart, payload: response.data.data })
      resolve('cartupdate_success')
    })
    .catch(function (error) {
      dispatch({ type: cart_loading, payload: false })
      console.error("cartupdate funciton error ", error);
      reject(error)

    });

})
export const cartCreation = ({ item_code, group_of_item,party_name }) => dispatch => new Promise(async (resolve, reject) => {
  console.log('group-fo-items', group_of_item)
  dispatch({ type: cart_loading, payload: true })
  var data_item = group_of_item ? group_of_item : [
    {
      "item_code": item_code,
      "qty": 1.0
    }
  ]
  console.log('data_item -->', data_item)
  var data = JSON.stringify({
    "data": {
      "quotation_to": "Customer",
      "order_type": "Shopping Cart",
      "naming_series": "QTN-",
      "customer_name": localStorage.getItem('usermail'),
      "party_name":party_name,
      "items": data_item
    }
  });
  // var data = JSON.stringify({ "qty": "3", "item_code": "COR-TP-BLU-S" });
  var user_tocken = localStorage.getItem('tocken')
  var config = {
    method: 'post',
    url: BASE + `/api/resource/Quotation`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      dispatch({ type: getcart, payload: response.data.data })
      localStorage.setItem('payment_schedule_name', response.data.data.payment_schedule[0].name)
      response.data.data.items.map((x) => {
        finding_img(x.item_code, x.item_name)
      })
      dispatch({ type: cart_loading, payload: false })
      localStorage.setItem('cart_name', response.data.data.name)
      resolve(response.data.data.name)
    })
    .catch(function (error) {
      dispatch({ type: cart_loading, payload: false })
      console.error("cartupdate funciton error ", error);
      reject(error)

    });

})

export const cart_retrive_done = () => dispatch => {
  // console.log('jeem bhoom baa')
  dispatch({ type: cart_status, payload: true })
  dispatch({ type: loading_freezz, payload: false })

  dispatch({ type: cart_loading, payload: false })



}

export const cartDeletion = () => dispatch => {
  // console.log("%c cartDeletion context ", 'background:yellow;color:green',);

  dispatch({ type: cart_loading, payload: true });
  var user_token = localStorage.getItem('tocken');
  var cart_qtn = localStorage.getItem('cart_qtn')

  var config = {
    method: 'delete',
    url: `${BASE}/api/resource/Quotation/${cart_qtn}`,
    headers: {
      'Authorization': `Bearer ${user_token}`,
    }
  };


  Axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      dispatch({ type: getcart, payload: [] });
      dispatch({ type: cart_loading, payload: false });

    })
    .catch(function (error) {
      console.error(error);
      dispatch({ type: cart_loading, payload: false });

    });

}

export const stock_check = ({ item_code }) => dispatch => new Promise(async (resolve, reject) => {
  console.log('stock check api calling -->',item_code)
  dispatch({ type: cart_loading, payload: true })

  var user_tocken = localStorage.getItem('tocken')
  var data = JSON.stringify({
    "item_code": item_code,
    "warehouse": "Zooomcart - ZREA"
  });

  var config = {
    method: 'post',
    url: BASE+'/api/method/erpnext.stock.get_item_details.get_bin_details',
    headers: {
      // 'Authorization': `Bearer ${user_tocken}`,
      'Authorization': `token 155b35b5505b856:49f3f48eeed4907`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      console.log('api calling done-->',JSON.stringify(response.data));
      dispatch({ type: cart_loading, payload: false })

      dispatch({ type: stock_data, payload: response.data })
      resolve(response.data)
    })
    .catch(function (error) {
      console.error(error);
      // stock_error
      dispatch({ type: cart_loading, payload: false })

      dispatch({ type: stock_error, payload: error })
      reject('stock_function_failed')
    });
})

export const Confirmation = async () => dispatch => new Promise(async (resolve, reject) => {
  var user_tocken = localStorage.getItem('tocken')
  dispatch({ type: cart_loading, payload: true });

  var data = '';

  var config = {
    method: 'get',
    url: BASE + '/api/method/erpnext.e_commerce.shopping_cart.cart.get_cart_quotation',
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      // dispatch({ type: confirmationData, payload: response.data });
      dispatch({ type: cart_loading, payload: false });
      resolve('Confirmation-Success')

    })
    .catch(function (error) {
      dispatch({ type: cart_loading, payload: false });
      console.error(error);
      reject('Confirmation-fail')
    });



})

export const cart_dueDate_update = () => dispatch => new Promise(async (resolve, reject) => {
  dispatch({ type: cart_loading, payload: true })

  let payment_schedule_id = await localStorage.getItem('payment_schedule_name')
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = yyyy + '-' + mm + '-' + dd;


  var data = JSON.stringify({
    "data": {
      "due_date": formattedToday
    }
  });
  var user_tocken = localStorage.getItem('tocken')
  var config = {
    method: 'put',
    url: BASE + `/api/resource/Payment Schedule/${payment_schedule_id}`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      dispatch({ type: cart_loading, payload: false })
      resolve('cartupdate_success')
    })
    .catch(function (error) {
      dispatch({ type: cart_loading, payload: false })
      console.error("cartupdate funciton error ", error);
      reject(error)

    });

})