import {
  inform_loading,
  carousel,
  latesttrend,
  aboutus,
  review,
  feedback,
} from '../../actionTypes';

const inform = (state, { payload, type }) => {
  switch (type) {
    case inform_loading:
      return {
        ...state,
        inform: {
          ...state.inform,
          inform_loading: payload,
        },
      };
    case carousel:
      return {
        ...state,
        inform: {
          ...state.inform,
          carousel: payload,
        },
      };
    case latesttrend:
      return {
        ...state,
        inform: {
          ...state.inform,
          latesttrend: payload,
        },
      };
    case aboutus:
      return {
        ...state,
        inform: {
          ...state.inform,
          aboutus: payload,
        },
      };
    case review:
      return {
        ...state,
        inform: {
          ...state.inform,
          review: payload,
        },
      };
    case feedback:
      return {
        ...state,
        inform: {
          ...state.inform,
          feedback: payload,
        },
      };
    default:
      return state;
  }
};

export default inform;
