import React, { useContext, useEffect, useState } from 'react';
import {
    Link,
    useParams,
    matchPath,
    useHistory
} from 'react-router-dom';
import { useAlert } from "react-alert";

// import Footer from '../../component/Footer/footer';
import Header from '../../component/header/Header';
import arrow from '../../component/assets/Footer Right.png'
import 'react-accessible-accordion/dist/fancy-example.css';
import './profile.css'
import DesktopMenu from '../../component/profiledetails/desktop-menu'
import ProfileEdit from '../../component/profiledetails/profileedit';
import PassWord from '../../component/profile/password';
import PersonalData from '../../component/profile/personalData';
import ShippingList from '../../component/profiledetails/shippingList';
import Myorders from '../../component/profiledetails/myOrders';
import AddAddress from '../../component/profiledetails/addaddress'
import Orderlist from '../../component/profiledetails/orderList';
import OrderDetails from '../../component/profiledetails/orderDetails';

const ProfileDetails = () => {
    const alert = useAlert();

    const historys = useHistory()
    // const { state, fetch_single_article } = useContext(ArtContext);
    const { id, getid } = useParams();

    const [url_params, setUrlparams] = useState("profileedit");
    const [ordernos, setorderno] = useState("");

    useEffect(() => {
        // alert.show("id",id)
        setUrlparams(id)
    }, [id]);
    // useEffect(() => {
    //     if (!localStorage.getItem('loggedin'))
    //         window.location.replace('/403')
    // }, []);
    // console.log(getid, 'changed')



    return (
        <div
        >
            <Header />
            <div className="mobile-div mob-visible">
                <div
                    className="container profile-header">
                    <Link to={url_params === "single_order" ? '/profile-details/orders' : '/profile'} class="d-flex">
                        <img alt="icon" src={arrow} />
                        {url_params === "profileedit" || url_params === "passwordchange" ?
                            <p className="small-bold">My Profile</p>
                            : url_params === "shippinglist" ?
                                <p className="small-bold">Shipping Details</p>
                                : url_params === "orders" ?
                                    <p className="small-bold">Order History</p>
                                    : url_params === "single_order" ?
                                        <p className="small-bold">Back To Orders</p>
                                        : url_params === "addadress" ?
                                            <p className="small-bold">Add Address</p>
                                            : url_params === "single_order" ?
                                                <p className="small-bold">Add Address</p>
                                                : historys.push("/404p")}
                    </Link>
                </div>
                {url_params === "profileedit" ?
                    <ProfileEdit />
                    : url_params === "passwordchange" ?
                        <ProfileEdit />
                        : url_params === "shippinglist" ?
                            <ShippingList />
                            : url_params === "orders" ?
                                <Myorders />
                                : url_params === "single_order" ?
                                    <OrderDetails />
                                    : url_params === "addadress" ?
                                        <AddAddress />
                                        : historys.push("/404p")}

            </div>
            {/* <Myorders />
            {/* <MobileMenu /> */}
            {/* <ProfileEdit /> */}
            {/* <ShippingList /> */}
            {/* <div className="container profile-edit-contianer mob">
                   <EditAddress />
                </div> */}
            <div className="desktop-visible">
                <div className="  container ">
                    <div className="row py-5 ">


                        <div className=" col-md-4 col-sm-4">
                            <DesktopMenu />
                        </div>
                        <div className="col-md-8 col-sm-8 px-lg-5 px-md-0">
                            {url_params == "profileedit" || url_params == "passwordchange" ?
                                <div className="Desktop-buttons">
                                    {/* <Link to={'/profile-details/profileedit'}><button style={{
                                        color: url_params == "profileedit" ? "#1D1F22" : "#72757E",
                                        borderBottom: url_params == "profileedit" ? "solid #1D1F22" : "none"

                                    }}>
                                        Personal data</button></Link> */}
                                    <Link to={'/profile-details/passwordchange'}><button style={{
                                        color: url_params == "passwordchange" ? "#1D1F22" : "#72757E",
                                        borderBottom: url_params == "passwordchange" ? "solid #1D1F22" : "none"
                                    }}>
                                        Password change</button></Link>
                                </div>
                                : url_params === "shippinglist" ?
                                    <div className="Desktop-buttons">
                                        <Link to={'/profile-details/shippinglist'}><button style={{
                                            color: url_params === "shippinglist" ? "#1D1F22" : "#72757E",
                                            borderBottom: url_params === "shippinglist" ? "solid #1D1F22" : "solid #72757E"
                                        }}>
                                            Shipping details</button></Link>
                                    </div>
                                    : url_params === "orders" ?
                                        <div className="Desktop-buttons">
                                            <Link to={'/profile-details/orders'}><button style={{
                                                color: url_params === "orders" ? "#1D1F22" : "#72757E",
                                                borderBottom: url_params === "orders" ? "solid #1D1F22" : "solid #72757E"
                                            }}>
                                                My orders</button></Link>
                                        </div>
                                        : url_params === "addadress" ?
                                            <div className="Desktop-buttons">
                                                <Link to={'/profile-details/addadress'}><button style={{
                                                    color: url_params === "addadress" ? "#1D1F22" : "#72757E",
                                                    borderBottom: url_params === "addadress" ? "solid #1D1F22" : "solid #72757E"
                                                }}>
                                                    Add Address</button></Link>
                                            </div>
                                            : url_params === "single_order" ?
                                                <div className="Desktop-buttons">
                                                    <Link to={'/profile-details/single_order'}><button style={{
                                                        color: url_params === "single_order" ? "#1D1F22" : "#72757E",
                                                        borderBottom: url_params === "single_order" ? "solid #1D1F22" : "solid #72757E"
                                                    }}>
                                                        Order Details</button></Link>
                                                </div>
                                                : <div> so sorry</div>}
                            <div>
                                {
                                    // url_params === "profileedit" ?
                                    //     <PersonalData />
                                    //     : 
                                    url_params === "passwordchange" ?
                                        <PassWord />
                                        : url_params === "shippinglist" ?
                                            <ShippingList />
                                            : url_params === "orders" ?
                                                <Myorders />
                                                : url_params === "single_order" ?
                                                    <OrderDetails />
                                                    : url_params === "addadress" ?
                                                        <AddAddress location="/profile-details/shippinglist" button="Add Address" method="add" />
                                                        : null}
                                {/* <Myorders /> */}
                                {/*  <PersonalData />*/}
                                {/*  <PassWord />*/}
                                {/*  <ShippingList />*/}
                                {/* <EditAddress /> */}
                            </div>
                            {/* <div className="container mt-3 small-normal policy-text">
                                At Espravo, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the Privacy Policy.
                            </div> */}
                        </div>

                    </div>
                </div>

            </div>

        </div>

    );
}

export default ProfileDetails;
