import React, { useEffect, useState, useRef } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./slicker.css"

const Slickerone = (props) => {
    var slider = 1;
 
    var next = (props) => {
        slider.slickNext();

    }
    useEffect(() => {
        next = next.bind()

    })

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={process.env.PUBLIC_URL + "/assets/next-button-slicker.svg"}
                className={className}
                style={{ ...style, width: "45px", height: "40px", right: 0 }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={process.env.PUBLIC_URL + "/assets/prev-button-slicker.svg"}
                className={className}
                style={{ ...style, width: "45px", height: "40px", right: 0,zIndex:10 }}
                onClick={onClick}
            />
        );
    }


    var settings = {
        infinite: false,
        arrow: true,
        slidesToShow: props.values[1].slide,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: props.values[1].slide,
                    slidesToScroll: props.values[1].slide,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: props.values[1].slide,
                    slidesToScroll: 1.9,
                    infinite: false,
                    arrow: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: props.values[1].slide,
                    slidesToScroll: 1.45,
                    infinite: false,
                    arrow: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: props.values[1].slide,
                    slidesToScroll: 1.07,
                    infinite: false,
                    arrow: true,
                }
            },
        ]
    }
        ;

    return (
        <div>
            <Slider ref={c => (slider = c)} {...settings}>
                {props.recommen ?
                    props.recommen.map((s) => {
                        return (
                            <div className="row">

                                {s ?
                                    <div className='position-relative slicker'>
                                        <div className="card  text-white d-flex " style={{ width: `${props.values[1].width}`, height: `${props.values[1].height}` }}>
                                            <img className={`card-img ${props.values[3]}`} src={s.hit.imageURL ? s.hit.imageURL[0] : "../assets/square.jpeg"} alt="slicker one" />
                                            <div className="card-img-overlay align-items-end d-flex justify-content-center nopadding">
                                                <p className='slickerpart text-center text-white ' >
                                                    {s.hit.item}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    ''
                                    // console.log("undefined")
                                }
                            </div>
                        )
                    })


                    :
                    props.values[0].map((s) => {
                        return (
                            <div className="row">
                                <div className='position-relative slicker'>
                                    <div className="card  text-white d-flex " style={{ width: `${props.values[1].width}`, height: `${props.values[1].height}` }}>
                                        <img className={`card-img ${props.values[3]}`} src={s.img} alt="slicker one" />
                                        <div className="card-img-overlay align-items-end d-flex justify-content-center nopadding">
                                            <p className='slickerpart text-center text-white ' >
                                                {s.titleone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })


                }


            </Slider>
        </div>
    )
}
export default Slickerone;


