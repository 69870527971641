import React, { useEffect, useState, useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import '../home.css'
import { validationform } from '../../../util/validation'
import { useAlert } from "react-alert";
import Header from '../../../component/header/Header'
import BasicButtonSimple from '../../../component/atom/BasicButtonSimple'
import { Login } from '../../../Context/actions/auth/login'
import { GlobalContext } from '../../../Context/Provider'
const Moblogin = () => {
    const alert = useAlert();

    let history = useHistory()
    const [loggedin, setloggedin] = useState('')
    const [notify, setnotify] = useState('')

    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [fullname, setfullname] = useState('')
    const [submitbtn, setsubmitbtn] = useState(true)

    const [searchbarHide, setsearchbarHide] = useState(false)

    const [validEmail, setvalidEmail] = useState('a')
    const [passwordvalid, setpasswordvalid] = useState('a')

    const {
        authDispatch,
        authState: {
            auth: { user_loading, loading, error, data, isAuthenticated },
        },
    } = useContext(GlobalContext);

    useEffect(() => {

        setloggedin(localStorage.getItem('loggedin'))

        setnotify(localStorage.getItem('notification'))

    })
    const Login_call = () => {
        Login(email, password)(authDispatch)
            .then(() => {

                alert.show("Login successfully");
                localStorage.removeItem('loggednotify');
                history.push("/")
            })
            .catch(() => {
                alert.error("Invalid Email or Password");
                localStorage.removeItem('loggednotify');


            })


    }



    return (
        <div>
            <Header />
            <div className="desktop-visible">
                <div className="pt-5 text-center">
                    <Link className="display-6 bold text-black mt-5 text-decoration-none" to="/">OOPs webpage only available in mobile version  Click to return Home page</Link>
                </div>
            </div>
            <div className="tablet-visible mob-visible">
                <div className='overlay-mob bg-white ' style={{ marginTop: "4rem" }} >
                    <div className="h-100">
                        <div className="login  mx-5 ">
                            <form className="pt-5" onSubmit={(e)=>{e.preventDefault()}}>
                                <div className="inp-fields">
                                    <input onChanges={(e) => setemail(e.target.value)} name="email" onBlur={e => { setemail(e.target.value); validationform(e.target) ? setvalidEmail(true) : setvalidEmail(false) }} className="profile-input d-block my-3" placeholder="E-mail*" />
                                    {validEmail ?
                                        ' '
                                        :
                                        <label className='valid-error'>Enter valid email address </label>
                                    }
                                    <input onChanges={(e) => setpassword(e.target.value)} name="password" onBlur={e => { setpassword(e.target.value); validationform(e.target) ? setpasswordvalid(true) : setpasswordvalid(false) }} className="profile-input d-block my-3" type="password" placeholder="Password*" />
                                    {passwordvalid ?

                                        ' '
                                        :
                                        <label className='valid-error'>Password at least 8 Characters , Contains At Least A Number, Special Character And A Mixture Of Uppercase And Lowercase Letters.</label>
                                    }
                                </div>
                                {/* <input type="button" className="btn-royalblue bg-royalblue" value="Login" onClick={() => { Login_call() }} style={{ height: "30px", width: "100%" }} /> */}
                                <BasicButtonSimple name="Login" submitbtn={false} function_call={(e) => { Login_call() }} />

                            </form>
                            <div className="my-3" style={{ textAlign: "center" }}>
                                <Link className="text-black text-decoration-none" to="/forgottenpassword"><p className="login-grey frgt-pwd" >Forgot Password</p></Link>
                                <p className="login-grey dnt-accnt">Don’t have an account yet?</p>
                                <Link className="text-black text-decoration-none" to="/register"><p className="login-grey frgt-pwd" >Register Now</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Moblogin;


