import Axios from 'axios'
import {
  refreshtocken,
  tocken,
  current_date,
  expire_date,
  user_loading,
  logedin,
} from "../../../actionTypes/index";
import { BASE } from '../../../api/api';

export const Login = (email, password) => dispatch => new Promise(async (resolve, reject) => {
  dispatch({ type: user_loading, payload: true })

  console.log('zoomcart login call is active')
  // console.log(`%c login context data entered email:${email}, password:${password}`, 'background:yellow;color:blue',);
  localStorage.setItem('usermail', email);
  var data = new FormData();
  data.append('client_id', '04f1dae69d');
  data.append('client_secret', 'bf647cc557');
  data.append('grant_type', 'password');
  data.append('username', email);
  data.append('password', password);
  var config = {
    method: 'post',
    url: BASE + '/api/method/frappe.integrations.oauth2.get_token',
    data: data
  };
  Axios(config)
    .then(function (response) {
      dispatch({ type: logedin, payload: true });
      // console.log(`%c login context reponse  ${response.data.access_token}`, 'background:yellow;color:green',);
      localStorage.setItem('loggedin', true)
      localStorage.setItem('loggednotify', true)
      localStorage.setItem('tocken', response.data.access_token)
      localStorage.setItem('refresh_token', response.data.refresh_token)
      localStorage.setItem('refresh_token_login', true)
      dispatch({ type: refreshtocken, payload: response.data.refresh_token });
      dispatch({ type: tocken, payload: response.data.access_token });
      dispatch({ type: user_loading, payload: false });

      const currentDateTime = new Date();
      const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000);
      const updateDateTime = new Date();
      const expireDateTime = new Date(updateDateTime.setHours(updateDateTime.getHours() + 1));
      const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000);
      const refreshDateTime = new Date(updateDateTime.setMinutes(updateDateTime.getMinutes() + 1));
      const refreshTimeStamp = expireTimeStamp - 2100;


      dispatch({ type: current_date, payload: currentTimestamp });
      dispatch({ type: expire_date, payload: expireTimeStamp });
      localStorage.setItem('expire_date', expireTimeStamp)
      localStorage.setItem('reLogintime', refreshTimeStamp)
      window.location.replace('/')
      resolve('login_successfully')
      // document.getElementById("reply").innerHTML = currentTimestamp;

      // if (token) {
      //   const decodedToken = decode(token)
      //   if (decodedToken.exp * 1000 < newDate().getTime()) logout();
      // }

      // let {exp}=jwtDecode(response.data.refresh_token)
    })
    .catch(function (error) {
      dispatch({ type: logedin, payload: false });

      // console.log("%c error issue", "background:yellow;color:red", error.status);
      localStorage.setItem('loggednotify', true)
      dispatch({ type: user_loading, payload: false });
      localStorage.removeItem('usermail');
      // error = 500 ? alert("Invalid password or email") : alert("something went wrong");
      error = 500 ? dispatch({ type: logedin, payload: false }) : alert("something went wrong");
      reject('Login_fail')
    });
})


export const reloagin_oath = dispatch => () => new Promise(async (resolve, reject) => {
  var FormData = require('form-data');
  var data = new FormData();
  const refresh_token = localStorage.getItem('refresh_token')
  data.append('grant_type', 'refresh_token');
  data.append('client_id', '04f1dae69d');
  data.append('client_secret', 'bf647cc557');
  data.append('refresh_token', refresh_token);

  var config = {
    method: 'post',
    url: BASE+'/api/method/frappe.integrations.oauth2.get_token',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      const updateDateTime = new Date();
      const expireDateTime = new Date(updateDateTime.setHours(updateDateTime.getHours() + 1));
      const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000);
      const refreshDateTime = new Date(updateDateTime.setMinutes(updateDateTime.getMinutes() + 1));
      const refreshTimeStamp = expireTimeStamp - 2100;
      localStorage.setItem('reLogintime', refreshTimeStamp)

      // localStorage.setItem('loggedin', true)
      localStorage.setItem('tocken', response.data.access_token)
      localStorage.setItem('refreshTocken', response.data.refresh_token)
      dispatch({ type: user_loading, payload: false })
      dispatch({ type: refreshtocken, payload: response.data.refresh_token });
      dispatch({ type: tocken, payload: response.data.access_token });
      dispatch({ type: user_loading, payload: false });
      localStorage.removeItem('refresh_token_login')
      resolve('relogin_successful')
    })
    .catch(function (error) {
      console.error(error);
      reject('relogin_failed')
    });

})









// export const login = (email,password) => async dispatch =>{
//   const config ={
//       headers: {
//           'Content-Type': 'application/json'
//       }
//   }

//   const body = JSON.stringify({email,password});

//   try {
//       const res = await axios.post('/api/auth',body,config);

//       dispatch({
//           type:LOGIN_SUCCESS,
//           payload: res.data
//       });

//       dispatch(loadUser());
//   } catch (err) {
//       const errors = err.response.data.errors;

//       if(errors){
//           errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
//       }
//       dispatch({
//           type:LOGIN_FAIL
//       })
//   }
// }