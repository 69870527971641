import React, { useEffect, useState, useRef } from 'react'
import Footer from '../../component/footer/Footer'
import { BASE } from '../../api/api'
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom'

import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    Hits,
    SearchBox,
    Pagination,
    Highlight,
    ClearRefinements,
    connectRefinementList,
    Configure,
    createInfiniteHitsSessionStorageCache,
    InfiniteHits
} from 'react-instantsearch-dom';
import Header from '../../component/header/Header'
import './Product.css'

import { convertNodeToElement } from 'react-html-parser';


var itemsbox = []
const searchClient1 = algoliasearch(
    'NAH884LD6O',
    '155ad86e58cb50ae7eefab00df3bc15d'
);
var ArrayCart = []
const MobRefinementList = ({ items, refine }) => {
    { itemsbox = items }
    return (
        <ul className="list-style-none py-2">
            <div className="mob-visible ">
                <div>
                    <select className="filterbtn" onChange={event => {
                        refine(event.target.value);
                    }}>
                        {items.map(item => {
                            return (
                                <option key={item.label}>{item.label}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>

        </ul>
    );
}
const RefinementList = ({ items, refine, attribute }) => {
    { itemsbox = items }
    return (
        <ul className="list-style-none">
            <p style={{ fontSize: "14px", fontStyle: "normal", fontWeight: " 500", lineHeight: " 18px", letterSpacing: "0.25em" }} className="text-uppercase pt-4">{attribute}</p>

            {items.map(item => (
                <li key={item.label}>
                    <label className="checkbox-label p18font mr-4 mt-4 " > <p className="mx-4 filter-text">{item.label}</p>
                        <input type="checkbox" value='{filterdata}' className="" onClick={event => {
                            refine(item.value);
                        }} />
                        <span className="checkmark"></span>
                    </label>
                </li>

            ))}

        </ul>
    );
}
const CustomRefinementList = connectRefinementList(RefinementList);
const MobRefinementListarea = connectRefinementList(MobRefinementList);

const Product = (props) => {
    useEffect(() => {
        console.log('route change')
    }, [window.location.pathname])


    return (
        <div>
            <Header />
            <div className="ais-InstantSearch search-padding">
                <InstantSearch
                    indexName="zoomcartlive" searchClient={searchClient1} search='Royal' >
                    {/* {
                        console.log('negotiation')
                    } */}
                    <div className="">
                        <Configure hitsPerPage={6} />
                    </div>
                    <div className="right-panel" >
                        <div className="desktop-visible">
                            <p className="py-4  mx-sm-4 mx-1 desk-mpl5 product-rugs">Products/Rugs</p>
                        </div>
                        <div className="mob-search">
                            <SearchBox className="searchbtn" defaultRefinement={props.location.state ? props.location.state.category : ""}
                                submit={<img src={process.env.PUBLIC_URL + "/assets/productsearch.png"} alt="" width="30px" height="30px" />}
                            />
                            <div className="mx-3 ">
                                <div className="mob-visible">
                                    <p className="pt-3  ml-1 product-rugs">Products/Rugs</p>
                                </div>
                                <div className="py-1 mob-filterbtn">
                                    <MobRefinementListarea
                                        attribute="Size"
                                        searchable
                                    />
                                    <MobRefinementListarea
                                        attribute="Colour"
                                        searchable
                                    />
                                    <MobRefinementListarea
                                        attribute="Category"
                                        searchable
                                    />
                                    <MobRefinementListarea
                                        attribute="Knots"
                                        searchable
                                    />

                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="product ">
                            <div className=" mx-sm-4 mx-1 ">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-lg-3 desk-mpl5 checkboxfilter">
                                        <div className='bg-grey  h-auto py-3 '>
                                            <CustomRefinementList
                                                attribute="Size"
                                                searchable
                                            />
                                            <CustomRefinementList
                                                attribute="Colour"
                                                searchable
                                            />
                                            <CustomRefinementList
                                                attribute="Category"
                                                searchable
                                            />
                                            <CustomRefinementList
                                                attribute="Knots"
                                                searchable
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="contianer">
                                            <div className="row hitstyle ">
                                                {/* <Hits hitComponent={Hit} className="list-style-none row " /> */}
                                                <InfiniteHits
                                                    hitComponent={Hit}
                                                    translations={{
                                                        loadMore: Showmore,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </InstantSearch>


            </div>

            <Footer />
        </div>
    )
}
function Hit(props) {
    return (
        <div className="pb-5 links">
            <Link to={{
                pathname: `/product/${props.hit.item_code}`,
                state: props.hit
            }} className="">
                <div class="card  " style={{ border: 0 }}>
                    {}
                    <img class="card-img-top  productcardimg" src={props.hit['Image URL'] ? props.hit['Image URL'][0] != null && props.hit['Image URL'][0] != "" ? BASE + props.hit['Image URL'][0] : "../assets/square.jpeg" : "../assets/square.jpeg"} alt="Card image cap" />
                    <div class=" " >
                        <p class="card-text single-product-title" style={{ padding: "0", margin: "0" }}>{props.hit.item}</p>
                        <p class="card-text single-product-title" style={{ padding: "0", margin: "0" }}>BHD {props.hit.item_price}</p>
                    </div>
                </div>
            </Link>
        </div>
    );
}
function Showmore() {
    return (
        <div className="showmore-btn  text-center d-flex align-items-center justify-content-center" >
            <button className="btn bg-black text-white   btn-outline-none letterspace-25 border-0 border ">
                LOAD MORE
            </button>
        </div>
    );
}

Hit.propTypes = {
    hit: PropTypes.object.isRequired,
};

export default Product;

