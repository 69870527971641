import Axios from 'axios'
import {
    pay_loading,
    session_id,
} from '../../../actionTypes';
import { BASE } from '../../../api/api';


export const placeOrder1 = ({ qtn, price }) => dispatch => new Promise(async (resolve, reject) => {
    dispatch({ type: pay_loading, payload: true });
    localStorage.removeItem('order_id')
    var user_tocken = localStorage.getItem('tocken')

    var data = JSON.stringify({
        "reference_doc_id": qtn,
    });

    var config = {
        method: 'post',
        url: BASE + '/api/method/zoom_cart.order_api.sales_order_api',
        headers: {
            'Authorization': `Bearer ${user_tocken}`,
            'Content-Type': 'application/json',
            'Cookie': 'full_name=Guest; selling_price_list=Standard%20Selling; sid=Guest; system_user=no; user_id=Guest; user_image='
        },
        data: data
    };
    Axios(config)
        .then(function (response) {
            dispatch({ type: pay_loading, payload: false });
            localStorage.setItem('sales_order', response.data.message.name);
            // dispatch({ type: pay_loading, payload: false });
            // paymentRequestMethod({ sales_order: response.data.message, price: price })
            resolve({ sales_order: response.data.message.name, price: price })
        })
        .catch(function (error) {
            reject('placeOrder-failed')
            dispatch({ type: pay_loading, payload: false });
            console.error(error);
        });
})

export const paymentRequestMethod = (e) => dispatch => new Promise(async (resolve, reject) => {
    dispatch({ type: pay_loading, payload: true });
    var user_tocken = localStorage.getItem('tocken')
    // var sales_order = sales_order;

    // var data = JSON.stringify({
    //   "dt": "Sales Order",
    //   "dn": e.sales_order,
    //   "order_type": "Sales",
    //   "submit_doc": 1
    // }
    // );
    var data = JSON.stringify({
        "customer": "",
        "email": "",
        "amount": `${e.price}`,
        "reference": `${e.sales_order}`,
        "reference_doctype": "Sales Order",
        "reference_docname": `${e.sales_order}`,
        "docstatus": 1
    }
    );


    var config = {
        method: 'post',
        // url: BASE + '/api/method/erpnext.accounts.doctype.payment_request.payment_request.make_payment_request',
        url: BASE+'/api/resource/Afs%20Payment%20Request',
        headers: {
            'Authorization': `Bearer ${user_tocken}`,
            'Content-Type': 'application/json',
        },
        data: data
    };

    Axios(config)
        .then(function (response) {
            localStorage.setItem("order_id", response.data.data.name)
            dispatch({ type: pay_loading, payload: false });
            // sectionMethod(response.data.data.name, sales_order)
            resolve({ order_id: response.data.data.name, sales_order: e.sales_order })
        })
        .catch(function (error) {
            dispatch({ type: pay_loading, payload: false });
            console.error(error)
            reject('payment-request-method-failed');
        });

})

export const sectionMethod = (e,{cart__name}) => dispatch => new Promise(async (resolve, reject) => {
    dispatch({ type: pay_loading, payload: true });
    console.log('section Method -->',cart__name)
    var user_tocken = localStorage.getItem('tocken')
    localStorage.setItem("payment_detail", JSON.stringify([{
        cart_item: cart__name,
        order_id: e.order_id,
        sales_order: e.sales_order
    }]))
    var data = JSON.stringify({
        "order_id": e.order_id,
        "dt": "Sales Order",
        "dn": e.sales_order,
    });

    var config = {
        method: 'post',
        url: BASE + '/api/method/afs_integration.afs_integration.doctype.afs_settings.afs_settings.get_payment_info',
        headers: {
            'Authorization': `Bearer ${user_tocken}`,
            'Content-Type': 'application/json',
        },
        data: data
    };

    Axios(config)
        .then(function (response) {
            // localStorage.setItem("session_id", response.data.message.session.id)
            dispatch({ type: pay_loading, payload: false });
            // dispatch({ type: "session_id", payload: response.data.message.session.id })
            // dispatch({ type: pay_loading, payload: false });
            // resolve("session_id", response.data.message.session.id);
            resolve({ session_id: response.data.message.session.id })
            // afsMethod(response.data.message.session.id)
        })
        .catch(function (error) {
            // reject(error);
            dispatch({ type: pay_loading, payload: false });
            reject('section-method-failed')
        });
})

export const afsMethod = ({ session_id }) => dispatch => {
    dispatch({ type: pay_loading, payload: true });

    window.Checkout.configure({
        merchant: 'TEST100078691',
        session: {
            id: `${session_id}`,   // This is the session.id value that was generated in the first step
        },
        interaction: {
            merchant: {
                name: 'Zoom Test',  // This is your merchant name that will be display on the checkout page to your customer, typically it will be a brand name.
                email: "zoom_test@gmail.com",  // The merchant email, the will be displayed on the checkout page.
                phone: "+9661234567890",  // The merchant phone, the will be displayed on the checkout page.
                logo: "https://ap-gateway.mastercard.com/api/documentation/resource/footer.png",   // The merchant logo on the checkout page.
                url: "https://www.mastercard.com",  // the merchant website
                address: {  // Merchant Address, the will be displayed on the checkout page.
                    line1: 'Jeddah',
                    line2: 'Saudi Arabia'
                }
            },
            displayControl: {
                billingAddress: "HIDE",    // Prompt the user to enter the billing address. Possible options are "HIDE", "MANDATORY", "OPTIONAL", or "READ_ONLY".
                customerEmail: "HIDE",    // Prompt the user to enter their email. Possible options are "HIDE", "MANDATORY", "OPTIONAL", or "READ_ONLY".
                orderSummary: "SHOW",    // Display the order summary to in the checkout page. Possible options are "HIDE", "SHOW", or "SHOW_PARTIAL".
                paymentConfirmation: "HIDE",  // Prompt the user to confirm the payment before submitting. Possible options are "HIDE", or "SHOW".
                shipping: "HIDE"    // Display the shipping address to the user. Possible options are "HIDE", or "READ_ONLY".
            },
        },
    })
    // window.Checkout.showLightbox();
    window.Checkout.showPaymentPage()
    console.log(session_id)
}

