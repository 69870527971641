import React, { useEffect, useState, useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import '../../screens/Home/home.css'

const BasicButton = ({ name, function_call, submitbtn }) => {

    useEffect(() => {
        // console.log('use effect homepage working')
    }, [])

    return (
        <div className="">
            <button className={submitbtn ? " bth  btn-royalblue py-2 px-3 mb-5 mt-4 h8 text-align-center" : "bg-royalblue bth  btn-royalblue py-2 px-3 mb-5 mt-4 h8 text-align-center"} disabled={submitbtn ? true : false} onClick={function_call}>{name}</button>
        </div>
    )
}
export default BasicButton;


