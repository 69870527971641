import React, { useEffect, useState, useRef, useContext } from 'react'
import Header from '../../component/header/Header'
import Footer from '../../component/footer/Footer'
import { BASE } from '../../api/api'
import { GlobalContext } from '../../Context/Provider'
import { About_Us } from '../../Context/actions/Information/information'
import './DeliveryTimeandShippingPolicy.css'


const DeliveryTimeandShippingPolicy = () => {

    const {
        informDispatch,
        informState: {
            inform: { inform_loading, aboutus },
        },
    } = useContext(GlobalContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.location.hash == '#cancellation' ? window.location.href = '#cancellation' : window.location.href = '#delivery'

    }, [window.location.hash])

    return (
        <div>
            <Header />
            <div className="position-relative  " >
                <img src={aboutus ? BASE + aboutus.image : process.env.PUBLIC_URL + "/assets/aboutus.jpg"} className="img-fluid about-us-image" />
            </div>
            <div className="bg-grey py-5">
                <h4 className="royal-blue-text  px-5" id="delivery">
                    <u>Delivery Timeline / Shipping Policy</u>
                </h4>
                <div className="container py-5">

                    {/* <p className="px-5 mx-5 np-mob">{aboutus ? aboutus.our_story : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae tempor nulla. Ut commodo libero tristique magna volutpat, eu luctus erat imperdiet. Nunc tempus, lacus eget fringilla fringilla, magna massa imperdiet sapien, vel luctus dolor urna in metus. Integer vulputate neque vitae lectus hendrerit, in facilisis purus interdum. Mauris eget turpis eu sem iaculis vulputate sodales ut turpis. Morbi laoreet sit amet eros ut dapibus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque consequat ante vitae consectetur commodo. Duis sit amet dui quis turpis faucibus vehicula."}</p> */}
                    {/* <p>
                        ZOOMCART.ONLINE is a trademark of Zoom Consultancy Co. WLL, a company based in Bahrain. The domain name ZOOMCART.ONLINE is owned by the Zoom Consultancy Co. WLL. In this document, we refer to ourselves as "ZOOMCART.ONLINE ", "we" or "us" or "our" and we refer to you as "you", "your" or "Customer". We refer "Merchant" as "stores" or "retailers". What does ZOOMCART.ONLINE do? We are an online platform of Smart phone, Accessories and other electronic products across Bahrain   and we are the provider of the Services. The Services are made up of the following:
                    </p> */}
                    <div>
                        <ul className='list-style-lower-alpha'>
                            <li>Delivery: Products purchased in our platform will strictly be delivered. Delivery of the Products shall be made to the address you specify in your Registration and or by Order. For the shop pick up orders we will reserve the order for one day.</li>
                            <li>Delivery timeframe: You acknowledge that delivery of the Products within 1 to 3 days’ delivery according to the order slip you entered upon confirming the delivery.  All delivery timeframes given are estimates only and delays can occur. If the delivery of your Product is delayed “ZOOMCART.ONLINE” will inform you accordingly.</li>
                            <li>Deemed receipt: In the event you do not receive the Product by the projected delivery date and immediately inform ZOOMCART.ONLINE customer care in any manner.</li>
                            <li>Customer’s failure to take delivery: If the Customer fails to take delivery of the Products (otherwise than by reason of any cause beyond the Customer's reasonable control or by reason of Store’s) then without prejudice to any other right or remedy available to Store’s. ZOOMCART.ONLINE has the right to cancel the order.</li>
                            <li>Delivery is Totally Free for The Customers in Bahrain. Order Products Online & Shop Pickup Available for Free (We Will Hold Product for 1 Day after Confirmation) - Remember: Some Products May Not Available for Shop Pickup (In Delivery Mode, you can Choose Shop Pickup, If Your Cart is Eligible for Shop Pickup). </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="bg-grey py-5">
                <h4 className="royal-blue-text  px-5" id="cancellation">
                    <u>Cancellation, Refund & Exchange Policy</u>
                </h4>
                <div className="container py-5">

                    {/* <p className="px-5 mx-5 np-mob">{aboutus ? aboutus.our_story : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae tempor nulla. Ut commodo libero tristique magna volutpat, eu luctus erat imperdiet. Nunc tempus, lacus eget fringilla fringilla, magna massa imperdiet sapien, vel luctus dolor urna in metus. Integer vulputate neque vitae lectus hendrerit, in facilisis purus interdum. Mauris eget turpis eu sem iaculis vulputate sodales ut turpis. Morbi laoreet sit amet eros ut dapibus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque consequat ante vitae consectetur commodo. Duis sit amet dui quis turpis faucibus vehicula."}</p> */}
                    {/* <p>
                        ZOOMCART.ONLINE is a trademark of Zoom Consultancy Co. WLL, a company based in Bahrain. The domain name ZOOMCART.ONLINE is owned by the Zoom Consultancy Co. WLL. In this document, we refer to ourselves as "ZOOMCART.ONLINE ", "we" or "us" or "our" and we refer to you as "you", "your" or "Customer". We refer "Merchant" as "stores" or "retailers". What does ZOOMCART.ONLINE do? We are an online platform of Smart phone, Accessories and other electronic products across Bahrain   and we are the provider of the Services. The Services are made up of the following:
                    </p> */}
                    <div>
                        <ul className='list-style-lower-alpha'>
                            <li>You may exchange or return defective products or product delivered differently than the one ordered within 14 days of the receipt of such goods. All items need to be in their original packaging & without signs of damage</li>
                            <li>Zoomcart.online offers customers the option of a free exchange providing the item is priced the same or less than the one originally purchased on your order.</li>
                            <li>Products need to be returned within 14 days upon your notification of such return either in person or sent to the following address Office 336, Adliya, Bahrain. User is responsible for the charges of the return shipping.</li>
                            <li>In case of returned product a new product will be delivered in exchange of the defective product or wrong product received. Refunds are not applicable for returned products with the exception of unavailability of the returned product in stock. In which case a refund will be offered after reviewing the validity of the return.</li>
                            <li>Customers can cancel an order within 3 days of placing such an order. Orders once delivered or out for delivery cannot be cancelled.
                                It is not possible to cancel an online order without contacting one of our wonderful customer service representatives. Please contact us as soon as possible
                            </li>
                            <li>if you wish to cancel or amend your order, we are always happy to help. You can either call us on +973 38815222 or email help@zoomcart.online
                                Refunds will be made on orders cancelled once such cancellation is confirmed by means of an email communication.

                            </li>
                            <li>Card and EFT refunds online – Please note that each banking institution has a different lead time on refunds.</li>
                            <li>Refunds may take up to 10 working days to reflect in your account.</li>
                            <li>Refunds will be done only through the Original Mode of Payment.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default DeliveryTimeandShippingPolicy;


