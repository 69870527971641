import {
  logedin,
} from "../../../actionTypes";
import Axios from "axios";


export const Logout = () => dispatch => {
  localStorage.removeItem('usermail');
  localStorage.removeItem('tocken');
  localStorage.removeItem("loggedin");
  localStorage.setItem("reLogintime", 1);
  // localStorage.setItem("expire_date", 1);

  // localStorage.setItem('cartitems', null);
  dispatch({ type: logedin, payload: false });
}