import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Redirect, useHistory, } from 'react-router-dom'
import '../home.css'
import Header from '../../../component/header/Header'
import validator from 'validator'
import { useAlert } from "react-alert";
import BasicButtonSimple from '../../../component/atom/BasicButtonSimple'
import { validationform } from '../../../util/validation'
import { Register } from '../../../Context/actions/auth/register'
import { Login } from '../../../Context/actions/auth/login'
import { GlobalContext } from '../../../Context/Provider'
const MobRegister = () => {
    let history = useHistory()
    const alert = useAlert();

    const {
        authDispatch,
        authState: {
            auth: { user_loading, loading, error, data, isAuthenticated },
        },
    } = useContext(GlobalContext);


    // const { Register, state, Login } = useContext(RegContext)
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [fullname, setfullname] = useState('')

    const [validEmail, setvalidEmail] = useState(true)
    const [passwordvalid, setpasswordvalid] = useState(true)
    const [fullnamevalid, setfullnamevalid] = useState(true)


    const validationnone = () => {
        setvalidEmail('')
        setpasswordvalid('')
        setfullnamevalid('')
    }
    const Register_call = () => {
        Register(email, fullname, password)(authDispatch)
            .then(res => {
                alert.show("Account Created")
                Login(res.email, res.password)(authDispatch)
                    .then(() => {
                        localStorage.removeItem('loggednotify');
                        history.push("/")
                    })
                    .catch(() => {
                        alert.error("Invalid Email or Password");
                        localStorage.removeItem('loggednotify');

                    })
            })
            .catch(res => {

                if (res == "Already account created") {
                    alert.error("Already account created")
                } else if (res == "Registration failed") {
                    alert.error("Invalid Data Entered")

                }
            })
    }

    const validator_form = (e) => {
        if (e.name == 'register-email') {
            if (validEmail == false) {
                if (validationform(e)) {
                    setvalidEmail(true)
                } else {
                    setvalidEmail(true)
                }
            }
        } else
            if (e.name == 'register-fullname') {
                if (fullnamevalid == false) {
                    if (validationform(e)) {
                        setfullnamevalid(true)
                    } else {
                        setfullnamevalid(true)
                    }
                }
            } else
                if (e.name == 'register-password') {
                    if (passwordvalid == false) {
                        if (validationform(e)) {
                            setpasswordvalid(true)
                        } else {
                            setpasswordvalid(true)
                        }
                    }
                }
    }


    return (

        <div>
            <Header />
            <div className="desktop-visible">
                <div className="pt-5 text-center">
                    <Link className="display-6 bold text-black mt-5 text-decoration-none" to="/">OOPs webpage only available in mobile version  Click to return Home page</Link>
                </div>
            </div>
            <div className='tablet-visible mob-visible position-relative' style={{ height: "100%", border: "3px solid red" }}>
                <div className='' style={{ textIndent: '-9999px' }} >
                    {document.body.style.overflow = 'hidden'}
                </div>
                <div className='overlay-mob bg-white ' style={{ marginTop: "4rem" }} >
                    <div className="h-100">
                        <div className="login mx-5 ">
                            <form className="pt-5" autoComplete="off" onSubmit={(e)=>{e.preventDefault()}}>
                                <div className="inp-fields">
                                    <input autoComplete="off" onKeyPress={(e) => { setemail(e.target.value); validator_form(e.target) }} name="register-email" onBlur={e => { setemail(e.target.value); validationform(e.target) ? setvalidEmail(true) : setvalidEmail(false) }} className="profile-input d-block my-3" placeholder="E-mail*" />
                                    {validEmail ?
                                        ' '
                                        :
                                        <label className='valid-error'>Enter valid email address </label>
                                    }
                                    <input autoComplete="off" onKeyPress={(e) => { setfullname(e.target.value); validator_form(e.target) }} name="register-fullname" onBlur={e => { setfullname(e.target.value); validationform(e.target) ? setfullnamevalid(true) : setfullnamevalid(false) }} className="profile-input d-block my-3" type="text" placeholder="Full Name*" />
                                    {fullnamevalid ?

                                        ' '
                                        :
                                        <label className='valid-error'>full name is too short.full name must contain minimum 3 charachter </label>
                                    }
                                    <input autoComplete="off" onKeyPress={(e) => { setpassword(e.target.value); validator_form(e.target) }} name="register-password" onBlur={e => { setpassword(e.target.value); validationform(e.target) ? setpasswordvalid(true) : setpasswordvalid(false) }} className="profile-input d-block my-3" type="password" placeholder="Password*" />
                                    {passwordvalid ?

                                        ' '
                                        :
                                        <label className='valid-error'>Password at least 8 Characters , Contains At Least A Number, Special Character And A Mixture Of Uppercase And Lowercase Letters.</label>
                                    }
                                </div>
                                {/* <input type="button" className="btn-royalblue bg-royalblue my-5" value="Register"
                                    disabled={validEmail && fullnamevalid && passwordvalid ? false : true}
                                    onClick={() => { Register_call() }} style={{ height: "30px", width: "100%" }} /> */}
                                <BasicButtonSimple name="Register" submitbtn={validEmail && fullnamevalid && passwordvalid ? false : true} function_call={(e) => { Register_call() }} />

                            </form>
                            <div className="my-3" style={{ textAlign: "center" }}>

                                <Link to="/login" className="change-mode text-decoration-none text-black"><p className="change-mode desk-only" >or login to your account</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MobRegister;


