import React, { useEffect, useState, useRef } from 'react'
import Slickerpart1 from './Slickerpart1';
import './home.css'

const BestSelling = () => {

    useEffect(() => {
    }, [])


    return (

        <div className="bestselling-bg">
            <div className=' np-mob py-5 '>
                <div className="  text-center">
                    <div className="title  px-5 np-mob  d-inline-flex pb-5">
                        <p className="title-margin1" />
                        <h3 className='text-center sub-title px-3 d-inline-flex '>  Features </h3>
                        <p className="title-margin2" />
                    </div>
                    <div className="">
                        <Slickerpart1 />
                    </div>
                </div>

            </div>
        </div>

    )
}
export default BestSelling;


