import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../component/header/Header';
import arrow from '../../component/assets/Footer Right.png'

import './profile.css'

const MyProfile = () => {
    return (
        <div id="select_size">
            <Header />
            <div className="mobile-profile">
                <h2 className="container headmedium mt-5 mb-3 secondarycolor">
                    My Profile
                </h2>
                <div className="profile-menu">
                    <Link to="/profile-details/orders">
                        <div className="profile-menu-item">
                            <p>
                                My Orders
                        </p>
                            <img alt="icon" src={arrow} />
                        </div>
                    </Link>
                    <Link to="/profile-details/profileedit">
                        <div className="profile-menu-item">
                            <p>
                                Change Details
                        </p>
                            <img alt="icon" src={arrow} />
                        </div>
                    </Link>
                    <Link to="/profile-details/shippinglist">
                        <div className="profile-menu-item">
                            <p>
                                Shipping Address
                        </p>
                            <img alt="icon" src={arrow} />
                        </div>
                    </Link>
                    <Link to="/">
                        <div className="profile-menu-item" onClick={()=>{localStorage.removeItem("loggedin")}}>
                            <p>
                                Sign Out
                        </p>
                            <img alt="icon" src={arrow} />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default MyProfile;
