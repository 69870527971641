export default {
  auth: {
    loading: false,
    data: null,
    error: null,
    isAuthenticated: false,
    isAdmin: false,
    user_login: false,
    user_loading:false
  },
};
