import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import validator from 'validator'
import Header from '../../component/header/Header'
import Footer from '../../component/footer/Footer'
import AddAddress from '../../component/profiledetails/addaddress'
import './address.css'



const Address = (props) => {
    let history = useHistory()
    const AddressSubmit = () => {
        history.push('/profile-details/shippinglist')
    }
    
    return (

        <div>
            <Header />
            <div className="Address-Part  h-100 w-100">
                <form className="" onSubmit={(e) => { e.preventDefault(); AddressSubmit() }}>
                    <div className="container py-5 ">
                            <div className="px-3 ">
                                {/* <h3 className="h3-bold  border-bottom border-1 ">{props.location.state.method?"Update Address":"Add Address"}</h3> */}
                            </div>
                            <AddAddress location="/shippingaddress" address={props.location.state.value} method={props.location.state.method}/>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}
export default Address;


