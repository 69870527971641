import {
    carousel,
    latesttrend,
    aboutus,
    review,
    inform_loading,
} from '../../../actionTypes';
import Axios from 'axios';
import { BASE } from '../../../api/api';
//adding to shopping list

export const HomeCarousel = () => dispatch => new Promise((resolve, reject) => {
    // console.log('%c props value in banner carousel calling in HomeCarousel context', 'color:red;background:yellow')
    var data = '';

    var config = {
        method: 'get',
        url: BASE + '/api/resource/Carousel/CAR00001',
        headers: {
            'Authorization': 'token 155b35b5505b856:49f3f48eeed4907',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        data: data
    };

    Axios(config)
        .then(function (response) {
            dispatch({ type: carousel, payload: response.data.data.table_1 });
            resolve('carousel-calling-succesfull')
        })
        .catch(function (error) {
            console.error('carosel error is -->', error);
            reject('carousel-calling-failed')
        });

})
export const TrendCarousel = () => dispatch => new Promise(async (resolve, reject) => {
    // console.log('%c props value in trend carousel calling in HomeCarousel context', 'color:red;background:yellow')
    console.log('hei')
    var data = '';

    var config = {
        method: 'get',
        url: BASE + '/api/resource/Latest Trend/Latest Trend',
        headers: {
            'Authorization': 'token 155b35b5505b856:49f3f48eeed4907',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        data: data
    };

    Axios(config)
        .then(function (response) {
          
            let array = ''
            response.data.data.table_1.map((c) => {
                array = [...array, { 'name': '', 'img': BASE + c.attach_image_2, }]
            })
            console.log('TrendCarouse api calling -->',array )
            dispatch({ type: latesttrend, payload: array });
            resolve("success")

            // carousel
        })
        .catch(function (error) {

            console.error("trend Context error ", error);
            reject("trend error")
        });

})
    ;
export const About_Us = () => dispatch => new Promise(async (resolve, reject) => {
    var data = '';

    var config = {
        method: 'get',
        url: BASE + '/api/resource/About Us/About Us',
        headers: {
            'Authorization': 'token 155b35b5505b856:49f3f48eeed4907',
            'Content-Type': 'application/json'
        },
        data: data
    };

    Axios(config)
        .then(function (response) {
            // console.log('about us context calling', response.data.data);
            dispatch({ type: aboutus, payload: response.data.data });
            resolve('aboutus-Success')
        })
        .catch(function (error) {
            // console.error(error);
            reject('aboutus-Fail')

        });

})

export const Review = () => dispatch =>{
    console.log('Review function calling')
    dispatch({ type: inform_loading, payload: true });
    var data = '';
    var config = {
        method: 'get',
        url: BASE + '/api/resource/Review?fields=["review","name","name1","designation","image"]',
        headers: {
            'Authorization': 'token 155b35b5505b856:49f3f48eeed4907',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        data: data
    };

    Axios(config)
        .then(function (response) {
            dispatch({ type: inform_loading, payload: false });

            // console.log(JSON.stringify(response.data));
            dispatch({ type: review, payload: response.data });

        })
        .catch(function (error) {
            dispatch({ type: inform_loading, payload: false });

            console.error(error);
        });

}
export const Feedback = () => dispatch => async (phone, email, message, name) => {
    // console.log("%c feedback context ", 'background:yellow;color:green',);

    dispatch({ type: inform_loading, payload: true });

    // console.log('kill;', phone, email, message, name)
    var data = JSON.stringify({ "name1": name, "phone": phone, "email": email, "message": message });

    var config = {
        method: 'post',
        url: BASE + '/api/resource/Feedback Zoomcart',
        headers: {
            'Authorization': 'token 155b35b5505b856:49f3f48eeed4907',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        data: data
    };


    Axios(config)
        .then(function (response) {
            // console.log(`%c feedback context reponse  ${JSON.stringify(response.data)}`, 'background:yellow;color:green',);
            dispatch({ type: inform_loading, payload: false });

        })
        .catch(function (error) {
            console.error(error);
            dispatch({ type: inform_loading, payload: false });

        });

}
