import React, { useContext, useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import { BASE } from '../../api/api'
import { GlobalContext } from "../../Context/Provider";
import { HomeCarousel } from "../../Context/actions/Information/information";
import inform from "../../Context/reducers/inform";

const BannerCarosel = () => {
  const [imgid, setimgid] = useState(1)
  const {
    informDispatch,
    informState: {
      inform: { inform_loading, carousel },
    },
  } = useContext(GlobalContext);

  const sliderRef = useRef();

  var next = (props) => {
    sliderRef.current.slickNext();

  }
  var previous = () => {
    sliderRef.current.slickPrev();
  }

  useEffect(() => {
    next = next.bind()
    previous = previous.bind()
  })

  useEffect(() => {
    HomeCarousel()(informDispatch)
    .then(e=>{
      console.log('carousel -->',carousel)
    })
      
  }, [])


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: function (currentSlide) {
      setimgid(currentSlide + 1)
    },
  };
  return (
    <div className="">

      <Slider ref={sliderRef} {...settings}>
        {carousel ?
          carousel.map((x) => {


            return (
              <div key={x.idx}>
                <img src={x.image ? BASE + x.image : "/assets/homebanner1.png"} className="img-fluid home-banner" />
              </div>
            )
          }
          )
          :
          <div key={2}>
            <img src="assets/homebanner5.jpg" className=" home-banner img-fluid" />
          </div>
        }

      </Slider>
      {

      }
      <div style={{ textAlign: "-webkit-center" }}>
        <div className="caroselBanner-btn d-flex align-items-center" style={{ width: "131px", height: "40px", position: "relative", bottom: "70px" }}>

          <div className=" mouse-pointer " onClick={() => previous()} style={{}}>
            {
              <img className="Tarrow" src="/assets/LTarrow.png"></img>
            }
          </div>
          <div className="pt-2">
            <label className="text-white font-weight-bold pl-1">{imgid}</label>

            <label className="text-white pl-1 slah">/</label>

            <label className="text-white pl-1">{carousel ? carousel.length : 1}</label>
          </div>
          <div className="homecaroselbtn mouse-pointer" onClick={(e) => next(e.target.value)}>
            {
              <img className="Tarrow" src="/assets/GTarrow.png"></img>
            }
          </div>
        </div>
      </div>

    </div>
  );
}
export default BannerCarosel;