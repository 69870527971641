import React, { useEffect, useState, useRef } from 'react'
import { BASE } from '../../api/api'
import { Link, useHistory } from 'react-router-dom'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
    SearchBox,
    InstantSearch,
    connectHits,
    connectRefinementList,
    Configure,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

import './home.css'


const searchClient = algoliasearch(
    'NAH884LD6O',
    '155ad86e58cb50ae7eefab00df3bc15d'
);

const Slickerpart1 = () => {
    let imgid = 0
    var settings = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1.1,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3.3,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows: false,
                }
            },
        ]
    }
        ;
    const Hits = ({ hits }) => (

        <Slider {...settings}>

            {hits.map(hit => {
                if (imgid > hits.length - 1) { imgid = 0 }
                imgid = imgid + 1;
                return (
                    <div>
                        <Link to={{
                            pathname: `/product/${hit.item_code}`,
                            state: hit
                        }} className="">
                            <div class="card  text-white d-flex position-relative bestsellcard "  >
                                <img class=" img-fluid bestsellcard"
                                    src={hit['Image URL'] ? hit['Image URL'][0] != null ?hit['Image URL'][0] != '' ? BASE + hit['Image URL'][0] : "./assets/square.jpeg" : "./assets/square.jpeg": "./assets/square.jpeg"}
                                    alt="Card image" />
                                <div class="card-img-overlay  h-100 overlay-bg">
                                    <div class="bestselling-number  rounded-circle justify-content-center d-flex img-text" style={{ position: "absolute", top: "5px", left: "5px" }}>
                                        <p class="bestselling-number ">{imgid}</p>
                                    </div>
                                    <div class="d-flex h-100 ">
                                        <div class=" bestselling pl-1 align-self-end align-items-end  " >
                                            <h5 class=" align-self-end card-text ">{hit.item}</h5>
                                            <p class="sub-card-text">BHD {hit.item_price}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                )
            }
            )}
        </Slider>
    );
    const CustomHits = connectHits(Hits);
    return (
        <div className="desk-mpl5"
        >
            <InstantSearch
                indexName="zoomcartlive" searchClient={searchClient} >
                <div class="d-none">
                    <SearchBox className="searchbtn" defaultRefinement="Best Seller" />
                </div>
                <Configure hitsPerPage={5} />
                <CustomHits />
            </InstantSearch>
        </div>
    )
}
export default Slickerpart1;


