import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { oder_confirmation } from '../../util/order_Confirmation';
import { findCart_Detail } from '../../util/findCartdetail';

import { GlobalContext } from '../../Context/Provider';
import { cartUpdate } from '../../util/cartupdate';
import { cartupdate, getCart } from '../../Context/actions/cart/cart';
import { BASE } from '../../api/api';
const Cart = (props) => {
    const history = useHistory()
    const { push } = useHistory()
    let count = 0;
    const value = JSON.parse(localStorage.getItem('product'))
    const [cartbox, cartboxopen] = useState(false)
    const [cartItems, cartItemsopen] = useState(false)
    const {
        cartDispatch,
        cartState: {
            cart: { cart_status, cart_loading, cartname, getcart, get_data },
        }
    } = useContext(GlobalContext);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('cartupdates')) == 1) { getCart() }
        if (JSON.parse(localStorage.getItem('cartupdates')) == 1) { localStorage.setItem('cartupdates', 0) }
        get_data ? get_data.items == '' || get_data.items == undefined || get_data.items == [] ? cartboxopen(false) : cartboxopen(true) : cartboxopen(false)
        cartItemsopen(localStorage.getItem('cartitems'))

        // console.log('cart itemse availble -->', state.cart_status)
        // let a = JSON.parse(localStorage.getItem('payment_detail'))
        // console.log('cart value are aa--aa', a)
        // if (state.cart_status == false) {
        //     if (a) {
        //         if (a[0].cart_item) {
        //             oder_confirmation(a[0].order_id).then(e => e == 'ERROR' ? (console.log('need to add to cart', a[0].order_id),
        //                 findCart_Detail(a[0].cart_item)
        //                     .then(async (e) => {
        //                         console.log('result is ', e)
        //                         loading_function_active()
        //                         await e.map(x => cartupdate(x.item_code, x.qty))
        //                         localStorage.removeItem('payment_detail')
        //                         loading_function_end()

        //                     })
        //                     .catch(e => console.log('error in finddetailof cart', e))
        //             ) : console.log('no need to add cart', a[0].cart_item))
        //         }
        //     }
        // }
        // else { setcartItems(localStorage.getItem('cartitems')) }
        get_data ? get_data.items == '' || get_data.items == undefined || get_data.items == [] ? cartboxopen(false) : cartboxopen(true) : cartboxopen(false)


    })
    return (
        <div>
            {props.value ?

                <div className=' position-relative container' style={{ height: "100%" }}>
                    <div className='' style={{ textIndent: '-9999px' }}>
                        {document.body.style.overflow = 'hidden'}
                    </div>

                    <div className="d-lg-none d-md-block">
                        <div className='overlay-mob bg-white ' style={{ marginTop: "4rem" }} >
                            {cartbox ?


                                <div className="h-100 container ">
                                    {
                                        cartbox ?

                                            get_data.items.map((x) => (

                                                <div className="d-flex mt-5">
                                                    <div className="mx-3">
                                                        {JSON.parse(localStorage.getItem('cartpic')) ?
                                                            (count = 0,
                                                                JSON.parse(localStorage.getItem('cartpic')).map(
                                                                    (s) => {
                                                                        return (
                                                                            s.id == x.item_name
                                                                                ?
                                                                                (
                                                                                    < img src={s.img ? `${BASE}${s.img}` : process.env.PUBLIC_URL + "/assets/square.jpeg"} className="cart-img img-fluid" />)
                                                                                :
                                                                                (
                                                                                    count = count + 1,
                                                                                    JSON.parse(localStorage.getItem('cartpic')).length == count
                                                                                        ?
                                                                                        <img src={process.env.PUBLIC_URL + "/assets/square.jpeg"} className="cart-img img-fluid" />
                                                                                        : ""
                                                                                )
                                                                        )

                                                                    }
                                                                )
                                                            )
                                                            :
                                                            <img src={process.env.PUBLIC_URL + "/assets/square.jpeg"} className="cart-img img-fluid" />

                                                        }
                                                    </div>
                                                    <div className="mx-3">

                                                        <div className="">
                                                            <p className="my-1 p16" style={{ color: '#333333' }}>{x.item_name}</p>
                                                            <p className="my-1" style={{ fontSize: "14px", fontWeight: 500, textDecoration: 'line-through', color: '#777777' }}></p>
                                                            <p className="my-1" style={{ fontSize: "16px", fontWeight: 700, color: '#333333' }}>BHD {x.net_rate}</p>
                                                            <div className="">
                                                                <div className="row my-3">
                                                                    <button className="d-flex justify-content-center bg-royalblue btn-border carts-icon px-1 mx-2"
                                                                        onClick={() => cartUpdate({ item_code: x.item_code, items: get_data.items, op: 'minus' })
                                                                            .then(e => cartupdate({ items_array: e, qtn: cartname })(cartDispatch).then(console.log('cartupdate successfull')))
                                                                            .catch(e => e == 'MINUS_OPERATION_FAILED' ? alert.show('QTY IS ONLY 1') : "")
                                                                        }
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                            width="16" height="16"
                                                                            viewBox="0 0 172 172"
                                                                            className="justify-content-center align-self-center"
                                                                            style={{ fill: "#000000" }}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M21.5,78.83333v14.33333h129v-14.33333z"></path></g></g></svg>
                                                                    </button>

                                                                    {x.qty}
                                                                    <button className="d-flex justify-content-center bg-royalblue btn-border carts-icon px-1 mx-2 "

                                                                        onClick={() => cartUpdate({ item_code: x.item_code, items: get_data.items, op: 'add' })
                                                                            .then(e => cartupdate({ items_array: e, qtn: cartname })(cartDispatch).then(console.log('cartupdate successfull')))
                                                                        }

                                                                    >

                                                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                            width="16" height="16"
                                                                            viewBox="0 0 226 226"
                                                                            className="justify-content-center align-self-center"
                                                                            style={{ fill: "#000000" }}><g fill="none" fill-rule="none" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}><path d="M0,226v-226h226v226z" fill="none" fill-rule="nonzero"></path><g fill="#ffffff" fill-rule="evenodd"><path d="M103.58333,18.83333v84.75h-84.75v18.83333h84.75v84.75h18.83333v-84.75h84.75v-18.83333h-84.75v-84.75z"></path></g></g></svg>
                                                                    </button>
                                                                    BHD {x.base_amount}
                                                                    <button className="d-flex justify-content-center row bg-royalblue btn-border carts-icon px-1 mx-2"
                                                                        onClick={() =>
                                                                            cartUpdate({ item_code: x.item_code, items: get_data.items, op: 'del' })
                                                                                .then(e =>
                                                                                    cartupdate({ items_array: e, qtn: cartname })(cartDispatch)
                                                                                        .then(console.log('cartupdate successfull')))}


                                                                    >
                                                                        <img src={process.env.PUBLIC_URL + "/assets/bin.png"} style={{ height: "16px", width: "16px" }} className="cart-icons justify-content-center align-self-center "></img>
                                                                    </button>

                                                                </div>

                                                            </div>

                                                        </div>


                                                    </div>


                                                </div>

                                            ))
                                            :
                                            ""
                                    }
                                    <div style={{ position: 'absolute', bottom: '15%', background: 'white' }} className="mx-auto container-sm w-100 px-3">
                                        <div className=" w-100 d-flex justify-content-center align-self-end flex-column">
                                            <div className="col d-inline-flex justify-content-end align-items-end ">
                                                <p className="l20 align-self-center ">Subtotal</p>
                                                <p className=" price-text  mx-2 align-self-end" >BHD {get_data.total}</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-6">
                                                    <button className="  royalblue-btn  btn btn-block bg-royalblue text-white" onClick={() => history.push('/shippingaddress')}>CheckOut</button>
                                                </div>
                                                <div className="col-sm-6 col-6">
                                                    <button className="  royalblue-btn royalblue-border-btn whitebtn btn btn-block " onClick={() => history.push('/product')}>Keep Shopping</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="d-flex justify-content-center" style={{ minHeight: "460px" }}>
                                    <div className="align-self-center text-center">
                                        <img src={process.env.PUBLIC_URL + "/assets/emptycart.png"} className="img-fluid" />
                                        <h5>Your cart is currently empty?</h5>
                                        <p>Before proceed to checkout, you must add some products to your cart.You will find a lot intresting products on our  Product Page</p>
                                        <Link to='/product' className="text-decoration-none"><button className=" btn-block bg-royalblue bth  btn-royalblue text-white h5 mouse-pointer" >Go to Product page</button></Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                : <div style={{ textIndent: '-9999px', height: '0px' }}>{document.body.style.overflow = 'auto'}</div>}
        </div>
    )
}
export default Cart;