import React, { useEffect, useState, useContext } from "react";
import { useAlert } from "react-alert";
import { Redirect, useHistory } from "react-router-dom";
import {
  Hits,
  Pagination,
  Highlight,
  ClearRefinements,
  InstantSearch,
  SearchBox,
  RefinementList,
  Configure,
} from "react-instantsearch-dom";
import { Link } from "react-router-dom";
import { search_algolia } from "../../api/api";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { BASE } from "../../api/api";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import Slickerone from "../../component/slicker/Slickerone";
import RecommSlicker from "../../component/slicker/RecommSlicker";
import { searchClient as searchClient1 } from "../../api/api";
import algoliasearch from "algoliasearch/lite";
import PropTypes from "prop-types";
import { finding_img } from "../../util/findingimg";
import { user_detail } from '../../Context/actions/auth/loadUser'
import DesktopCart from "../../component/header/desktopCart";
import MobCart from "../../component/header/MobCart";
import {
  cartupdate,
  getCart,
  cartCreation,
} from "../../Context/actions/cart/cart";
import { GlobalContext } from "../../Context/Provider";
import { stock_check } from "../../Context/actions/cart/cart";
import { cartUpdate } from "../../util/cartupdate";
import "./Product.css";
import "./Productinner.css";

// const searchClient1 = algoliasearch(
//     'NAH884LD6O',
//     '155ad86e58cb50ae7eefab00df3bc15d'
// );

const Productinner = (props) => {
  console.log("props value are inner product -->", props);
  let history = useHistory();
  const alert = useAlert();
  const [loggedin, setloggedin] = useState("");
  const [stock, setstock] = useState(0);
  const [addtocart, setaddtocart] = useState("");
  // const { getCart, state, stock_check } = useContext(CartContext)
  // const [dyimg, setdyimg] = useState(props.location ? product?.['Image URL'][0] != null || product?.['Image URL'][0] != undefined ? product?.['Image URL'][0] : "../assets/square.jpeg" : "../assets/square.jpeg")
  const [dyimg, setdyimg] = useState("../assets/square.jpeg");
  const [cartbtn, cartbtnopen] = useState(true);
  const [imgactive, imgactiveopen] = useState(1);
  const [show, setShow] = useState(false);
  const [cart, cartopen] = useState(false);
  const [product, setproduct] = useState(false);

  const {
    authDispatch,
    authState: {
      auth: { user_loading, loading_freezz, error, data, isAuthenticated },
    },
    cartDispatch,
    cartState: {
      cart: { cart_status, cart_loading, cartname, getcart, get_data },
    },
  } = useContext(GlobalContext);

  const toggleShow = () => setShow((p) => !p);
  localStorage.setItem("cartpackage", JSON.stringify(addtocart));
  useEffect(async () => {
    console.log(
      `window.location.pathname.replace('/product/','')`,
      window.location.pathname.replace("/product/", "")
    );
    let a = await window.location.pathname.replace("/product/", "");
    if (a) {
      search_algolia(a).then((e) => {
        setproduct(e);
        console.log("product are-->", e?.item_code);
        if (e?.item_code) {
          stock_check({ item_code: e?.item_code })(cartDispatch)
            .then((e) => {
              setstock(e.message.actual_qty);
            })
            .catch((e) => {});
        } else {
          console.log("failed api calling");
        }
        setdyimg(
          e
            ? e["Image URL"][0] != null || e["Image URL"][0] != undefined
              ? e["Image URL"][0]
              : ""
            : ""
        );
      });
    }
  }, [window.location.pathname]);
  useEffect(() => {
    setloggedin(localStorage.getItem("loggedin"));
    imgactiveopen(1);
    // product?.['Image URL'][0] != null || product?.['Image URL'][0] != undefined ? setdyimg(product?.['Image URL'][0]) : setdyimg("")
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  const addCart = () => {
    cart_check() ?
        cartUpdate({ item_code: product?.item_code, items: get_data.items, op: 'add' })
            .then(e => {
                cartupdate({ items_array: e, qtn: cartname })(cartDispatch)
                    .then(e => console.log('cartupdate successfull'))
                    .catch(e => {
                        alert.error("Server is Busy ,Please try again later")
                    })
            }
            )
        // : ""
        :
        get_data.items == '' || get_data.items == undefined || get_data.items == [] ?
            user_detail()(authDispatch)
                .then((e) => {
                    cartCreation({ item_code: product?.item_code, party_name: e })(cartDispatch)
                })
                .catch(e=>{alert.error('Server is Busy , Please try again later');console.log('error-->',e)})
            :
            cartUpdate({ item_code: product?.item_code, items: get_data.items, op: 'add' })
                .then(e => {
                    cartupdate({ items_array: e, qtn: cartname })(cartDispatch)
                        .then(e => console.log('cartupdate successfull'))
                        .catch(e => {
                            alert.error("Server is Busy ,Please try again later")
                        })
                }
                )
}

  const cart_check = () => {
    console.log("cart check calling", getCart);
    if (get_data) {
      if (
        get_data.items == "" ||
        get_data.items == undefined ||
        get_data.items == []
      ) {
        return false;
      } else {
        return get_data.items.find((x) => x.item_code == product?.item_code)
          ? true
          : false;
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="ais-InstantSearch">
        {/* <h1>React InstantSearch e-commerce demo</h1> */}
        {/* <div>
                    
                    <input type='text' className="searchbtn" />
                    <img src={process.env.PUBLIC_URL + "/assets/productsearch.png"} alt="" width="30px" height="30px" />
                </div> */}
        <DesktopCart show={show} toggleShow={toggleShow} />
        <MobCart value={cart} />

        <InstantSearch indexName="zoomcartlive" searchClient={searchClient1}>
          <div className="left-panel">
            <RefinementList attribute="item_group" />
            <Configure hitsPerPage={8} />
          </div>
          <div className="mob-search">
            <SearchBox
              className="searchbtn"
              defaultRefinement={product ? product?.category : ""}
              submit={
                <img
                  src={process.env.PUBLIC_URL + "/assets/productsearch.png"}
                  alt=""
                  width="30px"
                  height="30px"
                />
              }
              onSubmit={(e) => {
                e.preventDefault();
                history.push({
                  pathname: "/product",
                  //  search: '?query=rugs',
                  state: { category: e.target[0].value },
                });
                // setsearchbarHide(true)
              }}
            />
          </div>
          <div className="mob-containter">
            <div className="ml-0 py-3 py-sm-0 linked">
              <Link to="/product" className="linked mob-visible">
                <div className="d-flex">
                  <img
                    className="small-icon"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAA00lEQVRoge3aMQrCQBRF0au4DJdi7wYS01i4OBvt3ZtgRAsZDJoEkojzZvIOTGH3L9+I6IDNyzr2AL9QAldgH3uQKUrgBjyAmkRjKt4R4dTAJuZQQzU30TwnYBVxrkEcocIRKhyhwhEqHKHCESocoSLJiMXH6wo48j3wBTgA938MNdWO9k2kcgDY8vqhIPYwo8+yd0eJSvqtleXDHhS0b+aM8MdvF8eocowqx6iaRYz01/wujlHlGFWOUZVVTBZ/TwfNzSR7YSAoyOAKR5DFpRob4wkQwGmIoG5w7AAAAABJRU5ErkJggg=="
                  ></img>
                  <label className="l12 text-black mx-2 linked">Back</label>
                </div>
              </Link>
            </div>
            <div className="screen">
              <div className="row">
                <div className="col-12 col-md-6 px-1">
                  <div className="bg-greyish p-2 h-100">
                    <div className="container h-100">
                      <div className="  py-1 displayscreen d-flex justify-content-center align-self-center h-100">
                        <div className="h-100 w-100 d-flex flex-column ">
                          <div className="h-100 mainscreen d-flex align-self-center align-items-center">
                            <img
                              src={
                                dyimg == "" || dyimg == "../assets/square.jpeg"
                                  ? "../assets/square.jpeg"
                                  : BASE + dyimg
                              }
                              className="img-fluid "
                              style={{ width: "auto" }}
                            />
                          </div>
                          <div className="sub-img py-4  align-self-center d-flex ">
                            <div
                              className={
                                imgactive === 1
                                  ? "sub-imgpart mx-1 d-flex align-items-center justify-content-center align-self-center opacity-100 "
                                  : "sub-imgpart mx-1 d-flex align-items-center justify-content-center align-self-center opacity-25"
                              }
                              onClick={() => {
                                imgactiveopen(1);
                                setdyimg(
                                  product?.["Image URL"]
                                    ? product?.["Image URL"][0] != null ||
                                      product?.["Image URL"][0] != undefined
                                      ? product?.["Image URL"][0]
                                      : ""
                                    : ""
                                );
                              }}
                            >
                              <img
                                src={
                                  product?.["Image URL"]
                                    ? product?.["Image URL"][0] != null ||
                                      product?.["Image URL"][0] != undefined
                                      ? BASE + product?.["Image URL"][0]
                                      : "../assets/square.jpeg"
                                    : "../assets/square.jpeg"
                                }
                                className="mob-subimgmax img-fluid my-1 px-sm-2"
                              />
                            </div>
                            <div
                              className={
                                imgactive === 2
                                  ? "sub-imgpart mx-1 d-flex align-items-center justify-content-center align-self-center opacity-100 "
                                  : "sub-imgpart mx-1 d-flex align-items-center justify-content-center align-self-center opacity-25"
                              }
                              onClick={() => {
                                imgactiveopen(2);
                                setdyimg(
                                  product?.["Image URL"]
                                    ? product?.["Image URL"][1] != null
                                      ? product?.["Image URL"][1]
                                      : ""
                                    : ""
                                );
                              }}
                            >
                              <img
                                src={
                                  product?.["Image URL"]
                                    ? product?.["Image URL"][1] != null
                                      ? BASE + product?.["Image URL"][1]
                                      : "../assets/square.jpeg"
                                    : "../assets/square.jpeg"
                                }
                                className="mob-subimgmax img-fluid my-1 px-sm-2"
                              />
                            </div>
                            <div
                              className={
                                imgactive === 3
                                  ? "sub-imgpart mx-1 d-flex align-items-center justify-content-center align-self-center opacity-100 "
                                  : "sub-imgpart mx-1 d-flex align-items-center justify-content-center align-self-center opacity-25"
                              }
                              onClick={() => {
                                imgactiveopen(3);
                                setdyimg(
                                  product?.["Image URL"]
                                    ? product?.["Image URL"][2] != null
                                      ? product?.["Image URL"][2]
                                      : ""
                                    : ""
                                );
                              }}
                            >
                              <img
                                src={
                                  product?.["Image URL"]
                                    ? product?.["Image URL"][2] != null
                                      ? BASE + product?.["Image URL"][2]
                                      : "../assets/square.jpeg"
                                    : "../assets/square.jpeg"
                                }
                                className="mob-subimgmax img-fluid my-1 px-sm-2"
                              />
                            </div>
                            <div
                              className={
                                imgactive === 4
                                  ? "sub-imgpart mx-1 d-flex align-items-center justify-content-center align-self-center opacity-100 "
                                  : "sub-imgpart mx-1 d-flex align-items-center justify-content-center align-self-center opacity-25"
                              }
                              onClick={() => {
                                imgactiveopen(4);
                                setdyimg(
                                  product?.["Image URL"]
                                    ? product?.["Image URL"][3] != null
                                      ? product?.["Image URL"][3]
                                      : ""
                                    : ""
                                );
                              }}
                            >
                              <img
                                src={
                                  product?.["Image URL"]
                                    ? product?.["Image URL"][3] != null
                                      ? BASE + product?.["Image URL"][3]
                                      : "../assets/square.jpeg"
                                    : "../assets/square.jpeg"
                                }
                                className="mob-subimgmax img-fluid my-1 px-sm-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 linked pt-sm-5 pr-md-5">
                  <div className="container pr-sm-5 pt-sm-4 innerpage-desc">
                    <Link to="/product" className="linked desktop-visible ">
                      <div className="d-flex">
                        <img
                          className="small-icon"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAA00lEQVRoge3aMQrCQBRF0au4DJdi7wYS01i4OBvt3ZtgRAsZDJoEkojzZvIOTGH3L9+I6IDNyzr2AL9QAldgH3uQKUrgBjyAmkRjKt4R4dTAJuZQQzU30TwnYBVxrkEcocIRKhyhwhEqHKHCESocoSLJiMXH6wo48j3wBTgA938MNdWO9k2kcgDY8vqhIPYwo8+yd0eJSvqtleXDHhS0b+aM8MdvF8eocowqx6iaRYz01/wujlHlGFWOUZVVTBZ/TwfNzSR7YSAoyOAKR5DFpRob4wkQwGmIoG5w7AAAAABJRU5ErkJggg=="
                        />{" "}
                        <img src="../assets/back.png" className="small-icon " />
                        <label className="l12 text-black linked">Back</label>
                      </div>
                    </Link>

                    <span className="h3-bold py-3">{product?.item}</span>
                    <span className="p16 py-3">Select Size</span>
                    <div>
                      <select className="filterbtn">
                        {product ? (
                          Array.isArray(product?.Size) ? (
                            product?.Size.map((x) => <option>{x}</option>)
                          ) : (
                            <option>{product?.Size}</option>
                          )
                        ) : (
                          <option>default</option>
                        )}
                      </select>
                    </div>
                    <span className="p16 py-3">Selected Colour</span>
                    <div className="border border-1 border-dark">
                      <div className="py-2 text-center">
                        <span className="text-center">{product?.Colour}</span>
                      </div>
                    </div>
                    <div id="product-description">
                      {product?.Description ? (
                        <div id="single-product-description">
                          {ReactHtmlParser(product?.Description)}
                        </div>
                      ) : (
                        <p className=" l12 py-3 text-grey">
                          A rich, rustic tribal pattern brings a bold pop of
                          color and design into your room creates a stunning
                          floor covering that’s modern, inviting and impeccably
                          timeless.
                        </p>
                      )}
                    </div>

                    <div
                      className="pt-3"
                      style={{ borderTop: "1px solid #65635F99" }}
                    >
                      <span className="h3-bold py-3">
                        BHD {product?.item_price}
                      </span>
                      <div className="pt-3 d-flex justify-content-between">
                        {/* <button className="btn btn-block text-white btn-border p16 bg-black py-3" onClick={() => loggedin ? addCart(props) : alert.show('You need to Login to add Product')} disabled={stock == 0 ? true : false} >{stock == 0 ? "OUT OF STOCK" : "ADD TO CART"}</button> */}
                        <button
                          className="btn w-100 text-white btn-border p16 bg-black py-3 mx-2 "
                          onClick={() =>
                            loggedin
                              ? cart_check()
                                ? // <DesktopCart show={true} />
                                  window.innerWidth > 992
                                  ? setShow(true)
                                  : cartopen(true)
                                : addCart(props)
                              : alert.show("You need to Login to add Product")
                          }
                          disabled={stock == 0 ? true : false}
                        >
                          {stock == 0
                            ? "OUT OF STOCK"
                            : cart_check()
                            ? "GO TO CART"
                            : "ADD TO CART"}
                        </button>
                        <button
                          className={
                            stock == 0
                              ? "d-none"
                              : "btn w-100 text-white btn-border p16 bg-buynow py-3 mx-2"
                          }
                          onClick={() =>
                            loggedin
                              ? (addCart(props),
                                history.push("/shippingaddress"))
                              : alert.show("You need to Login to add Product")
                          }
                        >
                          BUY NOW
                        </button>
                      </div>
                      <span className="p14 pt-1 text-grey d-block">
                        Taxes and shipping fees will apply upon checkout
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-5">
                <div className="title  px-sm-5  text-center">
                  <div className="d-inline-flex center">
                    <p className="" style={{ margin: "10px 0 22px" }} />
                    <h3 className="text-center sub-title px-sm-3 px-2 d-inline-flex">
                      {" "}
                      You May Also Like{" "}
                    </h3>
                    <p className="" style={{ margin: "10px 0 21px" }} />
                  </div>
                </div>
                <div className="py-5">
                  <div className="container np-mob">
                    <RecommSlicker category={product?.Categories} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </InstantSearch>
      </div>
      <Footer />
    </div>
  );
};
function Hit(props) {
  return <div>s</div>;
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};
export default Productinner;
