import React, { useEffect, useState, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom"
import Axios from 'axios';
import { BASE } from '../../api/api';
import { GlobalContext } from '../../Context/Provider';
import { Singel_order_api } from '../../Context/actions/order/order';
const OrderDetails = (props) => {
    const {
        orderDispatch,
        orderState: {
            order: { single_order }
        }
    } = useContext(GlobalContext)
    const find_img = (itemcode) => {
        var data = JSON.stringify({
            "item_code": "KSWA0918053"
        });

        var config = {
            method: 'post',
            headers: {
                'Authorization': 'token 155b35b5505b856:49f3f48eeed4907',
                'Content-Type': 'application/json',
                'Cookie': 'full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image='
            },
            data: data
        };

        Axios(config)
            .then(async function (response) {
                let police = await response.data.message[0].website_image_1 == null ? '../assets/square.jpeg' : response.data.message[0].website_image_1
                return police
            })
            .catch(function (error) {
                console.error(error);
            });

    }
    let history = useHistory()
    const location = useLocation()
    const params = useParams()
    let substring = location.hash.substring(1)
    let total = 0;

    useEffect(() => {
        Singel_order_api()(orderDispatch)

    }, [2])

    const AddressSubmit = () => {
        history.push('/profile-details/shippinglist')


    }

    return (

        <div>
            <div className="Address-Part  h-100 w-100">
                <form className="" onSubmit={(e) => { e.preventDefault(); AddressSubmit() }}>
                    <div className="container py-5 ">
                        {single_order ?
                            single_order.map(x =>
                                x.sales_invoice_name == substring ? (
                                    x.item_list.map(x => {
                                        let a = ''
                                        a = find_img()
                                        total = total + x.price
                                        return (
                                            <div className='border border-1 p-5 d-menu-item1'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        < img src={x.Image ? `${BASE}${x.Image}` : process.env.PUBLIC_URL + "/assets/square.jpeg"} className="cart-img img-fluid" />

                                                    </div>
                                                    <div className='col-8 '>
                                                        <p className='d-menu-item2'>Product : {x.item_name}</p>
                                                        <p className='d-menu-item2'>Quantity : {x.Quantity}</p>
                                                        <p className='d-menu-item2'>Price : BHD {x.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : ""
                            )
                            :
                            ""}
                        <div> <p className='d-menu-item1 text-end border-2 border-dark border-top py-3'>Total : BHD {total} </p></div>
                    </div>

                </form>
            </div>
        </div>
    )
}
export default OrderDetails;


