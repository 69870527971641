import Axios from 'axios'
import { BASE } from '../api/api';

export const finding_img = (item_code, item) => {
    let count = 0;
    let m = JSON.parse(localStorage.getItem('cartpic'))
    var data = JSON.stringify({
        "item_code": item_code,
    });

    var config = {
        method: 'post',
        url: `${BASE}/api/method/algolia_sync.zoomcart_api.ItemFilter`,
        headers: {
            'Authorization': 'token 155b35b5505b856:49f3f48eeed4907',
            'Content-Type': 'application/json'
        },
        data: data
    };

    Axios(config)
        .then(function (response) {
            if (localStorage.getItem('cartpic') == "" || localStorage.getItem('cartpic') == null) {
                // console.log("part one is working")
                let cartpic = [{
                    img: response.data.message[0].website_image_1 ? response.data.message[0].website_image_1 != null ? response.data.message[0].website_image_1 : "" : "",
                    id: item,
                },]
                localStorage.setItem('cartpic', JSON.stringify(cartpic))
            }
            else {
                let skip = false
                // console.log("array length in cartpic", localStorage.getItem('cartpic'))
                let cartpic = localStorage.getItem('cartpic');
                // console.log('joke is not easy', JSON.parse(cartpic))
                // console.log('before cart push value is ', item)
                JSON.parse(cartpic).map((c) => c.id == item ?
                    (count = count + 1, skip = true)
                    :
                    count = count + 1
                )
                // console.log('length of array ', m.length, 'count value', count)
                if (count == JSON.parse(cartpic).length && skip == true) {
                } else
                // console.log('value not found',m)
                {
                    cartpic = [...JSON.parse(cartpic), {
                        img: response.data.message[0].website_image_1 ? response.data.message[0].website_image_1 : "",
                        id: item,
                    }];
                    localStorage.setItem('cartpic', JSON.stringify(cartpic))
                }

                // JSON.parse(cartpic).map((c) => c.id == item ?
                //     (console.log("yes same data found", item),
                //         skip = true,
                //         count=count+1,
                //         console.log("data are different same data found", item, c.id)

                //     )
                //     :
                //     (   count=count+1,
                //         console.log("data are different same data found", item, c.id),
                //         skip = false,
                //         cartpic = [...JSON.parse(cartpic), {
                //             img: e['Image URL'] ? e['Image URL'][0] : "../assets/square.jpeg",
                //             id: item,
                //         }
                //         ],
                //         localStorage.setItem('cartpic', JSON.stringify(cartpic)),
                //         console.log('count value',count,'length of cartpic',cartpic.length)

                //     )

                // )

                // console.log("skip value is ", skip)
                // if (skip == false) {localStorage.setItem('cartpic', JSON.stringify(cartpic))}
                // console.log('cartpic if condition else', cartpic)


            }

        })
        .catch(function (error) {
            console.error(error);
        });
}