import Axios from 'axios'
import { BASE } from '../../../api/api';
import {
  user_loading,
  register,
} from "../../../actionTypes";


export const Register = (email, fullname, password) => dispatch => new Promise(async (resolve, reject) => {
  // console.log("registration email", email, "full_name", fullname, "password", password)
  dispatch({ type: user_loading, payload: true })
  // console.log('Register Context calling');
  // var data = JSON.stringify({ "name": fullname, "email": email, "password": password, });
  var data = JSON.stringify({
    "name": fullname,
    "email": email,
    "password": password,
    "redirect_to": "https://zoom-react.pages.dev/"
  });

  var config = {
    method: 'post',
    url: `${BASE}/api/method/zoom_cart.otp_verification.signup`,
    headers: {
      'Authorization': 'token d03c7c3e6669a04:5596309d2292515',
      'Content-Type': 'application/json',
    },
    data: data
  };
  Axios(config)
    .then(function (response) {
      // console.log(`You have already registered with email ${email}`, "registration context working", response.data.message);
      localStorage.setItem('regnotify', true)
      dispatch({ type: user_loading, payload: false })

      if (response.data.message == `You have already registered with email ${email}`) {
        dispatch({ type: register, payload: false });
        reject("Already account created");
      } else {
        dispatch({ type: register, payload: true });
        resolve({ 'email': email, 'password': password });
      }
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      dispatch({ type: user_loading, payload: false })
      console.error(error);
      reject("Registration failed");
    });
})