
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Redirect, useHistory, } from 'react-router-dom'
import { useAlert } from "react-alert";
import algoliasearch from 'algoliasearch/lite';
import DesktopCart from './desktopCart';
import Desktopforgotten from './desktopForgotten';
import LogoutPopup from './logoutPopup'
import {
    InstantSearch,
    Hits,
    SearchBox,
    Configure,
    Index,
} from 'react-instantsearch-dom';
import Loading from '../loading/Loading';
import close from '../assets/close.png'
import MobCart from './MobCart'
import DesktopLogin from './desktopLogin'
import DesktopRegister from './desktopRegister';

import './headernew.css'

import { searchClient, search_algolia } from '../../api/api';
import { GlobalContext } from '../../Context/Provider';
import { oder_confirmation } from '../../util/order_Confirmation';
import { findCart_Detail } from '../../util/findCartdetail';
import { Logout } from '../../Context/actions/auth/logout';
import { reloagin_oath } from '../../Context/actions/auth/login';
import { getCart, fetchCart, cartCreation, cart_switch_address } from '../../Context/actions/cart/cart';

const Header = (props) => {
    const alert = useAlert();

    let history = useHistory()
    const { push } = useHistory()
    const value = JSON.parse(localStorage.getItem('product'))
    const usermail = localStorage.getItem('usermail')
    const path = window.location.pathname
    const [cart, cartopen] = useState(false)
    const [forgotenacc, forgotenaccopen] = useState(false)
    const [loading_screen, setloading_screen] = useState(false)
    const [loading_, setloading] = useState(true)
    const [loading_frezz, setloading_frezz] = useState(false)
    const [headerpos, setheaderpos] = useState(false)
    const [refreshTImestampState, setrefreshTimestampState] = useState(false)
    const [cartbox, cartboxopen] = useState(false)
    const [login, openlogin] = useState(false)
    const [loggedacc, openloggedacc] = useState(false)
    const [acc, openacc] = useState(false)
    const [logoutPopup, openLogoutPopup] = useState(false)
    const [searchbarHide, setsearchbarHide] = useState(false)
    const [searchvalue, setSearchValue] = useState('')

    // validation
    const [loggedin, setloggedin] = useState('')
    const [accout, setaccout] = useState('')
    const [notify, setnotify] = useState('')
    const [loginnotify, setloginnotify] = useState('')
    const [regnotify, setregnotify] = useState('')
    const [forgottennotify, setforgottennotify] = useState('')

    const [cartItems, setcartItems] = useState(null)

    const [show, setShow] = useState(false);

    const {
        authDispatch,
        authState: {
            auth: { user_loading, loading_freezz, error, data, isAuthenticated, user_login },
        },
        cartDispatch,
        cartState: {
            cart: { cart_status, cart_loading, cartname, getcart, get_data },
        },
        orderState: { order_loading },
        addressState: { add_loading },
        paymentState: { pay_loading },
        informState: { inform_loading },

    } = useContext(GlobalContext);


    const [scrollY, setScrollY] = useState(0);

    function logit() {
        setScrollY(window.pageYOffset);
        // console.log(new Date().getTime());
    }


    useEffect(() => {
        setloading(user_loading)
        getCart()(cartDispatch).then(e =>
            fetchCart({ qtn: e })(cartDispatch)
        )
        search_algolia()
    }, [])
    useEffect(() => {
        // console.log('user _ loading --->', user_loading)
        // console.log('cart _ loading --->', cart_loading)
        // console.log('order _ loading --->', order_loading)
        // console.log('add _ loading --->', add_loading)
        // console.log('pay _ loading --->', pay_loading)
        // console.log('inform _ loading --->', inform_loading)
        cart_loading || user_loading || order_loading || add_loading || pay_loading || inform_loading ? setloading(true) : setloading(false)
        setrefreshTimestampState(localStorage.getItem('refresh_token_login'))
        // setloading(user_loading)
        setloading_frezz(loading_freezz)
        if (JSON.parse(localStorage.getItem('cartupdates')) == 1) {
            getCart()(cartDispatch).then(e =>
                fetchCart({ qtn: e })(cartDispatch)
            )
        }
        if (JSON.parse(localStorage.getItem('cartupdates')) == 1) { localStorage.setItem('cartupdates', 0) }
        setcartItems(localStorage.getItem('cartitems'))
        // console.log('get cart value is -->', get_data)
        get_data ? get_data.items == '' || get_data.items == undefined || get_data.items == [] ? cartboxopen(false) : cartboxopen(true) : cartboxopen(false)
        setloggedin(localStorage.getItem('loggedin'))
        if (loggedin) {
            setaccout(user_login)
            openlogin(false)
        } else {
            openloggedacc(false)
        }

        // notification state update -- start
        setnotify(localStorage.getItem('notification'))
        setloginnotify(JSON.parse(localStorage.getItem('loggednotify')))
        setregnotify(localStorage.getItem('regnotify'))
        setforgottennotify(localStorage.getItem('forgottennotify'))
        // notification state update -- end


        const currentDateTime = new Date();
        const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000);
        const expireTimestamp = JSON.parse(localStorage.getItem('expire_date'));
        const refreshTimestamp = JSON.parse(localStorage.getItem('reLogintime'));


        if (expireTimestamp) {
            // console.log('expireTimestamp', expireTimestamp, 'current_time', currentTimestamp, 'refresh time stamp', refreshTimestamp)
            // if (refreshTImestampState == true) {
            //     if (refreshTimestamp > 1) {
            //         if (currentTimestamp > refreshTimestamp) {
            //             reloagin_oath()(authDispatch)
            //                 .then(e => setrefreshTimestampState(false))
            //                 .catch(e => console.error('relogin_oath_failed'))
            //         }
            //     }
            // }
            if (loggedin) {
                if (currentTimestamp > refreshTimestamp) {
                    reloagin_oath()(authDispatch)
                        .then(e => setrefreshTimestampState(false))
                        .catch(e => {
                            openLogoutPopup(true)
                            Logout()(authDispatch)
                            history.push('/')
                            localStorage.removeItem('expire_date')
                        })
                }
                if (currentTimestamp > expireTimestamp) {
                    openLogoutPopup(true)
                    Logout()(authDispatch)
                    history.push('/')
                    localStorage.removeItem('expire_date')
                }
            }
        }

        function watchScroll() {
            window.addEventListener("scroll", logit);
        }
        watchScroll();
        scrollY > 52 ? setheaderpos(true) : setheaderpos(false)
        return () => {
            window.removeEventListener("scroll", logit);
        };



    })






    // code need to change --start

    useEffect(async () => {
        await getCart()(cartDispatch)
            .catch(e => {
                setloading_screen(true)
                if (e == "GETCART-FAIL-NO-OBJECT") {
                    let a = JSON.parse(localStorage.getItem('payment_detail'))
                    if (a) {
                        if (a[0].cart_item) {
                            oder_confirmation(a[0].order_id)
                                .then(e => e == 'ERROR' ? (
                                    setloading_screen(true),
                                    findCart_Detail(a[0].cart_item)
                                        .then(async (e) => {
                                            let Item_Array = e.map(
                                                ({ item_code, qty }) => ({
                                                    item_code: item_code,
                                                    qty: qty
                                                }),
                                            );
                                            cartCreation({ group_of_item: Item_Array })(cartDispatch)
                                                .then(e => {
                                                    let address = localStorage.getItem('shipment_name -->')
                                                    cart_switch_address({ shipment_name: address, qtn: e, customer_name: localStorage.getItem('user_name') })(cartDispatch)
                                                        .then(r => {
                                                            setloading_screen(false)
                                                            localStorage.removeItem('payment_detail')
                                                        })
                                                        .catch(e => {
                                                            console.error('switch-address function fail in Context');
                                                            setloading_screen(false)
                                                        })
                                                })
                                                .then(e => {
                                                    setloading_screen(false)
                                                })
                                                .catch(e => {
                                                    setloading_screen(false)
                                                })
                                        })
                                        .catch(e => {
                                            console.
                                                log('error in finddetailof cart', e);
                                            setloading_screen(false)
                                        })
                                )
                                    :
                                    getCart()(cartDispatch).then(e => fetchCart({ qtn: e })(cartDispatch))
                                )
                                .catch(e => setloading_screen(false))
                        } else {
                            setloading_screen(false)
                        }
                    } else {
                        setloading_screen(false)
                    }

                } else {
                    console.log('set loading value is ', loading_screen)
                    setloading_screen(false)
                }

            })
            .catch(e => {
                console.log('get cart error')
            })


    }, [window.location.pathname])

    // useEffect end
    // code need to change --end


    const toggleShow = () => setShow(p => !p);
    const toggleLoginShow = () => openlogin(p => !p);
    const toggleforgottenShow = () => { forgotenaccopen(p => !p) };
    const toggleaccShow = () => openacc(p => !p);
    const togglePopup = () => { openLogoutPopup(p => !p) };
    return (
        <div className={headerpos ? "header-mob-position mob-nopadding" : "mob-nopadding"} style={{ borderBottom: "1px solid rgb(227, 227, 227)" }} >
            {loading_screen ? <Loading /> : ""}
            {loading_ ? <Loading /> : ""}
            {loading_frezz ? <Loading /> : ""}
            {/* <Hit objectID="2433252001"/> */}
            <div className="desktop-container-lg   np-mob nav-tablet-padding bg-white ">
                <nav className="navbar navbar-expand-lg  justify-content-between text-lg-center pt-lg-4 pb-lg-3"
                    onClick={(e) => { e.target.classList.toggle('navbar-toggler-active') }}
                >

                    {/* menu toogle icon creation start */}

                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                     aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                        onClick={(e) => { e.target.parentElement.classList.toggle('navbar-toggler-active') }}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                    </button>

                    {/* menu toogle icon creation end */}

                    <Link className="navbar-brand desktop" to="/" href="#">
                        <img src={process.env.PUBLIC_URL + "/assets/zoomcartlogo.png"} alt='zoom carat logo' className='logo' />
                    </Link>

                    {/* mobile screen visible only -start */}
                    <div className=" d-lg-none d-sm-block d-md-block" onClick={() => { loggedin ? cartopen(!cart) : alert.show("You must login") }}>
                        <div className='position-relative'>
                            {cart ?
                                < img src={process.env.PUBLIC_URL + "../assets/close.png"} />

                                :

                                <div className='d-flex px-0 mx-0'>
                                    <img src={process.env.PUBLIC_URL + "/assets/Z_carticon.svg"} style={{ width: '24px', height: '24px' }} />
                                    <label className="px-1 mouse-pointer m-0 header-sub-link "  >Cart</label>
                                </div>
                            }
                            {
                                cart ?
                                    "" :
                                    cartbox ?
                                        usermail ?
                                            get_data.items ?
                                                get_data.items.length != 0 ?
                                                    <div className='position-absolute cart-pos'><p>{get_data.items.length}</p>
                                                    </div>
                                                    : ''
                                                : ''
                                            : ""
                                        : ""
                            }
                        </div>

                    </div>
                    {/* mobile screen visible only -end */}



                    <div className="collapse navbar-collapse menu-drawer" id="navbarSupportedContent">

                        {/* mobile screen visible  -start for login and signup link in mobile drawer*/}
                        <div className=" d-lg-none d-sm-block d-md-block ">
                            <div className="d-flex-inline my-3  menu-option mob-profile">
                                {loggedin ?
                                    <div className='px-sm-5'>
                                        <img src={process.env.PUBLIC_URL + "/assets/profile_info1.png"} style={{ width: "32px", height: "32px" }} />
                                        <label className="ml-1 text-black" onClick={() => { window.location.href = "/profile" }} >Profile</label>
                                    </div>
                                    :
                                    <div className='px-sm-5'>
                                        <img src={process.env.PUBLIC_URL + "/assets/profile11.png"} style={{ width: "32px", height: "32px" }} />
                                        <Link to="/login"><label className="ml-1 text-black" >Login</label></Link>
                                        <label className="ml-1"> or </label>
                                        <Link to="/register"><label className="ml-1 text-black " >Signup</label></Link>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* mobile screen visible  -end for login and signup link in mobile drawer*/}


                        <ul className="navbar-nav mr-auto links w-100 menu-padding justify-content-start align-self-center align-items-lg-center nav-mob-padding">
                            <li className="nav-item active menu-option px-xl-3 px-lg-2 px-md-1">
                                <Link className="header-link" to='/'  >
                                    <label className="mouse-pointer m-0" style={{ borderBottom: path == '/' ? '#1D1C1F 2px solid ' : 'black' }} >Home</label>
                                </Link>
                            </li>
                            <li className="nav-item menu-option px-xl-3 px-lg-2 px-md-1">
                                <Link className="header-link" to='/product'>   <label className="mouse-pointer m-0" style={{ borderBottom: path == '/product' || window.location.pathname.includes('/product/') ? '#1D1C1F 2px solid' : 'black' }}>Products</label></Link>
                            </li>
                            <li className="nav-item menu-option dropdown px-xl-3 px-lg-2 px-md-1">
                                <Link className="header-link" to='/aboutus'  > <label className="mouse-pointer m-0" style={{ borderBottom: path == '/aboutus' ? '#1D1C1F 2px solid' : 'black' }}>About Us</label></Link>
                            </li>
                            <li className="nav-item menu-option px-xl-3 px-lg-2 px-md-1">
                                <Link className="header-link" to='/contactus'> <label className="mouse-pointer m-0" style={{ borderBottom: path == '/contactus' ? '#1D1C1F 2px solid' : 'black' }}>Contact Us</label></Link>
                            </li>
                        </ul>


                        <div className="d-flex d-none d-lg-block">
                            <div className="align-self-center d-flex ">

                                {/* search bar using algoliasearch engine -start */}
                                <div
                                    onBlur={(e) => (!e.relatedTarget) ? setsearchbarHide(false) : ''}
                                >
                                    <InstantSearch
                                        indexName="zoomcartlive" searchClient={searchClient}
                                    >
                                        <Configure hitsPerPage={3} />
                                        <SearchBox
                                            loadingIndicator={true}
                                            onFocus={() => setsearchbarHide(true)}
                                            onChange={(e) => { setsearchbarHide(false); setSearchValue(e.target.defaultValue) }}
                                            className="searchbar"
                                            autoFocus={false}
                                            translations={{ placeholder: 'Search' }}
                                            // searchAsYouType={false}
                                            submit={<img src={process.env.PUBLIC_URL + "/assets/searchicon.png"} alt="" />}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                history.push({
                                                    pathname: '/product',
                                                    //  search: '?query=rugs',
                                                    state: { category: e.target[0].value }
                                                })
                                                setsearchbarHide(true)
                                            }}
                                        />
                                        <div
                                            class={searchbarHide ? "position-absolute bg-white search-hits search-border" : "d-none"}
                                            style={{ top: "70%", zIndex: "999999" }} onBlur={() => setsearchbarHide(false)} >
                                            <Hits hitComponent={Hit} className="list-style-none search-hit-list row " />
                                        </div>
                                    </InstantSearch>
                                </div>
                                {/* search bar using algoliasearch engine -end */}


                                {/* screen limit set to desktop - tablet */}
                                <div className='desktop-visible border-2 border-secondary header-sub-link-parent  '>
                                    <div onClick={() => {
                                        if (loggedin) {
                                            setShow(true); getCart()(cartDispatch).then(e => {
                                                fetchCart({ qtn: e })(cartDispatch)
                                                console.log('cart value is found')
                                            }
                                            )
                                        } else { alert.show("You must Login") }
                                    }} className=" d-flex position-relative mouse-pointer mx-md-3 mx-lg-1 align-self-center"><img class="px-2 header-icon-logo" src={process.env.PUBLIC_URL + "/assets/Z_carticon.svg"} />
                                        <label className="mouse-pointer m-0 header-sub-link-cart "  >Cart</label>
                                        {/* <div className='position-absolute cart-pos'><p>1</p></div> */}
                                        {cartbox ?
                                            usermail ?

                                                get_data ?
                                                    get_data.items.length != 0 ?
                                                        <div className='position-absolute cart-pos'><p>{get_data.items.length}</p>
                                                        </div>
                                                        : ''
                                                    : ''
                                                : ''
                                            : ""
                                        }
                                    </div>
                                    {loggedin ?

                                        <div className="header-sub-link-login img-fluid mouse-pointer align-self-center pt-1 d-flex" onClick={() => history.push('/profile-details/passwordchange')}><img class="px-2 header-icon-logo" src={process.env.PUBLIC_URL + "/assets/z_account.svg"} />
                                            <label className="mouse-pointer m-0 "  >Profile</label>
                                        </div>
                                        :
                                        <div onClick={() => { openlogin(true) }} className="header-sub-link-login d-flex img-fluid mouse-pointer align-self-center pt-1"><img class="px-2 header-icon-logo" src={process.env.PUBLIC_URL + "/assets/z_account.svg"} />
                                            <span className="mouse-pointer m-0y"  >Login</span>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {loggedin ?
                            <button className="btn hover-pointer btn-border border-1 d-lg-none px-sm-5"
                                onClick={() => { Logout()(authDispatch); localStorage.removeItem("loggedin"); history.push("/"); alert.show("User logout") }} >
                                <img src={process.env.PUBLIC_URL + "/assets/logout_mob.png"} className="ml-2" style={{ width: "16px", height: "16px" }} />
                                <span className='pl-3'>
                                    Logout
                                </span>
                            </button>
                            : ""}
                    </div>
                </nav>
            </div>

            <MobCart value={cart} />
            <DesktopCart show={show} toggleShow={toggleShow} />
            {login ? <div className="overlay" x></div> : ""}
            <DesktopLogin login={login} toggleLoginShow={toggleLoginShow} toggleforgottenShow={toggleforgottenShow} toggleaccShow={toggleaccShow} />
            {loggedacc ? <div className="overlay"></div> : ""}
            {acc ? <div className="overlay"></div> : ""}
            <DesktopRegister acc={acc} toggleLoginShow={toggleLoginShow} toggleaccShow={toggleaccShow} />
            <Desktopforgotten forgotenacc={forgotenacc} toggleforgottenShow={toggleforgottenShow} />
            <LogoutPopup logoutPopup={logoutPopup} togglePopup={togglePopup} />

        </div>
    )
    function Hit(props) {
        searchvalue?.length > 0 ? setsearchbarHide(true) : setsearchbarHide(false)
        return (
            <div className="links border-1 border-bottom pt-1 px-2 search-result"
            >
                <Link to={{
                    pathname: `/product/${props.hit.item_code}`,
                    state: props.hit
                }} className=""
                >
                    <div class="card  " style={{ border: 0 }}>
                        <div class=" " >
                            <p class="card-text p16font">{props.hit.item}</p>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}
export default Header;