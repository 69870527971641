import React, { useEffect, useState, useRef, useContext } from 'react'
import Header from '../../component/header/Header'
import Footer from '../../component/footer/Footer'
import { BASE } from '../../api/api'
import { GlobalContext } from '../../Context/Provider'
import { About_Us } from '../../Context/actions/Information/information'
import './aboutus.css'


const Aboutus = () => {

    const {
        informDispatch,
        informState: {
            inform: { inform_loading, aboutus },
        },
    } = useContext(GlobalContext);

    // useEffect(() => {
    //     About_Us()(informDispatch)
    //         .then(() => {

    //             console.log('state -->', aboutus)
    //         })
    // }, [])
    useEffect(() => {
        // if (aboutus) { console.log('nim -- state -->', aboutus.image) }
    }, [aboutus])


    return (
        <div>
            <Header />
            <div className="position-relative  " >
                <img src={aboutus ? BASE + aboutus.image : process.env.PUBLIC_URL + "/assets/aboutus.jpg"} className="img-fluid about-us-image" />
            </div>
            <div className="bg-grey py-5">
                <h4 className="royal-blue-text  px-5" >
                    <u>About Us</u>
                </h4>
                <div className="container py-5">

                    {/* <p className="px-5 mx-5 np-mob">{aboutus ? aboutus.our_story : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae tempor nulla. Ut commodo libero tristique magna volutpat, eu luctus erat imperdiet. Nunc tempus, lacus eget fringilla fringilla, magna massa imperdiet sapien, vel luctus dolor urna in metus. Integer vulputate neque vitae lectus hendrerit, in facilisis purus interdum. Mauris eget turpis eu sem iaculis vulputate sodales ut turpis. Morbi laoreet sit amet eros ut dapibus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque consequat ante vitae consectetur commodo. Duis sit amet dui quis turpis faucibus vehicula."}</p> */}
                    <p>
                        ZOOMCART.ONLINE is a trademark of Zoom Consultancy Co. WLL, a company based in Bahrain. The domain name ZOOMCART.ONLINE is owned by the Zoom Consultancy Co. WLL. In this document, we refer to ourselves as "ZOOMCART.ONLINE ", "we" or "us" or "our" and we refer to you as "you", "your" or "Customer". We refer "Merchant" as "stores" or "retailers". What does ZOOMCART.ONLINE do? We are an online platform of Smart phone, Accessories and other electronic products across Bahrain   and we are the provider of the Services. The Services are made up of the following:
                    </p>
                    <div>
                        <ul className='list-style-lower-alpha'>
                            <li>Online shopping: Part of the Services relates to communicating information to you about Smart phone, Accessories and other electronics products and services available at those stores and allowing you to shop products and services available at those Stores – and through our online portal. For the customer order through call/WhatsApp only have Limited Access.</li>
                            <li>Agreement: We may change these Terms at any time and all changes will be posted on-line. These Terms were most recently updated on DATE. The Agreement exists between you and us from the time you start using (or whenever you use) our Services until you stop using our Services entirely and you tell us (by post or by email at ZOOMCART.ONLINE EMAIL ADDRESS) that you no longer want to be bound by the Agreement. However, continued use of the Services (or accessing any of the Sites) shows us that you agree to be legally bound by the Agreement (or any new or changed Terms then in place which form part of the Agreement).</li>
                            <li>Scope of Use: For Customers - ZOOMCART.ONLINE is for your non-commercial, personal use only and not for business purposes. You may access the ZOOMCART.ONLINE only through our website and app, standard web and mobile browsers and similar consumer applications, but not with any robot, spider, offline reader, site search/retrieval application or other manual or automatic device, tool, or process to retrieve, index, or data mine our Sites, via scraping, web crawling or otherwise. (As an exception, revocable at any time by us, operators of public search engines may use spiders to copy materials from the Site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials.)</li>
                            <li>Age restrictions: You must be 18 years of age or over, with proper identification to place an order from ZOOMCART.ONLINE platform. Prevention on use: We reserve the right to prevent you from using the ZOOMCART.ONLINE online platform at any time and for any reason</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default Aboutus;


