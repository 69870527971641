import logo from './logo.svg';
import React, { useState, useEffect, useContext } from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'
// import { Provider as ContextProvider } from './Context/Context'
import { GlobalProvider } from './Context/Provider';

import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.bundle"
import Home from './screens/Home/index'
import Product from './screens/Product/Product'
import Productinner from './screens/Product/Productinner'
import Contactus from './screens/Contactus/Contactus';
import Aboutus from './screens/Aboutus/Aboutus';
import NotFound from './screens/Pagenotfound/Notfound';
import Address from './screens/Address/Address';
import ProfileDetails from './screens/Profile/profileDetails'
import ShippingAddress from './screens/ShippingAddress/ShippingAddress';
import Confirmation from './screens/confirmation/confirmation';
import OrderConfirmation from './screens/order confirmation/orderConfirmation';
import MyProfile from './screens/Profile/myProfile'
import Payment from './screens/Payment/Payment'
import Reset from './screens/ResetPassword/Reset'
import TermandCondition from './screens/termandCondition/TermandCondition'
import DeliveryTimeandShippingPolicy from './screens/DeliveryTimeandShippingPolicy/DeliveryTimeandShippingPolicy'
import privacyPolicy from './screens/privacyPolicy/privacyPolicy'
// import Pay from './screens/Payment/Pay'
// mob
import MobRegister from './screens/Home/mob/mobRegister'
import MobLogin from './screens/Home/mob/MobLogin'
import MobForgottenpassword from './screens/Home/mob/mobForgottenpassword'
import './App.css';
// import env from "react-dotenv"

const history = createHistory()
history.listen((location, action) => {
  // console.log('%c current path', 'color:red;backgroud:green', location.pathname)
});

function App() {
  // console.log('proccess env -->', process.env.lkasdj)
  // console.log('proccess env -->',window.env.algolia_id)
  // console.log('proccess env -->', window.env)
  // console.log('proccess env -->',window.env.algolia_id)
  // const { getCart, Logout, state } = useContext(AuthContext)
  let token = ''
  token = localStorage.getItem('tocken') ? localStorage.getItem('tocken') : ''

  useEffect(async() => {

    history.listen((location, action) => {
      // console.log('%c current path', 'color:red;backgroud:green', location.pathname)
      token = localStorage.getItem('tocken') ?  localStorage.getItem('tocken') : ''
      // console.log('token value', token)
    });
  })
  return (
    <div className="App">
      {/* <Router history={history}> */}
      <BrowserRouter history={history}>
        <GlobalProvider>
          {/* <ContextProvider> */}
          <BrowserRouter >
            {token ?
              <Switch>
                <Route path='/' component={Home} exact />
                <Route path='/product' component={Product} exact />
                <Route path='/aboutus' component={Aboutus} exact />
                <Route path='/contactus' component={Contactus} exact />
                <Route path='/product/:name' component={Productinner} exact />
                <Route path='/register' component={MobRegister} exact />
                <Route path='/login' component={MobLogin} exact />
                <Route path="/profile" component={MyProfile} exact />
                <Route path='/profile-details/:id' component={ProfileDetails} />
                <Route path='/reset' component={Reset} exact />
                <Route path='/forgottenpassword' component={MobForgottenpassword} exact />
                <Route path='/addaddress' component={Address} exact />
                <Route path='/shippingaddress' component={ShippingAddress} exact />
                <Route path='/payment' component={Payment} exact />
                <Route path='/confirmation' component={Confirmation} />
                <Route path='/orderconfirmation' component={OrderConfirmation} />
                <Route path='/termsandcondition' component={TermandCondition} />
                <Route path='/DeliveryTimeandShippingPolicy/#delivery' component={DeliveryTimeandShippingPolicy} />
                <Route path='/DeliveryTimeandShippingPolicy/#cancellation' component={DeliveryTimeandShippingPolicy} />
                <Route path='/privacyPolicy' component={privacyPolicy} />
                <Route path='*' component={NotFound} exact />
                <Redirect to="/" exact />
              </Switch>
              :
              <Switch>
                <Route path='/' component={Home} exact />
                <Route path='/product' component={Product} exact />
                <Route path='/aboutus' component={Aboutus} exact />
                <Route path='/contactus' component={Contactus} exact />
                <Route path='/product/:name' component={Productinner} exact />
                <Route path='/register' component={MobRegister} exact />
                <Route path='/login' component={MobLogin} exact />
                <Route path='/reset' component={Reset} exact />
                <Route path='/forgottenpassword' component={MobForgottenpassword} exact />
                <Route path='/termsandcondition' component={TermandCondition} />
                <Route path='/DeliveryTimeandShippingPolicy' component={DeliveryTimeandShippingPolicy} />
                <Route path='/privacyPolicy' component={privacyPolicy} />
                <Route path='*' component={NotFound} exact />
                <Redirect to="/" exact />
              </Switch>
            }
          </BrowserRouter>
          {/* </ContextProvider> */}
        </GlobalProvider>
      </BrowserRouter >

      {/* </Router> */}
    </div>

  );
}

export default App;
