import React, { useEffect, useState, useRef, useContext } from 'react'
import Header from '../../component/header/Header'
import Footer from '../../component/footer/Footer'
import { BASE } from '../../api/api'
import { GlobalContext } from '../../Context/Provider'
import { About_Us } from '../../Context/actions/Information/information'
import './TermandCondition.css'


const TermandCondition = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [window.location.pathname])

    return (
        <div>
            <Header />
            <div className="position-relative  " >
                <img src={process.env.PUBLIC_URL + "/assets/aboutus.jpg"} className="img-fluid about-us-image" />
            </div>
            <div className="bg-grey py-5">
                <h4 className="royal-blue-text  px-5" >
                    <u>Terms and Conditions</u>
                </h4>
                <div className="container py-5">
                    <div>
                        <ul className='list-style-upper-alpha'>
                            <li>Why Register? Depending on the particular functionality of ZOOMCART.ONLINE at any time, you may or may not need to register to use much of its functionality or to access much of our Services. If you do register, we operate an ZOOMCART.ONLINE account in which information is stored about you (called your “Account”) and the act of creating an Account is known as “Registering or “Registration”. However, non-registered customers (guest users) can purchase the product from our website, but they do only have limited access. Registering allows us to: provide you with easy access to information about you, your use of the Services, your past behavior on the ZOOMCART.ONLINE platform and your past purchases; to help you use the Services and to modify your preferences. We reserve the right to decline a new Registration or to cancel an Account at any time. You Register with us so you don’t have to re-enter your information every time you do something (like make a purchase).</li>
                            <li>How to Register: To Register you need to supply us with your name, postcode, email address, and possibly some other personal information. See our Privacy Policy for more details about this. We may require that you provide us with additional details. While you are not required to provide any such additional information, we may not be able to allow you to participate in certain parts of the Services unless you complete and submit all such information. It is your responsibility to provide correct details and we cannot be responsible for the consequences of any incorrect details that you have provided.</li>
                            <li>Passwords: During Registration, you will also need to supply us with (or we will supply you with) a password. You must keep the password confidential and immediately notify us if any unauthorized third party becomes aware of that password or if there is any unauthorized use of your email address or any breach of security known to you. You agree that we are not responsible or liable in the event that a person to whom your password is disclosed uses (and/or transacts via) our Services and platform. Please note that you are entirely responsible if you do not maintain the confidentiality of your password.</li>
                            <li>Valid email addresses: All Accounts must be registered with a valid personal email address that you access regularly. Any Accounts which have been registered with someone else’s email address or with temporary email addresses may be closed without notice. We may require users to re- validate their Accounts if we believe they have been using an invalid email address.</li>
                            <li>Emails: ZOOMCART.ONLINE will send you administrative and promotional emails. We may also send you information regarding your Account activity and purchases, as well as updates about our Site and Services or other promotional offers. We may also send you information regarding purchases you’ve made. (You can opt-out of our promotional emails at any time by clicking the unsubscribe link at the bottom of any of such email correspondence or by updating your email preferences in the “My Account” section of the Sites at any time.)  Use of your email address will always be in accordance with our Privacy Policy.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="bg-grey py-5">
                <h6 className="royal-blue-text  px-5 mx-5" >
                    <u>LIMITATION OF LIABILITY</u>
                </h6>
                <div className="container py-5">
                    {/* <p>
                    In no event shall ZOOMCART.ONLINE (each of our Respective Officers, Directors, Employees, Agents, Shareholders, Retail Partners, Licensors, and Logistics) be liable to you for any incidental, special , punitive, consequential, or indirect damages ( including, but not limited to, damages for deletion, corruption, loss of data, loss of programs, failure to store any information or other content maintained or transmitted by the services, service interruptions, or for the cost of procurement of substitute services). Arising out of us in connection with the services, or these terms, however arising including negligence, even if we or our agents or representatives know or have been advised of the possibility of such damages.
                    </p>
                    <p>
                    We will not be liable for any indirect, special, punitive, incidental, exemplary, and or consequential damages (including, but not limited to physical damages, bodily, injury, death, and or emotional distress and discomfort) Arising out of your use of the services, any services provided by personal shoppers or third party providers, or any products requested by you or delivered to you, even if we or our agents or representatives know or have been advised of the possibility of such damages. This provision applies to the maximum extent permitted by applicable.
                    </p> */}

                    <div>
                        <ul className='list-style-upper-alpha'>
                            <li>In no event shall ZOOMCART.ONLINE (each of our Respective Officers, Directors, Employees, Agents, Shareholders, Retail Partners, Licensors, and Logistics) be liable to you for any incidental, special , punitive, consequential, or indirect damages ( including, but not limited to, damages for deletion, corruption, loss of data, loss of programs, failure to store any information or other content maintained or transmitted by the services, service interruptions, or for the cost of procurement of substitute services). Arising out of us in connection with the services, or these terms, however arising including negligence, even if we or our agents or representatives know or have been advised of the possibility of such damages.</li>
                            <li>We will not be liable for any indirect, special, punitive, incidental, exemplary, and or consequential damages (including, but not limited to physical damages, bodily, injury, death, and or emotional distress and discomfort) Arising out of your use of the services, any services provided by personal shoppers or third party providers, or any products requested by you or delivered to you, even if we or our agents or representatives know or have been advised of the possibility of such damages. This provision applies to the maximum extent permitted by applicable.</li>
                        </ul>
                    </div>
                </div>
            </div>
            
            
            <div className="bg-grey py-5">
                <h6 className="royal-blue-text  px-5 mx-5" >
                    <u>RULES OF ZOOMCART.ONLINE USAGE</u>
                </h6>
                <div className="container py-5">
                    {/* <p>
                    In no event shall ZOOMCART.ONLINE (each of our Respective Officers, Directors, Employees, Agents, Shareholders, Retail Partners, Licensors, and Logistics) be liable to you for any incidental, special , punitive, consequential, or indirect damages ( including, but not limited to, damages for deletion, corruption, loss of data, loss of programs, failure to store any information or other content maintained or transmitted by the services, service interruptions, or for the cost of procurement of substitute services). Arising out of us in connection with the services, or these terms, however arising including negligence, even if we or our agents or representatives know or have been advised of the possibility of such damages.
                    </p>
                    <p>
                    We will not be liable for any indirect, special, punitive, incidental, exemplary, and or consequential damages (including, but not limited to physical damages, bodily, injury, death, and or emotional distress and discomfort) Arising out of your use of the services, any services provided by personal shoppers or third party providers, or any products requested by you or delivered to you, even if we or our agents or representatives know or have been advised of the possibility of such damages. This provision applies to the maximum extent permitted by applicable.
                    </p> */}

                    <div>
                        <ul className='list-style-upper-alpha'>
                            <li>Errors and omissions: We are not liable for errors or omissions if we have complied with the standards set out in Clause 3. We will use reasonable endeavors to correct any errors or omissions as soon as practicable after being notified of them. We reserve the right to change, modify, substitute, suspend or remove without notice any Services, information or listings of Goods on the ZOOMCART.ONLINE platform or forming part of the Services from time to time.</li>
                            <li>Stop/Block Access:  ZOOMCART.ONLINE reserves the right to block access to and/or to edit or remove any material which in our reasonable opinion may give rise to a breach of any of the Agreement or otherwise. We reserve the right to close your Account if you are violating the Agreement in any way (including if you are seen to be using proxy IPs – Internet Protocol addresses – to attempt to hide the use of multiple Accounts, or if you pretend to be located in a country different from where you actually reside, or if you disrupt the Sites or the Services in any way).</li>
                            <li>Multiple Accounts: You may not open multiple Accounts, whether to circumvent restrictions or otherwise. In any event, if you do use multiple Accounts, if we do take any action against you, you may have action taken against all of your Accounts.</li>
                            <li>Technology: We assume no responsibility for functionality which is dependent on your browser or other third party software to operate.  We do not guarantee that the Sites or Services will support all browsers or mobiles or operating systems or that the Sites or Services will continue to support browsers or mobiles or operating systems currently supported.</li>
                            <li>Withdrawal: We may always, at any time, withdraw any information or any aspect of any of the Services at any time.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="bg-grey py-5">
                <h6 className="royal-blue-text  px-5 mx-5" >
                    <u>SUSPENSION AND TERMINATION</u>
                </h6>
                <div className="container py-5">
                    {/* <p>
                    In no event shall ZOOMCART.ONLINE (each of our Respective Officers, Directors, Employees, Agents, Shareholders, Retail Partners, Licensors, and Logistics) be liable to you for any incidental, special , punitive, consequential, or indirect damages ( including, but not limited to, damages for deletion, corruption, loss of data, loss of programs, failure to store any information or other content maintained or transmitted by the services, service interruptions, or for the cost of procurement of substitute services). Arising out of us in connection with the services, or these terms, however arising including negligence, even if we or our agents or representatives know or have been advised of the possibility of such damages.
                    </p>
                    <p>
                    We will not be liable for any indirect, special, punitive, incidental, exemplary, and or consequential damages (including, but not limited to physical damages, bodily, injury, death, and or emotional distress and discomfort) Arising out of your use of the services, any services provided by personal shoppers or third party providers, or any products requested by you or delivered to you, even if we or our agents or representatives know or have been advised of the possibility of such damages. This provision applies to the maximum extent permitted by applicable.
                    </p> */}

                    <div>
                        <ul className='list-style-upper-alpha'>
                            <li>Suspension: If you use (or anyone other than you, with your permission uses) the ZOOMCART.ONLINE in contravention of the Agreement, we may suspend your use of the Services and/or Site (in whole or in part). If we do this, we may refuse to restore the ZOOMCART.ONLINE until we receive an assurance from you, in a form we deem acceptable, that there will be no further breach of the provisions of the Agreement, or indeed we may refuse to restore the Services or Sites at all.</li>
                            <li>Law Enforcement: We shall fully co-operate with any law enforcement authorities or court order requesting or directing us to disclose the identity or locate anyone in breach of the Agreement.</li>
                            <li>Preservation of rights: Our right to terminate the Agreement shall not affect any other right or remedy we may have in respect of any breach or any rights, obligations or liabilities which have come about before termination.</li>
                         </ul>
                    </div>
                </div>
            </div>
            
            <div className="bg-grey py-5">
                <h6 className="royal-blue-text  px-5 mx-5" >
                    <u>PAYMENT</u>
                </h6>
                <div className="container py-5">
                    <p>
                    We reserve the right to charge you convenience Fee while you enjoy our platform.  ZOOMCART.ONLINE accept only Card/Cash on Delivery.
                    </p>
                   
                </div>
            </div>
           
            <div className="bg-grey py-5">
                <h6 className="royal-blue-text  px-5 mx-5" >
                    <u>DATA PROTECTION</u>
                </h6>
                <div className="container py-5">
                    <p>
                    Please see our Privacy Policy of the Agreement and which explain what information we collect from you and how we use it and what we do with it
                    </p>
                   
                </div>
            </div>
           
            <div className="bg-grey py-5">
                <h6 className="royal-blue-text  px-5 mx-5" >
                    <u>INDEMNIFICATION</u>
                </h6>
                <div className="container py-5">
                    <p>
                    You agree to defend, indemnify and hold harmless ZOOMCART.ONLINE and its officers, directors, employees and agents from and against any losses, claims, actions, costs, damages, penalties, fines and expenses, including without limitation attorneys' and experts’ fees and expenses, that may be incurred by an Indemnified Party arising out of, relating to or resulting from your unauthorized use of the Services or from any breach by you of these Terms, including without limitation any actual or alleged violation of any law, rule or regulation.
                    </p>
                   
                </div>
            </div>
            
            <div className="bg-grey py-5">
                <h6 className="royal-blue-text  px-5 mx-5" >
                    <u>QUESTIONS AND COMPLAINTS</u>
                </h6>
                <div className="container py-5">
                    <p>
                    If you have any questions or complaints, please contact ZOOMCART.ONLINE using the "Contact Us" page on the Platform. ZOOMCART.ONLINE will liaise with Merchants (Store) on your questions and complaints.
                    </p>
                   
                </div>
            </div>
            
           

            <Footer />
        </div>
    )
}


export default TermandCondition;



