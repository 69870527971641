import {
  loading_freezz,
  user_details,
  cart_loading
  // cart_status
} from "../../../actionTypes";
import Axios from "axios";
import { BASE } from "../../../api/api";


export const loading_function_active = dispatch => async () => {
  dispatch({ type: loading_freezz, payload: true })

}
export const loading_function_end = dispatch => async () => {
  dispatch({ type: loading_freezz, payload: false })
  // dispatch({ type: cart_status, payload: true })

}

  export const user_detail = () => dispatch => new Promise((resolve, reject) => {
  dispatch({ type: cart_loading, payload: true })
  var data = {
    "email": localStorage.getItem('usermail')
  }
  var user_tocken = localStorage.getItem('tocken')
  var config = {
    method: 'post',
    url: BASE + `/api/method/algolia_sync.zoomcart_api.customer_fetch`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      dispatch({ type: user_details, payload: response.data.message[0] })
      dispatch({ type: cart_loading, payload: false })
      resolve(response.data.message[0].name)
    })
    .catch(function (error) {
      dispatch({ type: cart_loading, payload: false })
      console.error("user detail fetch api ", error);
      reject(error)

    });
})