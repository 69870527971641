import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./RecommSlicker.css"
import { BASE } from '../../api/api'
import { Link, useHistory } from 'react-router-dom'

import {
    SearchBox,
    // Hits,
    InstantSearch,
    connectHits,
    connectRefinementList,
    Configure,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
    'NAH884LD6O',
    '155ad86e58cb50ae7eefab00df3bc15d'
);

const RecommSlicker = () => {
    let imgid = 0
    var settings = {
        infinite: false,
        // speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1.5,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2.65,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3.7,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows: false,
                }
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    }
        ;
    const Hits = ({ hits }) => (

        <Slider {...settings}>

            {hits.map(hit => {
                if (imgid > hits.length - 1) { imgid = 0 }
                imgid = imgid + 1;
                return (

                    <Link to={{
                        pathname: `/product/${hit.item_code}`,
                        state: hit
                    }} className="">
                        <div className="row">
                            <div className='position-relative slicker'>
                                <div className="card  text-white d-flex RecommedationSlicker RecommedationSlicker-overlay" >
                                    <img className=" card-img RecommedationSlicker" src={hit['Image URL'] ? hit['Image URL'][0] != null ? BASE + hit['Image URL'][0] : "../assets/square.jpeg" : "../assets/square.jpeg"} alt="slicker one" />
                                    <div className="card-img-overlay align-items-end d-flex justify-content-center nopadding recommendationbg">
                                        <p className='slickerpart text-center text-white ' >
                                            {hit.item}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            }
            )}
        </Slider>
    );
    const CustomHits = connectHits(Hits);
    return (
        <div className="desk-mpl5"
        >
            <InstantSearch
                indexName="zoomcartlive" searchClient={searchClient} >
                <Configure hitsPerPage={5} />
                <CustomHits />
            </InstantSearch>
        </div>
    )
}
export default RecommSlicker;


