export default {
  address: {
    loading: false,
    data: null,
    error: null,
    status:'',
    getAddress:'',
    deladdress:'',
    address:'this'
  },
};
