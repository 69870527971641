import {
  logedin,
  register,
  token,
  user_loading,
  forgottenpassword,
  confirmpassword
} from '../../actionTypes';
import { Login } from '../actions/auth/login';

const auth = (state, { payload, type }) => {
  switch (type) {
    // case USER_LOADING:
    case user_loading:
      return {
        ...state,
        auth: {
          ...state.auth,
          error: false,
          // loading: true,
          loading: payload,

          // isAuthenticated: true,
        },
      };
    case register:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: false,
          user_loading: false,
          // data: payload,
          isAuthenticated: true,
          user_login: false
        },
      };

    case logedin:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: false,
          user_loading: false,
          user_login: true,
          isAuthenticated: true,
        },
      };
    case confirmpassword:
      return {
        ...state,
        auth: {
          ...state.auth,
          confirmpassword: payload,
        },
      };
    case forgottenpassword:
      return {
        ...state,
        auth: {
          ...state.auth,
          forgottenpassword: payload
        },
      };
    case token:
      return {
        ...state,
        auth: {
          ...state.auth,
          token: payload,
        },
      };
    default:
      return state;
  }
};

export default auth;
