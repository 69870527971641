import React from 'react'
import { Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay';
import './loading.css'

const Loading = () => {
    const path = window.location.pathname

    return (

        <div className="position-fixed loading-container d-flex align-self-center justify-content-center w-100">

            <LoadingOverlay
                    active={true}
                    spinner
                    // className='f'
                //    text='Please wait...'
                ></LoadingOverlay>
        </div>
    )
}
export default Loading;