import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { GlobalContext } from '../../Context/Provider';
import { cartUpdate } from '../../util/cartupdate';
import { oder_confirmation } from '../../util/order_Confirmation';
import { findCart_Detail } from '../../util/findCartdetail';
import { useAlert } from "react-alert";
import { cartupdate,getCart } from '../../Context/actions/cart/cart';
import { BASE } from '../../api/api';
const DesktopCart = (props) => {
    const alert = useAlert();
    let count = 0;
    const { show, toggleShow } = props
    const [loading, setloading] = useState(false)
    const [cartbox, cartboxopen] = useState(props.cartbox)
    const [cartItems, setcartItems] = useState('')
    const [statuscard, setstatuscard] = useState(true)
    const {
        
        cartDispatch,
        cartState: {
            cart: { cart_status, cart_loading, cartname, getcart, get_data },
        }
    } = useContext(GlobalContext);
    useEffect(() => {
        setcartItems(localStorage.getItem('cartitems'))
        cartItems ? cartboxopen(true) : cartboxopen(false);
    }, [show, toggleShow])
    useEffect(() => {
        if (statuscard == false) { setstatuscard(true) }
        setloading(cart_loading)
        getCart()(cartDispatch)
    }, [])
    useEffect(() => {
        setloading(cart_loading)
        if (JSON.parse(localStorage.getItem('cartupdates')) == 1) { getCart()(cartDispatch) }
        if (JSON.parse(localStorage.getItem('cartupdates')) == 1) { localStorage.setItem('cartupdates', 0) }
        // console.log('cart itemse availble -->', state.cart_status)
        // let a = JSON.parse(localStorage.getItem('payment_detail'))
        // console.log('cart value are aa--aa', a)
        // if (state.cart_status == false) {
        //     if (a) {
        //         if (a[0].cart_item) {
        //             oder_confirmation(a[0].order_id).then(e => e == 'ERROR' ? (console.log('need to add to cart', a[0].order_id),
        //                 findCart_Detail(a[0].cart_item)
        //                     .then(async (e) => {
        //                         console.log('result is ', e)
        //                         loading_function_active()
        //                         await e.map(x => cartupdate(x.item_code, x.qty))
        //                         localStorage.removeItem('payment_detail')
        //                         loading_function_end()

        //                     })
        //                     .catch(e => console.log('error in finddetailof cart', e))
        //             ) : console.log('no need to add cart', a[0].cart_item))
        //         }
        //     }
        // }
        // else { setcartItems(localStorage.getItem('cartitems')) }
        get_data ? get_data.items == '' || get_data.items == undefined || get_data.items == [] ? cartboxopen(false) : cartboxopen(true) : cartboxopen(false)
    }, [show, toggleShow])

    return (
        <Modal
            className="cart-modal"
            show={show}
            onHide={toggleShow}
            centered
            size="xl"
        >
            <Modal.Body className="p-0">
                {cartbox ?

                    <div className="">

                        <div className="col-12 overflow-auto" style={{ maxHeight: "480px" }}>
                            {
                                cartbox ?

                                    get_data.items.map((x) => {
                                        return (
                                            <div className="row py-4  border-black border-bottom-1" style={{ borderTop: ".3px solid #999999" }}>
                                                <div className="col-6 d-flex">
                                                    {/* <img src={} className="cart-img img-fluid" /> */}
                                                    {JSON.parse(localStorage.getItem('cartpic')) ?
                                                        (count = 0,
                                                            JSON.parse(localStorage.getItem('cartpic')).map(
                                                                (s) => {
                                                                    // console.log('product images -->',s)
                                                                    return (
                                                                        s.id == x.item_name
                                                                            ?
                                                                            (
                                                                                < img src={s.img ? `${BASE}${s.img}` : process.env.PUBLIC_URL + "/assets/square.jpeg"} className="cart-img img-fluid" />)
                                                                            :
                                                                            (
                                                                                count = count + 1,
                                                                                JSON.parse(localStorage.getItem('cartpic')).length == count
                                                                                    ?
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/square.jpeg"} className="cart-img img-fluid" />
                                                                                    : ""
                                                                            )
                                                                    )

                                                                    // <img src={process.env.PUBLIC_URL + "/assets/square.jpeg"} className="cart-img img-fluid" />
                                                                }
                                                            )
                                                        )
                                                        :
                                                        <img src={process.env.PUBLIC_URL + "/assets/square.jpeg"} className="cart-img img-fluid" />

                                                    }
                                                    <div className="mx-3">
                                                        <p className=" l20" style={{ color: '#333333' }}>{x.item_name}</p>
                                                        <p className=" price-text" >BHD {x.net_rate}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 justify-content-end d-flex">
                                                    <div className="d-flex d-inline-flex justify-content-end " >
                                                        <button className=" bg-royalblue btn-border carts-icon px-1 mx-md-2 mx-sm-1"
                                                            onClick={() => cartUpdate({ item_code: x.item_code, items: get_data.items, op: 'minus' })
                                                                .then(e => cartupdate({items_array:e,qtn:cartname})(cartDispatch).then(console.log('cartupdate successfull')))
                                                                .catch(e => e == 'MINUS_OPERATION_FAILED' ? alert.show('QTY IS ONLY 1') : "")
                                                            }>
                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                width="16" height="16"
                                                                viewBox="0 0 172 172"
                                                                style={{ fill: "#000000" }}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M21.5,78.83333v14.33333h129v-14.33333z"></path></g></g></svg>
                                                        </button>
                                                        <label className="cart-qty">

                                                            {x.qty}
                                                        </label>
                                                        <button className=" bg-royalblue btn-border carts-icon px-1 mx-md-2 mx-sm-1" onClick={() => cartUpdate({ item_code: x.item_code, items: get_data.items, op: 'add' })
                                                            .then(e => cartupdate({items_array:e,qtn:cartname})(cartDispatch).then(console.log('cartupdate successfull')))

                                                        }>

                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                width="16" height="16"
                                                                viewBox="0 0 226 226"
                                                                style={{ fill: "#000000" }}><g fill="none" fill-rule="none" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}><path d="M0,226v-226h226v226z" fill="none" fill-rule="nonzero"></path><g fill="#ffffff" fill-rule="evenodd"><path d="M103.58333,18.83333v84.75h-84.75v18.83333h84.75v84.75h18.83333v-84.75h84.75v-18.83333h-84.75v-84.75z"></path></g></g></svg>
                                                        </button>

                                                        <label className="price-text">BHD {x.base_amount}</label>
                                                        <button className="bg-royalblue btn-border carts-icon text-center px-1 mx-md-2 mx-sm-1 align-items-center " onClick={() => cartUpdate({ item_code: x.item_code, items: get_data.items, op: 'del' }).then(e => cartupdate({items_array:e,qtn:cartname})(cartDispatch).then(console.log('cartupdate successfull')))}>
                                                            <img src={process.env.PUBLIC_URL + "/assets/bin.png"} style={{ height: "20px", width: "20px" }} className="cart-icons justify-content-center align-self-center"></img>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                    :
                                    ""
                            }
                        </div>

                        <div className="position-relative " style={{ bottom: "-1px", borderTop: ".3px solid #999999" }}>
                            <div className="row pt-4">
                                <div className="d-flex  mx-3">

                                    <div className="col-7  d-flex ">
                                    </div>
                                    <div className="col-5 d-flex justify-content-end align-items-center">
                                        <p className="l20 mb-0">Subtotal</p>
                                        <p className="d-block price-text mx-lg-5 mx-md-2 mx-sm-2 mb-0" >BHD {get_data.total}</p>
                                    </div>
                                </div>
                                <Link to="/shippingaddress" className="col-12 d-flex">
                                    <button className="my-3 border-0 w-100 checkout-btn btn-block bg-black mx-3"
                                        style={{ fontWeight: 600, textAlign: "center", fontSize: "24px", height: "68px", color: "white" }}>CheckOut</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    :
                    <div className="d-flex justify-content-center" style={{ minHeight: "460px" }}>
                        <div className="align-self-center text-center">
                            <img src={process.env.PUBLIC_URL + "/assets/emptycart.png"} className="img-fluid" />
                            <h5>Your cart is currently empty?</h5>
                            <p>Before proceed to checkout, you must add some products to your cart.You will find a lot intresting products on our  Product Page</p>
                            <Link to='/product' className="text-decoration-none"><button className=" btn-block bg-royalblue bth  btn-royalblue text-white h5 mouse-pointer" >Go to Product page</button></Link>
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}
export default DesktopCart;