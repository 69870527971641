export const cartUpdate = ({ item_code, items, op }) => new Promise((resolve, reject) => {
    let Item_Array = items.map(
        ({ item_code, qty }) => ({
            item_code: item_code,
            qty: qty
        }),
    );
    let objIndex = Item_Array.findIndex((obj => obj.item_code == item_code));
    let objvalue = Item_Array.find((obj => obj.item_code == item_code));
    if (op == "add" || op == "minus") {
        console.log('items qty is changedd', items[objIndex])
        if (op == "add") {
            if (items[objIndex] == undefined) {
                console.log('undei')
                Item_Array = [...Item_Array, { item_code: item_code, qty: 1 }]
                resolve(Item_Array)
            } else {

                console.log('Item_Array[objIndex] -->', Item_Array[objIndex])
                Item_Array[objIndex].qty = items[objIndex].qty + 1
                resolve(Item_Array)
            }

        } else if (op == "minus") {
            if (Item_Array[objIndex].qty == 1) {
                reject('MINUS_OPERATION_FAILED')
            } else {
                Item_Array[objIndex].qty = items[objIndex].qty - 1
                resolve(Item_Array)
            }
        }


    }
    else if (op == "del") {
        let array_filter = Item_Array.filter(x => x.item_code != item_code)
        console.log('Item_Array deletion is -->', array_filter)
        resolve(array_filter)
    }
})