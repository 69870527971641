import React, { useEffect, useState, useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import '../../screens/Home/home.css'

const BasicButtonSimple = ({ name, function_call, submitbtn }) => {

    useEffect(() => {
        // console.log('use effect homepage working')
    }, [])

    return (
        <div className="">
            <button type="button" className="btn-royalblue bg-royalblue" disabled={submitbtn} onClick={() => { function_call() }} style={{ height: "30px", width: "100%" }} >
                {name}
            </button>
        </div>
    )
}
export default BasicButtonSimple;


