import React, { useContext, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './profiledetails.css'
import PassWord from '../../component/profile/password';

const ProfileEdit = () => {

    React.useEffect(() => {
    }, []);

   

    return (
        <div id="profile-edit" className="profile-edit">
           
            <div className="profile-edit-contianer mob">
                <Accordion preExpanded="[0]">
                    <AccordionItem uuid="0">
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Password Change
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="body_links">
                                <PassWord />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
}

export default ProfileEdit;
