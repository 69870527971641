import React, { useEffect, useState, useContext } from 'react'
import validator from 'validator'
import { useHistory } from 'react-router-dom'
import Loading from '../../component/loading/Loading'
import { validationform } from '../../util/validation'
import { GlobalContext } from '../../Context/Provider'
import { Add_Address, UpdateAddress, GetAddress } from '../../Context/actions/address/address'
const AddAddress = (props) => {
    let history = useHistory();
    const [loading, setloading] = useState(false)
    const [disabled_button_option, setdisabled_button_option] = useState(false)
    const [name, setname] = useState('')
    const [AddressLine1, setAddressLine1] = useState('')
    const [AddressLine2, setAddressLine2] = useState('')
    const [postalcode, setpostalcode] = useState('')
    const [city, setcity] = useState('')
    const [state, setstate] = useState('')
    const [country, setcountry] = useState('')
    const [phone, setphone] = useState('')
    const [validName, setvalidName] = useState(true)
    const [validAddressLine1, setvalidAddressLine1] = useState(true)
    const [validAddressLine2, setvalidAddressLine2] = useState(true)
    const [validPostalCode, setvalidPostalCode] = useState(true)
    const [validCity, setvalidCity] = useState(true)
    const [validState, setvalidState] = useState(true)
    const [validCountry, setvalidCountry] = useState(true)
    const [validPhone, setvalidPhone] = useState(true)
    const [submitbtn, setsubmitbtn] = useState(false)


    const {
        addressDispatch,
        addressState: {
            address: { address, deladdress },
        },
        // cartDispatch: {
        //     cart: { get_data },
        // },
    } = useContext(GlobalContext);


    useEffect(() => {
        if (validName == true && validAddressLine1 == true && validAddressLine2 == true && validPostalCode == true && validCity == true && validState == true && validCountry == true && validPhone == true) {
            setdisabled_button_option(false)
        } else {
            setdisabled_button_option(true)
        }
    })
    useEffect(() => {
        if (props.address) {
            setname(props.address.address_title);
            setAddressLine1(props.address.address_line1);
            setAddressLine2(props.address.address_line2);
            setpostalcode(props.address.pincode);
            setcity(props.address.city);
            setphone(props.address.phone);
            setstate(props.address.state);
            setcountry(props.address.country);
        }
    }, [])

    const AddressSubmit = (e) => {
        let a = document.getElementsByTagName('input')
        console.log('great indian festival -->',a)
        if (validName == true && validAddressLine1 == true && validAddressLine2 == true && validPostalCode == true && validCity == true && validState == true && validCountry == true && validPhone == true) {
            setdisabled_button_option(false)
            if (props.method == "update") {



                UpdateAddress(name, AddressLine1, AddressLine2, city, state, postalcode, phone, props.address.name)(addressDispatch)
                    .then(() => {
                        GetAddress()(addressDispatch)
                    })
            }
            else {
                Add_Address(name, AddressLine1, AddressLine2, city, state, postalcode, phone)(addressDispatch)
                    .then(() => {
                        GetAddress()(addressDispatch)
                    })
            }
            props.location == '/shippingaddress' ? history.push("/shippingaddress") : history.push("/profile-details/shippinglist")
        } else {
            setdisabled_button_option(true)
        }

    }


    return (

        <div className="Address-Part  h-100 w-100">
            {loading ? <Loading /> : ""}

            {/* <form className="" onSubmit={(e) => { e.preventDefault(); AddressSubmit() }}> */}
            <div className="container py-5 d-flex">
                <div id='address-form' className="row justify-content-center align-self-center align-item-center">
                    <div className='col-12   mb-2 col-sm-6 '>
                        <input value={name} onChange={e => { setname(e.target.value) }} name='fullname' className='col-12 addressinput profile-input' placeholder='Enter Your Name*'
                            onBlur={e => { setname(e.target.value); validationform(e.target) ? setvalidName(true) : setvalidName(false) }}
                        />
                        {validName ?
                            ' '
                            :
                            <label className='valid-error'>Enter valid Name</label>
                        }

                    </div>
                    <div className='col-12   mb-2  col-sm-6'>
                        <input value={phone} onChange={e => { setphone(e.target.value) }} name='phone' className='col-12 addressinput profile-input' placeholder='Phone*'
                            onBlur={e => { setphone(e.target.value); validationform(e.target) ? setvalidPhone(true) : setvalidPhone(false) }}
                        />
                        {validPhone ?
                            ' '
                            :
                            <label className='valid-error'>Enter valid Phone number</label>
                        }
                    </div>
                    <div className='col-12   mb-2 col-sm-6 '>
                        <input value={AddressLine1} onChange={e => { setAddressLine1(e.target.value) }} name='address' className='col-12 addressinput profile-input' placeholder='Address Line1*'
                            //  onBlur={(e) => validationform(e.target)}
                            onBlur={e => { setAddressLine1(e.target.value); validationform(e.target) ? setvalidAddressLine1(true) : setvalidAddressLine1(false) }}
                        />
                        {validAddressLine1 ?
                            ' '
                            :
                            <label className='valid-error'>Enter valid Address</label>
                        }


                    </div>
                    <div className='col-12    mb-2 col-sm-6 '>
                        <input value={AddressLine2} onChange={e => { setAddressLine2(e.target.value) }} name='address' className='col-12 addressinput profile-input' placeholder='Address Line2*'
                            //  onBlur={(e) => validationform(e.target)}
                            onBlur={e => { setAddressLine2(e.target.value); validationform(e.target) ? setvalidAddressLine2(true) : setvalidAddressLine2(false) }}
                        />
                        {validAddressLine2 ?
                            ' '
                            :
                            <label className='valid-error'>Enter valid Address</label>
                        }


                    </div>

                    <div className='col-12   mb-2  col-sm-6'>
                        <input value={postalcode} onChange={e => { setpostalcode(e.target.value) }} name='postalcode' className='col-12 addressinput profile-input' placeholder='Postal code*'
                            // onBlur={(e) => validationform(e.target)}
                            onBlur={e => { setpostalcode(e.target.value); validationform(e.target) ? setvalidPostalCode(true) : setvalidPostalCode(false) }}
                        />
                        {validPostalCode ?
                            ' '
                            :
                            <label className='valid-error'>Enter valid Postalcode</label>
                        }
                    </div>
                    <div className='col-12   mb-2  col-sm-6'>
                        <input value={city} onChange={e => { setcity(e.target.value) }} name='city' className='col-12 addressinput profile-input' placeholder='City/Town*'
                            // onBlur={(e) => validationform(e.target)}
                            onBlur={e => { setcity(e.target.value); validationform(e.target) ? setvalidCity(true) : setvalidCity(false) }}
                        />
                        {validCity ?
                            ' '
                            :
                            <label className='valid-error'>Enter valid Name</label>
                        }
                    </div>
                    <div className='col-12   mb-2  col-sm-6'>
                        <input value={state} onChange={e => { setstate(e.target.value) }} name='city' className='col-12 addressinput profile-input' placeholder='State*'
                            // onBlur={(e) => validationform(e.target)}
                            onBlur={e => { setstate(e.target.value); validationform(e.target) ? setvalidState(true) : setvalidState(false) }}
                        />
                        {validState ?
                            ' '
                            :
                            <label className='valid-error'>Enter valid Name</label>
                        }
                    </div>
                    <div className='col-12   mb-2  col-sm-6'>
                        <input value={country} onChange={e => { setcountry(e.target.value) }} name='city' className='col-12 addressinput profile-input' placeholder='Country*'
                            // onBlur={(e) => validationform(e.target)}
                            onBlur={e => { setcountry(e.target.value); validationform(e.target) ? setvalidCountry(true) : setvalidCountry(false) }}
                        />
                        {validCountry ?
                            ' '
                            :
                            <label className='valid-error'>Enter valid Name</label>
                        }
                    </div>
                    {/* <button className={submitbtn ? " bth  btn-royalblue py-2 my-5 " : "bg-royalblue bth  btn-royalblue py-2 my-5 "} disabled={submitbtn} onClick={()=>AddressSubmit()} ><div className='h8 text-align-center'>Add Address</div></button> */}
                    <button className={submitbtn ? " bth  btn-royalblue py-2 my-5 " : "bg-royalblue bth  btn-royalblue py-2 my-5 "} disabled={disabled_button_option} onClick={() => AddressSubmit()} ><div className='h8 text-align-center'>{props.method ? "Update Address" : "Add Address"}</div></button>
                </div>
            </div>

            {/* </form> */}
        </div>
    )
}
export default AddAddress;


