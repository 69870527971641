import React, { useState, useEffect, useContext } from 'react'
import Popup from 'reactjs-popup'
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import validator from 'validator'
import { useAlert } from "react-alert";
import { Redirect, useHistory } from 'react-router-dom'
import BasicButtonSimple from '../atom/BasicButtonSimple';
import { GlobalContext } from '../../Context/Provider';
import { Login } from '../../Context/actions/auth/login';
const DesktopLogin = (props) => {
    const history = useHistory()
    const alert = new useAlert();
    const { login, toggleLoginShow, toggleforgottenShow, toggleaccShow } = props
    const {
        authDispatch,
        authState: {
            auth: { user_loading, loading, error, data, isAuthenticated },
        },
    } = useContext(GlobalContext);

    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [fullname, setfullname] = useState('')
    const [submitbtn, setsubmitbtn] = useState(true)

    const [searchbarHide, setsearchbarHide] = useState(false)

    // validation
    const [validEmail, setvalidEmail] = useState('a')
    const [passwordvalid, setpasswordvalid] = useState('a')
    // const { Login } = useContext(AuthContext)


    const Login_call = () => {

        Login(email, password)(authDispatch)
            .then(() => {
                alert.show("Login successfully");
                localStorage.removeItem('loggednotify');
                toggleLoginShow()
            })
            .catch(() => {
                alert.error("Invalid Email or Password");
                localStorage.removeItem('loggednotify');

            })


    }


    const validationform = (e) => {

        if (e.name == 'email') {
            if (validator.isEmail(email)) {
                setvalidEmail('')
                // setopen(emailopen => !emailopen)
            } else {
                setvalidEmail('emailvalid')
                setsubmitbtn(true)

            }
        }
        else
            if (e.name == 'password') {
                if (password.length > 6) {
                    setpasswordvalid('')
                } else {
                    setpasswordvalid('passwordvalid')
                    setsubmitbtn(true)

                }
            }
    }

    return (

        <Modal
            className="cart-modal"
            show={login}
            onHide={toggleLoginShow}
            centered

        >
            <Modal.Body >
                <div className="bg-danger position-relative " style={{ zIndex: 1 }}>

                    <div className='cart-box bg-white border-black border container align-content-center' style={{ minHeight: "399px", width: "471px", }} >
                        <div className="position-relative ">
                            <div className="login container ">
                                <form className="pt-5" onSubmit={(e) => e.preventDefault()}>
                                    <div className="inp-fields">
                                        <input className="profile-input d-block" tabindex="-1" name="email" onBlur={e => e.target.value.length == 0 ? "" : (setemail(e.target.value), validationform(e.target))} onChange={(e) => { setemail(e.target.value) }} placeholder="E-mail*" />
                                        {validEmail == 'emailvalid' ?
                                            <label className='valid-error'>Enter valid email address </label>
                                            : ' '}
                                        <input className="profile-input d-block" name="password" tabindex="-1" onBlur={e => { setpassword(e.target.value); validationform(e.target) }} onChange={(e) => { setpassword(e.target.value) }} type="password" placeholder="Password*" />
                                        {passwordvalid == 'passwordvalid' ?

                                            <label className='valid-error'>Password at least 8 Characters , Contains At Least A Number, Special Character And A Mixture Of Uppercase And Lowercase Letters.</label>
                                            : ' '}
                                    </div>
                                    {/* <input type="submit" className="btn-royalblue bg-royalblue" value="Login" onClick={() => { Login_call(); }} style={{ height: "30px", width: "100%" }} /> */}
                                    <BasicButtonSimple name="Login" submitbtn={false} function_call={() => Login_call()} />
                                </form>
                                <div className="my-3" style={{ textAlign: "center" }}>

                                    <p className="login-grey mouse-pointer frgt-pwd" onClick={() => { props.toggleforgottenShow(); props.toggleLoginShow() }}>Forgot Password</p>
                                    <p className="login-grey dnt-accnt">Don’t have an account yet?</p>
                                    <p className="change-mode mouse-pointer desk-only" onClick={() => { props.toggleaccShow(); props.toggleLoginShow() }}  >Register Now</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>



    )
}
export default DesktopLogin;