import React, { useContext, useEffect, useState } from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import Header from '../../component/header/Header'
import Footer from '../../component/footer/Footer'
import AddressListItem from '../../component/profile/addressListItem';
import { GetAddress } from '../../Context/actions/address/address';
import { Link, useHistory } from 'react-router-dom';
import { GlobalContext } from '../../Context/Provider';
import { cart_switch_address } from '../../Context/actions/cart/cart';
const ShippingAddress = () => {
    let history = useHistory();
    // const { state, GetAddress, SwitchAddress, Confirmation } = useContext(Address)
    const [index, selectedIndex] = useState(0)
    const [deletebtn, selecteddeletebtn] = useState(true)

    const {
        addressDispatch,
        addressState: {
            address: { address, deladdress },
        },
        cartDispatch,
        cartState: {
            cart: { cart_status, cart_loading, cartname, getcart, get_data },
        }
    } = useContext(GlobalContext);



    useEffect(() => {
        GetAddress()(addressDispatch)
    }, [window.location.pathname, deladdress, deletebtn, selecteddeletebtn]);

    useEffect(() => {
        console.log('address-->', address)
    }, [window.location.pathname, deladdress]);

    return (
        <div>
            <Header />
            <div id="profile-edit" className="profile-edit">
                <div className="shipping-list-container container">
                    <h3 className="h3-bold">Shipping_Address</h3>
                    <h6 className="text-start  my-3">Select Delivery Address</h6>
                    <div className=" pad-0 mob">
                        {address != 'this' ? address.message[0].map((item, ind, shipping_address_name) => {
                            // console.log('ind value is ', ind, 'selected Index value is ', item, 'shipping_address_name [--->',address.message[1])

                            // return get_data != undefined ?
                            return item.name == get_data.shipping_address_name ?

                                // item.name == get_data.shipping_address_name ?
                                item.name == get_data.shipping_address_name ?

                                    < AddressListItem customer_name={address.message[1]} address={item} call={true} selected={true} selectedIndex={selectedIndex} index_value={ind} indexValue={index} selecteddeletebtn={selecteddeletebtn} deletebtn={deletebtn} />

                                    :

                                    <AddressListItem customer_name={address.message[1]} address={item} call={true} selected={false} selectedIndex={selectedIndex} index_value={ind} indexValue={index} selecteddeletebtn={selecteddeletebtn} deletebtn={deletebtn} />
                                :
                                ind == index ?

                                    < AddressListItem customer_name={address.message[1]} address={item} call={true} selected={true} selectedIndex={selectedIndex} index_value={ind} indexValue={index} selecteddeletebtn={selecteddeletebtn} deletebtn={deletebtn} />

                                    :

                                    <AddressListItem customer_name={address.message[1]} address={item} call={true} selected={false} selectedIndex={selectedIndex} index_value={ind} indexValue={index} selecteddeletebtn={selecteddeletebtn} deletebtn={deletebtn} />
                        }
                        ) : ""}
                    </div>
                    <div className="container px-5 ">
                        <Link to={{
                            pathname: '/addaddress',
                            state: { method: "", value: "" }
                        }}><button className="btn bg-royalblue btn-border text-white whitecolor change-details-btn btn-block py-2">
                                Add New Address
                            </button></Link>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ShippingAddress;
