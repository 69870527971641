import {
  forgottenpassword,
  confirmpassword,
  user_loading,
} from "../../../actionTypes";
import Axios from "axios";
import { BASE } from "../../../api/api";

export const forgottenPassword = (email) => dispatch => new Promise(async (resolve, reject) => {
  // console.log("the given email id in forggotten password context", email);
  // console.log('%c the given email id in forggotten password context', 'color:blue;background:yellow', email)

  // var axios = require('axios');
  // let history = useHistory();

  dispatch({ type: user_loading, payload: true })
  var data = JSON.stringify({ "email": email });

  var config = {
    method: 'post',
    url: BASE + '/api/method/zoom_cart.otp_verification.otp_verification',
    headers: {
      'Authorization': 'token d03c7c3e6669a04:5596309d2292515',
      'Content-Type': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      // console.log('%c forgotten password  context calling successfully', 'color:green;background:yellow', response.data)
      dispatch({ type: user_loading, payload: false })
      dispatch({ type: forgottenpassword, payload: true })
      resolve(response.data)
    })
    .catch(function (error) {
      dispatch({ type: user_loading, payload: false })
      // console.log('%c forgotten password  context calling failed', 'color:green;background:yellow', error)
      // console.error(error);
      reject(error)

    });


})
export const ChangePassword = (otp, password) => dispatch => new Promise(async (resolve, reject) => {
  // console.log('%c the otp and password in ChangePassword context', 'color:blue;background:yellow', otp, password)
  dispatch({ type: user_loading, payload: true })
  var axios = require('axios');
  var data = JSON.stringify({ "otp": otp, "password": password });
  var config = {
    method: 'post',
    url: BASE + '/api/method/zoom_cart.otp_verification.password_change',
    headers: {
      'Authorization': 'token 155b35b5505b856:49f3f48eeed4907',
      'Content-Type': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      dispatch({ type: user_loading, payload: false })
      dispatch({ type: confirmpassword, payload: true })
      // console.log('%c ChangePassword  context successfully', 'color:green;background:yellow', response)
      resolve(response.data.message)

    })
    .catch(function (error) {
      // console.error(error);
      dispatch({ type: user_loading, payload: false })
      // console.log('%c ChangePassword  context successfully', 'color:red;background:yellow', error
      reject("Change_password_error")
    });


})
export const PasswordCall = () => dispatch => {
  dispatch({ type: forgottenpassword, payload: false })
}