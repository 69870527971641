import Axios from 'axios'
import { BASE } from '../api/api'
export const oder_confirmation = (order_id) => new Promise((resolve, reject) => {
    // console.log('order_confirmation function called',order_id)
    var user_tocken = localStorage.getItem('tocken')

    var data = JSON.stringify(
        {
            "order_id": order_id
        }
    );


    var config = {
        method: 'post',
        url: `${BASE}/api/method/afs_integration.afs_integration.doctype.afs_settings.afs_settings.payment_status`,
        headers: {
            'Authorization': `Bearer ${user_tocken}`,
            'Content-Type': 'application/json',
        },
        data: data
    };
    Axios(config)
        .then(function (response) {
            // setTimeout(() => {
            //     history.push('/')
            // }, 15000);
            response.data.message.result == 'SUCCESS' ? resolve('SUCCESS') : resolve('ERROR')

        })
        .catch(function (error) {
            // console.log('stock_check_api_call_failed', error);
            reject('FAILED')
            // setTimeout(() => {
            //     history.push('/')
            // }, 15000);
        });


})