import Axios from 'axios';
import { BASE } from '../../../api/api';

import {
  getaddress,
  switchaddress,
  deladdress,
  add_loading,
} from '../../../actionTypes';



export const GetAddress = () => dispatch => {
  // console.log("%c GetAddress context ", 'background:yellow;color:green',);
  var user_tocken = localStorage.getItem('tocken')
  // console.log(user_tocken)
  dispatch({ type: add_loading, payload: true });
  var user_mail = localStorage.getItem('usermail')
  var data = JSON.stringify({
    "email": `${user_mail}`
  });

  var config = {
    method: 'post',
    url: `${BASE}/api/method/algolia_sync.zoomcart_api.address`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
    },
    data: data
  };
  Axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // console.log(`%c getaddress context reponse  ${JSON.stringify(response.data.message)}`, 'background:yellow;color:green',);
      console.log('getaddresss function calling', response.data.message[1])

      dispatch({ type: deladdress, payload: false });
      dispatch({ type: getaddress, payload: response.data });
      dispatch({ type: add_loading, payload: false });
    })
    .catch(function (error) {
      console.error(`%c getaddress context error  ${error}`, 'background:yellow;color:red',);
      dispatch({ type: deladdress, payload: false });
      dispatch({ type: add_loading, payload: false });
    });

}
export const DelAddress = (address) => dispatch => new Promise((resolve, reject) => {
  // console.log("%c DelAddress context ", 'background:yellow;color:green', address.name);
  dispatch({ type: add_loading, payload: true });
  var user_tocken = localStorage.getItem('tocken')
  var data = JSON.stringify({
    "disabled": 1
  });

  var config = {
    method: 'put',
    url: `${BASE}/api/resource/Address/${address.name}`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: data
  };
  Axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // console.log(`%c deladdress context reponse  ${JSON.stringify(address.name)}`, 'background:yellow;color:green',);
      dispatch({ type: deladdress, payload: true });
      dispatch({ type: add_loading, payload: false });
      resolve('Del-Address-successfull')
    })
    .catch(function (error) {
      console.log(`%c deladdress context error  ${error}`, 'background:yellow;color:red',);
      dispatch({ type: add_loading, payload: false });
      reject('Del-Address-failed')

    });



})
export const Add_Address = (name, address_line1, address_line2, city, state, pincode, phone) => dispatch => new Promise(async (resolve, reject) => {
  // console.log("%c AddAddress context ", 'background:yellow;color:green',);
  var user_tocken = localStorage.getItem('tocken')
  dispatch({ type: add_loading, payload: true });

  // var data = JSON.stringify({"address_title":"Hafni","address_type":"Shipping","address_line1":"Malappuram","city":"Chelari","state":"Kerala","pincode":673003,"phone":9847893282});
  var data = JSON.stringify({ "address_title": name, "address_type": "Shipping", "address_line1": address_line1, "address_line2": address_line2, "city": city, "state": state, "pincode": pincode, "phone": phone });

  var config = {
    method: 'post',
    url: BASE + '/api/resource/Address',
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',

    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // console.log(`%c addaddress context reponse  ${JSON.stringify(response.data)}`, 'background:yellow;color:green',);
      dispatch({ type: add_loading, payload: false });
      resolve('Success')
    })
    .catch(function (error) {
      // console.log(`%c addaddress context error  ${error}`, 'background:yellow;color:red',);
      dispatch({ type: add_loading, payload: false });
      reject('fail')
    });


})
export const UpdateAddress = (name, address_line1, address_line2, city, state, pincode, phone, shipping_name) => dispatch => new Promise(async (resolve, reject) => {
  // console.log(`%c update context-- 
  // name ${name}
  // address_line1${address_line1}
  // address_line2${address_line2}
  // city${city}
  // state${state}
  // pincode${pincode}
  // phone${phone}
  // shipping_name${shipping_name}
  // `, 'background:yellow;color:green');
  var user_tocken = localStorage.getItem('tocken')
  dispatch({ type: add_loading, payload: true });

  // var data = JSON.stringify({"address_title":"Hafni","address_type":"Shipping","address_line1":"Malappuram","city":"Chelari","state":"Kerala","pincode":673003,"phone":9847893282});
  var data = JSON.stringify({ "address_title": name, "address_type": "Shipping", "address_line1": address_line1, "address_line2": address_line2, "city": city, "state": state, "pincode": pincode, "phone": phone });

  var config = {
    method: 'put',
    url: BASE + `/api/resource/Address/${shipping_name}`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',

    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // console.log(`%c updateaddress context reponse  ${JSON.stringify(response.data)}`, 'background:yellow;color:green',);
      dispatch({ type: deladdress, payload: true });
      dispatch({ type: add_loading, payload: false });
      resolve('done')

    })
    .catch(function (error) {
      // console.log(`%c updateaddress context error  ${error}`, 'background:yellow;color:red',);
      dispatch({ type: add_loading, payload: false });
      reject('fail')

    });


})
export const SwitchAddress = ({qtn,shipment_name}) => dispatch => new Promise(async (resolve, reject) => {
  // console.log("%c Switch context ", 'background:yellow;color:green', name);
  console.log('shipping switch address function called')
  var user_tocken = localStorage.getItem('tocken')
  dispatch({ type: add_loading, payload: true });
  

  var data = JSON.stringify({
    "data": {
      "shipping_address_name": shipment_name
    }
  });


  var config = {
    method: 'post',
    url: BASE + `api/resource/Quotation/${qtn}`,
    headers: {
      'Authorization': `Bearer ${user_tocken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: data
  };

  Axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data.message.address), 'background:yellow;color:green');
      // console.log(`%c shipping context reponse  ${JSON.stringify(response.data.data)}`, 'background:yellow;color:green');
      dispatch({ type: switchaddress, payload: JSON.stringify(response.data.message.address) });
      dispatch({ type: deladdress, payload: true });
      dispatch({ type: add_loading, payload: false });
      resolve('addresssChanged')
    })
    .catch(function (error) {
      // console.log(`%c switch context error  ${error}`, 'background:yellow;color:red',);
      dispatch({ type: add_loading, payload: false });
      reject('SwitchAddress-fail')
    });



})