import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../Context/Provider';
import { ChangePassword,PasswordCall } from '../../Context/actions/auth/forgotten_email';
import { Link, useHistory } from 'react-router-dom';
import { useAlert } from "react-alert";
import { validationform } from '../../util/validation';
import Loading from '../../component/loading/Loading'
import 'react-accessible-accordion/dist/fancy-example.css';
import BasicButtonSimple from '../../component/atom/BasicButtonSimple';
const Reset = () => {

    const {
        authDispatch,
        authState: {
            auth: { user_loading,  error, data, isAuthenticated },
        },
    } = useContext(GlobalContext);

    const alert = useAlert()
    const [password, setpassword] = useState('')
    const [newpassword, setnewpassword] = useState('')
    const [verifyOTP, setverifyOTP] = useState('')
    const [loading, setloading] = useState(false)
    const [validOtp, setvalidOtp] = useState(true)
    const [passwordvalid, setpasswordvalid] = useState(true)
    const [confirmpasswordvalid, setconfirmpasswordvalid] = useState(true)

    const history = useHistory();
    useEffect(() => {
        PasswordCall()(authDispatch)

    }, [])
    const confirm_validation = (e) => {
        if (newpassword == password) {
            setconfirmpasswordvalid(true)
            return true
        } else {
            setconfirmpasswordvalid(false);
            return false
        }
    }
    const validation_onchange = (e) => {
        if (passwordvalid == false) {
            if (e.name == 'password') {
                if (validationform(e)) {
                    setpasswordvalid(true)
                } else {
                    setpasswordvalid(false)
                }
            }
        } else if (confirmpasswordvalid == false) {
            setconfirmpasswordvalid(true)
            setnewpassword('')
        }
    }
    const Reset = () => {
        if (verifyOTP.length > 4 && password.length > 4) {
            ChangePassword(verifyOTP, password)(authDispatch)
                .then(e => {
                    if (e == "Incorrect OTP") {
                        alert.error("Incorrect OTP")
                    }
                    else if (e == "Your OTP has expired") {
                        alert.error("Your OTP has expired")
                        // alert.show("password Changed")
                    }
                    else if (e == "Password Changed Successfully") {

                        alert.show("Password Changed Successfully")
                        history.push('/')
                    }
                })
                .catch(e => {
                    alert.error("Please TRY again Later")
                })

        }
        // console.log('reset function call')
    }
    return (
        <div className="vh-100 reset-bg">
            {user_loading ? <Loading /> : ""}
            <div className="justify-content-center d-flex flex-column align-self-center align-items-center h-100">
                <div >
                    <form className="rounded border-1  border-black   p-5 reset-form bg-white" autoComplete="off">
                        <div className="form-group" onSubmit={(e) => { e.preventDefault() }}>
                            <div className="form-group d-flex flex-column ">
                                <img src={process.env.PUBLIC_URL + "/assets/zoomcartlogo.png"} className="align-self-center flex-center top-0 resetlogo mx-5 mb-5" />
                                <input type="number" name="otp" className=" profile-input" id="exampleInputPassword1" placeholder="Enter OTP *" onChange={(e) => setverifyOTP(e.target.value)} />
                                <input autoComplete="off" type="password" name="password" className=" profile-input" id="exampleInputPassword1" placeholder="Password *" onChange={(e) => { setpassword(e.target.value); validation_onchange(e.target) }} onBlur={e => { setpassword(e.target.value); validationform(e.target) ? setpasswordvalid(true) : setpasswordvalid(false) }} />
                                {passwordvalid ?
                                    ' '
                                    :
                                    <label className='valid-error'>Password at least 8 Characters , Contains At Least A Number, Special Character And A Mixture Of Uppercase And Lowercase Letters.</label>
                                }
                                <input autoComplete="off" type="password" name="password" className=" profile-input" id="exampleInputPassword1" placeholder="Confirm Password *" onChange={(e) => { setnewpassword(e.target.value); validation_onchange(e.target) }} onBlur={e => { setnewpassword(e.target.value); confirm_validation(e) ? setconfirmpasswordvalid(true) : setconfirmpasswordvalid(false) }} />
                                {confirmpasswordvalid ?
                                    ' '
                                    :
                                    <label className='valid-error'>Both password not same</label>
                                }
                            </div>
                            {/* <button type="button" className="btn resetbtn btn-block btn-royalblue bg-royalblue" disabled={passwordvalid == confirmpasswordvalid ? false : true} onClick={(e) => { e.preventDefault(); Reset() }}>Reset Password</button> */}
                            <BasicButtonSimple name="Reset Password" submitbtn={passwordvalid == confirmpasswordvalid ? false : true} function_call={(e) => { Reset() }} />
                            <div className='justify-content-center d-flex  align-self-center align-items-center'>

                            <div className='text-center' onClick={()=>{
                                history.replace('/')
                            }}> 
                            <img src={process.env.PUBLIC_URL + "/assets/left-arrow.png"} alt='back button' style={{ height: "16px", width: '16px' }} /> 
                            <label className='pt-5 px-3  '>Back To Home</label></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Reset;
