import React, { useContext, useEffect, useState, useRef } from 'react'
import Slickerone from '../../component/slicker/Slickerone';
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../Context/Provider';
import { TrendCarousel } from '../../Context/actions/Information/information';
import './home.css'
const LatestTrend = () => {
    // const { HomeCarousel, state } = useContext(CarouselContext)

    const {
        informDispatch,
        informState: {
            inform: { inform_loading, latesttrend },
        },
    } = useContext(GlobalContext);


    const latest_trend = [
        { 'name': '', 'img': '../assets/latesttrend1.png', },
        { 'name': '', 'img': '../assets/latesttrend2.png', },
        { 'name': '', 'img': '../assets/latesttrend1.png', },
        { 'name': '', 'img': '../assets/latesttrend2.png', },
    ]
    const sizeDesktop = { 'width': '471px', 'height': '629px', 'slide': 2.36 };
    const sizeNotebook = { 'width': '471px', 'height': '629px', 'slide': 1.9 };
    const sizeMiniTablet = { 'width': '471px', 'height': '629px', 'slide': 1.1 };
    const sizeTablet = { 'width': '471px', 'height': '629px', 'slide': 1.5 };
    const sizeMob = { 'width': '154px', 'height': '207px', 'slide': 2.1 };
    useEffect(() => {
        console.log('big')
        TrendCarousel()(informDispatch).then(e=>{
            console.log('man u')
        });
        console.log('small')
    }, [3])



    return (

        <div className="Latest-Trend-Part">
            <div className="desk-mpl5">
                <div className="title d-flex pt-3 align-items-center">

                    <span className="d-block latest-trends sub-title text-left ">
                        Be aware of the latest trends
                    </span>
                    <div className="desktop-visible">
                        <label className="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <p className="m-0 pt-1" style={{ borderBottom: "2px solid var(--royal-blue)" }} >   </p>
                    </div>

                </div>
                <p className="trends-desc">Stay informed of new trends, but also of our various offers.</p>
                <Link to='/product' className="d-block text-decoration-none  royal-blue-text  Learn-more pt-2">Learn more &nbsp;
                    <img style={{ width: "6px", height: "12px" }} src={process.env.PUBLIC_URL + "/assets/vector right.png"} /> </Link>
            </div>
            <div className="py-5 desk-mpl5">
                <div className="">

                    {latesttrend ? (
                        <div>

                            <div className="desktop-slide">
                                <Slickerone values={[latesttrend, sizeDesktop, "btn", "latest_trend_img"]} />
                            </div>
                            <div className="notebook-slide">
                                <Slickerone values={[latesttrend, sizeNotebook, "btn", "latest_trend_img"]} />
                            </div>
                            <div className="tablet-slide">
                                <Slickerone values={[latesttrend, sizeTablet, "btn", "latest_trend_img"]} />
                            </div>
                            <div className="minitablet-slide">
                                <Slickerone values={[latesttrend, sizeMiniTablet, "btn", "latest_trend_img"]} />
                            </div>
                            <div className="mob-slide">
                                <Slickerone values={[latesttrend, sizeMob, "btn", "latest_trend_img"]} />
                            </div>
                        </div>
                    ) :
                        (
                            <div>

                                <div className="desktop-slide">
                                    <Slickerone values={[latest_trend, sizeDesktop, "btn", "latest_trend_img"]} />
                                </div>
                                <div className="notebook-slide">
                                    <Slickerone values={[latest_trend, sizeNotebook, "btn", "latest_trend_img"]} />
                                </div>
                                <div className="tablet-slide">
                                    <Slickerone values={[latest_trend, sizeTablet, "btn", "latest_trend_img"]} />
                                </div>
                                <div className="minitablet-slide">
                                    <Slickerone values={[latest_trend, sizeMiniTablet, "btn", "latest_trend_img"]} />
                                </div>
                                <div className="mob-slide">
                                    <Slickerone values={[latest_trend, sizeMob, "btn", "latest_trend_img"]} />
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>

        </div>
    )
}
export default LatestTrend;


