import React, { useState, useEffect, useContext } from 'react'
import { Modal } from "react-bootstrap";

const LogoutPopup = (props) => {
    const { logoutPopup, toggleLoginShow, togglePopup } = props
    // console.log('logout POpup called',logoutPopup)

    return (

        <Modal
            className="cart-modal"
            show={logoutPopup}
            onHide={togglePopup}
        >
            <Modal.Body >
                <button title="Close" type="button" id="newsletter-close" class="logout-popup" onClick={()=>props.togglePopup()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </button>
                <div className='cart-box bg-white  container align-content-center' style={{ maxHeight: "399px", maxWidth: "768px", }} >
                    <h2>Your Session has Expired. Please Login Again</h2>
                </div>
            </Modal.Body>
        </Modal>



    )
}
export default LogoutPopup;