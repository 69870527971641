import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from "react-alert";
import { Link, useHistory } from 'react-router-dom';
import close from '../assets/close.png'
import './addresslist.css'
import { DelAddress, SwitchAddress, GetAddress } from '../../Context/actions/address/address';
import { GlobalContext } from '../../Context/Provider';
import { cart_switch_address } from '../../Context/actions/cart/cart';
const AddressListItem = (props, { selecteddeletebtn, deletebtn, customer_name }) => {
    const { index_value, selectedIndex, indexValue } = props
    console.log('value of props is address -->', props.customer_name)
    const alert = useAlert();
    // const { DelAddress, state, SwitchAddress, Confirmation } = useContext(AddressContext)
    const {
        addressDispatch,
        addressState: {
            address: { address, deladdress },
        },
        cartDispatch,
        cartState: {
            cart: { cart_status, cart_loading, cartname, getcart, get_data },
        }
    } = useContext(GlobalContext);
    let history = useHistory();
    const adding = (props) => {
        localStorage.setItem('user_name',props.customer_name)
        cart_switch_address({ shipment_name: props.address.name, qtn: cartname, customer_name: props.customer_name })(cartDispatch)
            .then(r => {
                localStorage.setItem('shipment_name -->', props.address.name)
                console.log('confirmation is commented ')
                history.push("/confirmation")
                // Confirmation()
                //     .catch(console.error('switch-address function fail in Context'))
            })
            .catch(console.error('switch-address function fail in Context'))
    }
    useEffect(() => {
        // console.log("props value pasing in address part ", props)
    }, [window.location.pathname, DelAddress, deladdress]);


    return (
        <div id="address-list-item" className="address-list-item ">
            <div style={props.selected ? { background: 'rgba(233,251,245,255)', border: '1px solid rgba(233,251,245,255) !important' } : { background: 'transparent' }}
                className={
                    ` ${props.call ? " mouse-pointer mr-3 hover-pointer w-100 text-uppercase position-relative pl-3" : " mr-3 w-100 text-uppercase position-relative pl-3"}
               ${props.selected ? "selected-address" : "border border-1 border-dark"}   `
                }
                onClick={
                    () =>
                        props.call ?
                            (
                                selectedIndex(index_value),
                                adding(props))

                            :
                            ""
                }>

                {props.selected ? (
                    <div>
                        <img src="/assets/Tick_Icon.svg" style={{
                            width: "24px",
                            height: "24px",
                            position: "absolute",
                            right: "0",
                            zIndex: "1",
                            margin: "1px",
                        }} />

                        <div style={{
                            background: '#65e265',
                            position: 'absolute',
                            right: '0',
                            borderRight: '45px solid transparent',
                            borderBottom: '50px solid rgba(233,251,245,255)'
                        }}
                        >
                        </div>
                    </div>)
                    : ''
                }
                <p className="mt-3 address-list-item-para small-normal w-100">
                    {props.address.address_title ? props.address.address_title : ""}<br />
                    {props.address.address_line1 ? props.address.address_line1 : ""}<br />
                    {props.address.address_line2 ? props.address.address_line2 : ""}<br />
                    {props.address.phone ? props.address.phone : ""}<br />
                    {props.address.pincode ? props.address.pincode : ""}<br />
                    {props.address.city ? props.address.city : ""}<br />
                    {props.address.state ? props.address.state : ""}<br />
                    {props.address.country ? props.address.country : ""}<br />
                </p>

            </div>
            <div className="btns my-3">
                <div className="delete-add-mob " onClick={() => props.address.name ? DelAddress(props.address)(addressDispatch).then(e => {
                    GetAddress()(addressDispatch);
                    selecteddeletebtn(!deletebtn)
                }) : alert.error("Deletion not working at the moment,Please try again later")}>
                    <img alt="icon" src={close} />
                </div>
                <button className="btn bg-royalblue  text-white btn-border change-details-btn mx-1 " onClick={() => {
                    history.push({ pathname: "/addaddress", state: { method: "update", value: props.address } })

                }}>
                    Change
                </button>

                <button className="btn bg-royalblue  text-white btn-border change-details-btn mx-1 d-none d-sm-block"
                    onClick={() =>
                        props.address
                            ?
                            props.address.name ?
                                DelAddress(props.address)(addressDispatch).then(e => {
                                    GetAddress()(addressDispatch);
                                    // selecteddeletebtn(!deletebtn)
                                })
                                :
                                alert.error("Deletion not working at the moment,Please try again later")
                            :
                            alert.error("Deletion not working at the moment,Please try again later")
                    }
                >
                    Delete
                </button>
            </div>
        </div >
    );
}

export default AddressListItem;
