import React, { createContext, useReducer, Children } from "react";
import auth from "./reducers/auth";
import cart from './reducers/cart'
import order from './reducers/order'
import address from "./reducers/address";
import payment from './reducers/payment';
import inform from './reducers/inform';
import authInitialState from "./intialstates/authInitialState";
import cartInitialStates from "./intialstates/cartInitialStates";
import orderInitialStates from "./intialstates/orderInitialState";
import addressInitialState from "./intialstates/addressInitialState"
import paymentInitialState from "./intialstates/paymetInitialStates";
import informInitialState from "./intialstates/informtInitialStates";
// import contacts from "./reducers/contacts";
// import contactsInitialState from "./intialstates/contactsInitialState";

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(auth, authInitialState);
  const [cartState, cartDispatch] = useReducer(cart, cartInitialStates);
  const [orderState, orderDispatch] = useReducer(order, orderInitialStates);
  const [addressState, addressDispatch] = useReducer(address, addressInitialState);
  const [paymentState, paymentDispatch] = useReducer(payment, paymentInitialState);
  const [informState, informDispatch] = useReducer(inform, informInitialState);

  return (
    <GlobalContext.Provider
      value={{
        authState,
        authDispatch,
        cartState,
        cartDispatch,
        orderState,
        orderDispatch,
        addressState,
        addressDispatch,
        informState,
        informDispatch,
        paymentState,
        paymentDispatch
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
