import React, { useState, useEffect, useContext } from 'react'
import { Modal } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom'
import { useAlert } from "react-alert";
import { validationform } from '../../util/validation'
import BasicButtonSimple from '../atom/BasicButtonSimple';
import { forgottenPassword } from '../../Context/actions/auth/forgotten_email';
import { GlobalContext } from '../../Context/Provider';
const Desktopforgotten = (props) => {

    let history = useHistory()
    const alert = useAlert();
    const { forgotenacc, toggleforgottenShow } = props
    const [email, setemail] = useState('')
    const {
        authDispatch,
        authState: {
            auth: { user_loading, loading, error, data, isAuthenticated },
        },
    } = useContext(GlobalContext);
    // validation
    const [validEmail, setvalidEmail] = useState(true)
    // const { forgottenpassword } = useContext(AuthContext)
    const forgotten_password = () => {
        forgottenPassword(email)(authDispatch)
            .then(e => {
                if (e.message == 'OTP sent successfully') {
                    alert.show('Please Check Email');
                    history.push("/reset")
                } else if (e.message == undefined) {
                    alert.error('Email not Exist');
                }
            }
            )
    }

    return (


        <Modal
            className="cart-modal"
            show={forgotenacc}
            onHide={toggleforgottenShow}
            centered

        >
            <Modal.Body >
                <div className="bg-danger position-relative">

                    <div className='cart-box bg-white border container align-content-center' style={{ height: "399px", width: "471px", }} >
                        <div className="position-relative ">
                            <div className="login container ">
                                <h2 className="headmedium mt-5 mb-sm-3 secondarycolor">Request a Password reset</h2>
                                <form className="pt-5">
                                    <div className="inp-fields">
                                        <input onChanges={(e) => setemail(e.target.value)} name="email" onBlur={e => { setemail(e.target.value); validationform(e.target) ? setvalidEmail(true) : setvalidEmail(false) }} className="profile-input d-block my-3" placeholder="E-mail*" />
                                        {validEmail ?
                                            ' '
                                            :
                                            <label className='valid-error'>Enter valid email address </label>
                                        }
                                    </div>
                                    {/* <input onClick={() => { forgotten_password() }} type="button" className="btn-royalblue bg-royalblue my-5" value="Reset Password" style={{ height: "30px", width: "100%" }} /> */}
                                    <BasicButtonSimple name="Reset Password" submitbtn={false} function_call={() => forgotten_password()} />

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>



    )
}
export default Desktopforgotten;