import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Review } from '../../Context/actions/Information/information';
import { GlobalContext } from '../../Context/Provider';
import './home.css'

const Slickerpart1 = () => {
    const {
        informDispatch,
        informState: {
            inform: { inform_loading, review },
        },
    } = useContext(GlobalContext);
    var slider = 1;
    var next = (props) => {
        slider.slickNext();

    }
    useEffect(() => {
        next = next.bind()
    })

    useEffect(() => {
        Review()(informDispatch)
    }, [])

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={process.env.PUBLIC_URL + "/assets/next-white-button-slicker.svg"}
                className={className}
                style={{ ...style, width: "45px", height: "40px", right: 0 }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <img
                src={process.env.PUBLIC_URL + "/assets/prev-white-button-slicker.svg"}
                className={className}
                style={{ ...style, width: "45px", height: "40px", right: 0, zIndex: 10 }}
                onClick={onClick}
            />
        );
    }
    var settings = {
        infinite: false,
        speed: 500,
        autoplaySpeed: 500,
        slidesToShow: 2.44,
        slidesToScroll: 1.5,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1.05,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.15,
                    slidesToScroll: 1.1,
                    infinite: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1.51,
                    slidesToScroll: 1.5,
                    infinite: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows: true,
                }
            },
        ]
    }
        ;
    const review_ = [
        {
            desc: "Our team has been working at frispes for almost 2 months because our office still closed. During working at frispes, we feel a new experience, we can feel working with a beautiful, modern, and comfortable space. And cozy cafe with high-speed internet  in there. Thank you for frispes and team.",
            img: "../assets/Ellipse1.png", Name: "Pedro Ibanez", subName: "Lorem Ipsum"
        },
        {
            desc: "The high-speed internet at frispes was so amazing. I can get best experience when running video call with my clients. Frispes has spacious and safe parking area, that’s what i need because i used to work as freelancer for a long time.",
            img: "../assets/Ellipse1.png", Name: "Raymond Thual", subName: "Lorem Ipsum"
        },
        {
            desc: "Just three words : Frispes is Awesome!",
            img: "../assets/Ellipse1.png", Name: "Yuli Berlian", subName: "Lorem Ipsum"
        },
    ]

    return (
        <div>
            <Slider ref={c => (slider = c)} {...settings}>
                {review ?
                    review.data.map((x) =>
                    (
                        <div>

                            <div className=' '>

                                <div className="customercard text-white ">
                                    <div className="container py-5 px-5 ">
                                        <p>{x.review}
                                        </p>

                                    </div>
                                    <div className="d-flex px-5 position-absolute bottom-0 pb-sm-5 pb-3">

                                        <div className="align-self-end">
                                            <div className=" customername d-flex align-self-end  ">
                                                <div className="px-3 ">
                                                    <span>{x.name1}</span>
                                                    <p>{x.designation} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>)
                    )
                    :
                    review_.map((x) => (
                        <div>

                            <div className=' '>

                                <div className="customercard text-white ">
                                    <div className="container py-5 px-5 ">
                                        <p>{x.desc}
                                        </p>

                                    </div>
                                    <div className="d-flex px-5 position-absolute bottom-0 pb-sm-5 pb-3">

                                        <div className="align-self-end">
                                            <div className=" customername d-flex align-self-end  ">
                                                <img src={x.img} className="rounded-circle" />
                                                <div className="px-3 ">
                                                    <span>{x.Name}</span>
                                                    <p>{x.subName} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    ))
                }
            </Slider>

        </div>
    )
}
export default Slickerpart1;


