// import React from "react";
import React, { useEffect, useState, useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import Footer from '../../component/footer/Footer'
import Header from '../../component/header/Header'
import BannerCarosel from './BannerCarosel';
import MainCategory from './mainCategory'
import BestSelling from './BestSelling'
import LatestTrend from './LatestTrend'
import BasicButton from '../../component/atom/basicButton'
import Review from './Review'
import './home.css'
const Home = () => {
    const history = useHistory();
    return (
        <div className="">
            <Header />
            <div className='home '>
                <div className="np-mob">
                    <div className="banner-contain">
                        <div className="col-12 col-sm-6  desk-mpl5 d-flex">
                            <div className="container px-0">
                                <p className='banner-title'>Check out 30+ Zoomcart carpets</p>
                                <p className="banner-desc ">When you walk the talk, make sure to leave the right footprints.</p>
                                <BasicButton name="Shop Now" function_call={() => history.push("/product")} />
                                
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 nopadding np-mob"><BannerCarosel /></div>
                    </div>
                </div>
                <MainCategory />
                <BestSelling />
                <LatestTrend />
                <Review />
            </div>
            <Footer />

        </div>
    )
}
export default Home;


