import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddressListItem from '../../component/profile/addressListItem';
import 'react-accessible-accordion/dist/fancy-example.css';
import { GlobalContext } from '../../Context/Provider';
import { GetAddress } from '../../Context/actions/address/address';
const ShippingList = () => {
    const {
        addressDispatch,
        addressState: {
            address: { address, deladdress },
        },
        cartDispatch,
        cartState: {
            cart: { cart_status, cart_loading, cartname, getcart, get_data },
        }
    } = useContext(GlobalContext);
    useEffect(() => {
        GetAddress()(addressDispatch)
    }, [])
    useEffect(() => {
        if (deladdress) { GetAddress()(addressDispatch) }
    }, [deladdress]);

    return (
        <div id="profile-edit" className="profile-edit">
            <p className="container mt-3 small-normal pad-0">
                Manage all the shipping addresses you want (work place, home address ...) This way you won't have to enter the shipping address manually with each order.
            </p>
            <div className="shipping-list-container container pad-0 mob">
                {/* {state.getAddress?console.log("calling getaddress function",state.getAddress):""} */}
                {
                    address != 'this' ?
                        address.message[0].map((address) => (
                            <AddressListItem address={address} call={false} />

                        ))
                        :
                        ""
                }
            </div>
            <div className="container absolute-btn btn-shiplist">
                <Link to={'/profile-details/addadress'}><button className="btn bg-royalblue btn-border text-white whitecolor change-details-btn btn-block ">
                    Add New Address
                </button></Link>
            </div>
        </div>
    );
}

export default ShippingList;
