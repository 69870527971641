import React, { useEffect, useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { BASE } from '../../api/api'
import './RefinedMaincategory.css'
import PropTypes from 'prop-types';
import {
    SearchBox,
    Hits,
    InstantSearch,
    connectRefinementList,
    Configure,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
    'NAH884LD6O',
    '155ad86e58cb50ae7eefab00df3bc15d'
);
var titlename = ''

const RefinedMainCategory = (props) => {
    const history = useHistory();
    titlename = props.name
    useEffect(() => {
    }, [])

    return (
        <InstantSearch
            indexName="zoomcartlive" searchClient={searchClient} >
            <Configure hitsPerPage={1} />
            <div className="d-none">
                <SearchBox className="searchbtn" defaultRefinement={props.name} />

            </div>
            <div className=" hitstylee w-100">

                <Hits hitComponent={Hit} className="list-style-none row " />
            </div>
        </InstantSearch>


    )
}
export default RefinedMainCategory;
function Hit(props) {
    console.log('category filter portion -->', props)
    return (

        <div className="pt-5 links">
            <Link to={{
                pathname: `/product`,
                state: { category: props.hit.Category }
            }} className="">
                <div className="card  text-white d-flex mouse-pointer "  >
                    <img className="mainCategoryCardImg card-img img-fluid" src={props.hit['Image URL'] ? props.hit['Image URL'][0] != null ? props.hit['Image URL'][0] != '' ? BASE + props.hit['Image URL'][0] : "../assets/square.jpeg" : "../assets/square.jpeg" : "../assets/square.jpeg"} alt="main catergory" />

                    <div className="card-img-overlay align-items-end d-flex justify-content-center nopadding overlay-bg">
                        <p className=' mainCategyCard-title text-white ' >
                            {props.hit.Category}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );
}
Hit.propTypes = {
    hit: PropTypes.object.isRequired,
};