import {
  pay_loading

} from '../../actionTypes';

const payment = (state, { payload, type }) => {
  switch (type) {
    case pay_loading:
      return {
        ...state,
        payment: {
          // ...state.address,
          pay_loading: payload,
        },
      };
    default:
      return state;
  }
};

export default payment;
