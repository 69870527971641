import React, { useState, useEffect, useContext } from 'react'
import Popup from 'reactjs-popup'
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import validator from 'validator'
import { Redirect, useHistory, } from 'react-router-dom'
import { validationform } from '../../util/validation'
// import ReactFormInputValidation from "react-form-input-validation";
import { useAlert } from "react-alert";
import { GlobalContext } from '../../Context/Provider';
import { Register } from '../../Context/actions/auth/register';
import { Login } from '../../Context/actions/auth/login';
import BasicButtonSimple from '../atom/BasicButtonSimple';

const DesktopRegister = (props) => {
    const history = useHistory();
    const alert = useAlert();
    const { acc, toggleLoginShow, toggleaccShow } = props
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [fullname, setfullname] = useState('')

    // validation
    const [validEmail, setvalidEmail] = useState(true)
    const [passwordvalid, setpasswordvalid] = useState(true)
    const [fullnamevalid, setfullnamevalid] = useState(true)
    // const { Register, Login } = useContext(AuthContext)

    const {
        authDispatch,
        authState: {
            auth: { user_loading, loading, error, data, isAuthenticated },
        },
    } = useContext(GlobalContext);


    const validationnone = () => {
        setvalidEmail('')
        setpasswordvalid('')
        setfullnamevalid('')
    }

    const Register_call = () => {
        Register(email, fullname, password)(authDispatch)
            .then(res => {
                alert.show("Account Created")
                Login(res.email, res.password)(authDispatch)
                    .then(() => {
                        localStorage.removeItem('loggednotify');
                        history.push("/")
                    })
                    .catch(() => {
                        alert.error("Invalid Email or Password");
                        localStorage.removeItem('loggednotify');

                    })
            })
            .catch(res => {

                if (res == "Already account created") {
                    alert.error("Already account created")
                } else if (res == "Registration failed") {
                    alert.error("Invalid Data Entered")

                }
            })
    }
    const validator_form = (e) => {
        if (e.name == 'register-email') {
            if (validEmail == false) {
                if (validationform(e)) {
                    setvalidEmail(true)
                } else {
                    setvalidEmail(true)
                }
            }
        } else
            if (e.name == 'register-fullname') {
                if (fullnamevalid == false) {
                    if (validationform(e)) {
                        setfullnamevalid(true)
                    } else {
                        setfullnamevalid(true)
                    }
                }
            } else
                if (e.name == 'register-password') {
                    if (passwordvalid == false) {
                        if (validationform(e)) {
                            setpasswordvalid(true)
                        } else {
                            setpasswordvalid(true)
                        }
                    }
                }
    }

    return (

        <Modal
            className="cart-modal"
            show={acc}
            onHide={toggleaccShow}
            centered>
            <Modal.Body >
                <div className="bg-danger position-relative">
                    <div className='cart-box bg-white border container align-content-center register-card'  >
                        <div className="position-relative ">
                            <div className="login container ">
                                {/* <h2 className="headmedium mt-5 mb-sm-3 secondarycolor">Login</h2> */}
                                <form className="pt-5" autoComplete="off">
                                    <div className="inp-fields">
                                        <input autoComplete="off" onKeyPress={(e) => { validator_form(e.target); setemail(e.target.value) }} name="register-email" onBlur={e => { setemail(e.target.value); validationform(e.target) ? setvalidEmail(true) : setvalidEmail(false) }} className="profile-input d-block my-3" placeholder="E-mail*" />
                                        {validEmail ?
                                            ' '
                                            :
                                            <label className='valid-error'>Enter valid email address </label>
                                        }
                                        <input autoComplete="off" onKeyPress={(e) => { validator_form(e.target); setfullname(e.target.value) }} name="register-fullname" onBlur={e => { setfullname(e.target.value); validationform(e.target) ? setfullnamevalid(true) : setfullnamevalid(false) }} className="profile-input d-block my-3" type="text" placeholder="Full Name*" />
                                        {fullnamevalid ?

                                            ' '
                                            :
                                            <label className='valid-error'>full name is too short.full name must contain minimum 3 charachter </label>
                                        }
                                        <input autoComplete="off" onKeyPress={(e) => { validator_form(e.target); setpassword(e.target.value) }} name="register-password" onBlur={e => { setpassword(e.target.value); validationform(e.target) ? setpasswordvalid(true) : setpasswordvalid(false) }} className="profile-input d-block my-3" type="password" placeholder="Password*" />
                                        {passwordvalid ?

                                            ' '
                                            :
                                            <label className='valid-error'>Password at least 8 Characters , Contains At Least A Number, Special Character And A Mixture Of Uppercase And Lowercase Letters.</label>
                                        }
                                    </div>
                                    {/* <input type="button" className="btn-royalblue bg-royalblue my-5" value="Register" disabled={validEmail && fullnamevalid && passwordvalid ? false : true} onClick={() => { Register_call() }} style={{ height: "30px", width: "100%" }} /> */}
                                    <BasicButtonSimple name="Register" submitbtn={validEmail && fullnamevalid && passwordvalid ? false : true} function_call={() => Register_call()} />

                                </form>
                                <div className="my-3" style={{ textAlign: "center" }}>

                                    <p className="mouse-pointer change-mode desk-only" onClick={() => { props.toggleaccShow(); validationnone(); props.toggleLoginShow() }}>or login to your account</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>



    )
}
export default DesktopRegister;