import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'

const Footer = () => {
    const path = window.location.pathname

    return (

        <div className="footer-part">
            <footer className="pt-5 footer">
                <div className="container pb-5">
                    <div className="col-11 col-md-12 px-0 px-lg-0 d-md-flex ">
                        <div className="col-12 py-3 py-lg-0 px-0 col-md-3">
                            <h5 className="p14-bold py-3" >Customer Center</h5>
                            <span>+973-38814222, +973-38815222</span>
                            <span>Weekdays: Saturday - Thursday</span>
                            <span>Working Hours: 08:30 - 17:30</span>
                            <span>Holidays: Friday, National Holidays</span>

                        </div>

                        <div className="col-12 py-3 py-lg-0 px-0 col-md-3">
                            <h5 className="p14-bold py-3">Shipment Info</h5>
                            {/* <span ><Link style={{ borderBottom: "1px solid white" }} to='/' className='p14'>Track Shipping Location</Link></span> */}
                            <span>Refund/exchange address</span>
                            <span>ZOOM CONSULTANCY W.L.L</span>
                            <span>OFFICE 12, BLDG 656,</span>
                            <span>ROAD 3625  ADLIYA 336</span>
                            <span> KINGDOM OF BAHRAIN</span>
                            <span>(Please make sure to use
                                Post Office Parcel service)</span>
                        </div>

                        {/* <div className="col-12 py-3 py-lg-0 px-0 col-sm-2">
                            <h5 className="p14-bold py-3">Company</h5>
                            <span><Link to='/' className='p14'>Company</Link></span>
                            <span><Link to='/' className='p14'>Notice/Event</Link></span>
                        </div> */}

                        <div className="col-12 py-3 py-lg-0 px-0 col-md-3">
                            <h5 className="p14-bold py-3">POLICY</h5>
                            {/* <span ><Link style={{ borderBottom: "1px solid white" }} to='/' className='p14'>Track Shipping Location</Link></span> */}
                            <span><Link to='/termsandcondition' className='p14'>Terms and Conditions</Link></span>
                            <span><Link to='/privacyPolicy'>Privacy Policy</Link></span>
                            <span><Link to='/DeliveryTimeandShippingPolicy/#delivery'>Delivery Timeline / Shipping Policy</Link></span>
                            <span><Link to='/DeliveryTimeandShippingPolicy/#cancellation'>Cancellation, Refund & Exchange Policy</Link></span>

                        </div>
                        <div className="col-md-3 col-12 py-3 py-lg-0 px-0 d-flex  justify-content-lg-end">
                            <div >

                                <h5 className="p14-bold py-3">Social media </h5>
                                <div className='footer-sub-link-parent d-flex'>

                                    <div className='mouse-pointer' onClick={() => {
                                        window.open('https://instagram.com/zoomcart.online?igshid=YmMyMTA2M2Y=', '_blank');
                                    }}><img className='social' src={process.env.PUBLIC_URL + '/assets/insta-icon.png'} /><span className='py-1'>instagram</span></div>
                                    <div className='mouse-pointer' onClick={() => {
                                        window.open('https://wa.me/message/S722OSPN4JYWP1', '_blank');
                                    }}><img className='social' src={process.env.PUBLIC_URL + '/assets/whatsapp_icon.svg'} /><span className='py-1'>whatsapp</span></div>
                                    <div className='mouse-pointer' onClick={() => {
                                    }}><img className='social' src={process.env.PUBLIC_URL + '/assets/facebook-icon.png'} /><span className='py-1'>facebook</span></div>
                                </div>
                                {/* <img className='social px-1' src={process.env.PUBLIC_URL + '/assets/pinterest-icon.png'} /> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div className='contianer my-1 border border-1  border-top justify-content-center'>
                    <div className="text-center my-5">
                        <span className='  '>CEO : KAMAL MOHIYUDDIN │ Company Name :  ZOOM CONSULTANCY W.L.L │ Tel : +973-38814222, +973-38815222 │ E-mail :  support@zoomcart.online </span>
                        <span>address : ZOOM CONSULTANCY W.L.L ,OFFICE 12, BLDG 656,ROAD 3625 ADLIYA 336, KINGDOM OF BAHRAIN</span>
                        <span>CR No: 68542-02 │ Technical Manager : KAMAL MOHIYUDDIN </span>
                        <span>ⓒ 2020 JDESIGN All rights reserved. │ Privacy Policy │ Terms of Service</span>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Footer;