import React, { useEffect, useState, useRef } from 'react'
import './home.css'
import RefinedMainCategory from '../../component/RefinedCards/RefinedMainCategory'

const MainCategory = () => {
    
    const category = ['Silk & Silk', 'Silk & Wool', 'Wool & Wool']

    return (

        <div className="conatainer">

            <div className="py-5">


                <div className='contain np-mob  text-center'>
                    <div className="title  px-5 np-mob  d-inline-flex">
                        <p className="title-margin1" />
                        <h3 className='text-center sub-title px-3 d-inline-flex'>  Main Categories </h3>
                        <p className="title-margin2" />
                    </div>

                    <div className="row mx-md-5 mx-sm-4  np-mob">

                        {category.map((c) => {
                            if (c != "Tiles and planks")
                                return (
                                    <div className="col-6 col-sm-3 position-relative my-3 p-3px d-flex justify-content-center container">
                                        <RefinedMainCategory name={c} />
                                    </div>
                                )
                        })}
                    </div>

                </div>

            </div>


        </div>

    )
}
export default MainCategory;


