export default {
    cart: {
      loading: false,
      data: null,
      error: null,
      get_data:'shipiping',
      cart_name:'cart data'
    },
    nav_load:{
      data:'zero'
    }
  };