import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import validator from 'validator'
import BasicButton from '../atom/basicButton';
import Loading from '../../component/loading/Loading'
import { GlobalContext } from '../../Context/Provider';
import { Link, useHistory } from 'react-router-dom'
import { forgottenPassword } from '../../Context/actions/auth/forgotten_email';

const PassWord = () => {
    let history = useHistory()

    React.useEffect(() => {
        let email = localStorage.getItem('usermail')
        setfor_email(email)
    }, []);
    const alert = useAlert()
    const [send_pwd, set_send] = useState(false)
    const [for_email, setfor_email] = useState('')
    const [tmp_pwd, change_tmp] = useState('')
    const [new_pwd, change_new] = useState('')
    const [loading, setloading] = useState(false)
    const {
        authDispatch,
        authState: {
            auth: { user_loading }
        } } = useContext(GlobalContext)

    useEffect(() => {
        setloading(user_loading)
    })
    const forgot_pwd = () => {
        forgottenPassword(for_email)(authDispatch)
            .then(e => {
                alert.show('Please Check Email');
                history.push("/reset")
            })
            .catch(c => {
                alert.error('Email not Valid');
            })
    }
    return (
        <div id="personal_data">
            {loading ? <Loading /> : ''}
            {!send_pwd ?
                <div>
                    <p className="small-normal">If you want to change the password to access your account, enter the folowing information:Your current email address is {for_email}</p>
                    <BasicButton name="Change Password" function_call={() => forgot_pwd()} />

                </div> :
                <div>
                    <p className="small-normal">If you want to change the password to access your account, enter the folowing information:Your current email address is {for_email}</p>
                    <div className="fields-sec mx-0 row">
                        <input className="profile-input col-md-5 mr-md-4" value={tmp_pwd} onChange={(e) => change_tmp(e.target.value)} placeholder="Temporary password" />
                        <input className="profile-input col-md-5 mr-md-4" value={new_pwd} onChange={(e) => change_new(e.target.value)} placeholder="New password" />
                    </div>
                    <BasicButton name="Change Password" function_call={() => forgottenPassword(for_email)(authDispatch)
                        .then(e => {
                            alert.show('Please Check Email');
                            history.push("/reset")
                        })
                        .catch(c => {
                            alert.error('Email not Valid');
                        })} />
                </div>
            }
        </div>
    );
}

export default PassWord;
