import React, { useEffect, useState, useRef, useContext } from 'react'
import Customer from './Customer';
import './home.css'


const Review = () => {
    return (

        <div className="py-5 desk-mpl5 ">
            <div className=" ">

                <div className="title1   d-flex mb-lg-3 align-items-center">

                    <span className="">Reviews &nbsp;&nbsp;&nbsp;</span>
                    <p className='m-0 pt-1' />
                </div>
                <span className=" d-block reviewtitle">
                    What our customers are saying
                </span>

                <div className="py-5">
                    <div className="d-flex flex-column">
                        <Customer />


                    </div>
                </div>


            </div>
        </div>
    )
}
export default Review;


