export const logedin = 'logedin'
export const register = 'register'
export const token = 'token'
export const tocken = 'tocken'
export const refreshtocken = 'refreshtocken'
export const user_loading = "user_loading"

export const user_details ='user_details'

export const getcart = 'getcart'
export const carts = 'carts'
export const cart_status = 'cart_status'
export const stock_data = 'stock_data'
export const stock_error = 'stock_error'
export const cart_loading = 'cart_loading'
export const cart_name = 'cart_name'



export const forgottenpassword = 'forgottenpassword'
export const confirmpassword = 'confirmpassword'

export const setloading = 'setloading'
export const loading_freezz = 'loading_freezz'


export const inform_loading = 'inform_loading'
export const carousel = 'carousel'
export const latesttrend = 'latesttrend'
export const aboutus = 'aboutus'
export const review = 'review'
export const feedback = 'feedback'

export const addaddress = 'addaddress'
export const switchaddress = 'switchaddress'
export const getaddress = 'getaddress'
export const selected_address = 'selected_address'
export const confirmationData = 'confirmationData'
export const deladdress = 'deladdress'
export const add_loading = 'deladdress'

export const sales_order = 'sales_order'
export const session_id = 'session_id'

export const current_date = 'current_date'
export const expire_date = 'expire_date'

export const order_history = 'order_history'
export const single_order = 'single_order'
export const order_loading = 'order_loading'

export const pay_loading = "pay_loading"
