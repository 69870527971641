import {
  order_loading,
  order_history,
  single_order,
  } from '../../actionTypes';
  
  const order = (state, {payload, type}) => {
    switch (type) {
      case order_loading:
        return {
          ...state,
          order: {
            ...state.cart,
            error: false,
            loading: true,
          },
        };
  
      case order_history:
        return {
          ...state,
          order: {
            ...state.cart,
            loading: false,
            order_history: payload,
          },
        };
  
      case single_order:
        return {
          ...state,
          order: {
            ...state.cart,
            loading: false,
            single_order: payload,
          },
        };
      default:
        return state;
    }
  };
  
  export default order;
  