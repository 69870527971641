import algoliasearch from 'algoliasearch/lite';


export const BASE='https://zoom-be.tridz.in';

// export const BASE = 'https://zcart.frappe.cloud';
export const LIVE_SITE_URL = 'https://zoom-react.pages.dev/'
export const DEV_SITE_URL = 'https://dev-zoom.tridz.in/'
export const searchClient = algoliasearch(
    'NAH884LD6O',
    '155ad86e58cb50ae7eefab00df3bc15d'
);


export const search_algolia = (props) => new Promise((resolve, reject) => {
    const client = algoliasearch('NAH884LD6O', '155ad86e58cb50ae7eefab00df3bc15d');
    const index = client.initIndex('zoomcartlive');
    index.search(props)
        .then(async ({ hits }) => {
            console.log(JSON.stringify(hits))
            let a = hits.find(e => e.item_code == props)
            resolve(a)
        })
        .catch(e=>{
            reject('ERROR-IN-ALGOLIA-PRODUCT-SEARCH-PART-API')
        })
})