import React, { useEffect, useState, useContext } from 'react'
import Header from '../../component/header/Header'
import Footer from '../../component/footer/Footer'
import validator from 'validator'
import Loading from '../../component/loading/Loading'
import BasicButton from '../../component/atom/basicButton'
import './contact.css'
import { GlobalContext } from '../../Context/Provider'
import { Feedback } from '../../Context/actions/Information/information'


const Contactus = () => {
    // const { Feedback, state } = useContext(FeedbackContext)
    const {
        informDispatch,
        informState: {
          inform: { inform_loading, carousel },
        },
      } = useContext(GlobalContext);
    const [loading, setloading] = useState(false)
    const [name, setname] = useState('')
    const [phone, setphone] = useState('')
    const [email, setemail] = useState('')
    const [submitbtn, setsubmitbtn] = useState(true)
    const [validName, setvalidName] = useState('a')
    const [validEmail, setvalidEmail] = useState('a')
    const [validPhone, setvalidPhone] = useState('a')
    const [validmessage, setvalidmessage] = useState('a')
    const [message, setmessage] = useState('')


    useEffect(() => {
        if (validEmail == '' && validName == '' && validPhone == '' && validmessage == '') {
            setsubmitbtn(false)
        }
    })
    useEffect(() => {
        setloading(inform_loading)
    })
    const validationform = (e) => {
        if (e.name == 'email') {
            if (validator.isEmail(e.value)) {
                setvalidEmail('')
            } else {
                setvalidEmail('emailvalid')
                setsubmitbtn(true)
            }
        }
        else
            if (e.name == 'phone') {
                if (validator.isMobilePhone(e.value, 'en-IN')) {
                    setvalidPhone('')
                } else {
                    setvalidPhone('phonevalid')
                    setsubmitbtn(true)
                }
            }
            else
                if (e.name == 'message') {
                    var validmessage = /[a-zA-Z]+\s+[a-zA-Z]+/g;
                    if (validmessage.test(e.value)) {

                        setvalidmessage('')
                    } else {
                        setvalidmessage('messagevalid')
                        setsubmitbtn(true)
                    }
                } else
                    if (e.name == 'name') {
                        const accname = new RegExp('[a-z]{3}');
                        if (accname.test(e.value)) {
                            setvalidName('')
                        } else {
                            setvalidName('name')
                            setsubmitbtn(true)
                        }
                    }
    }
    const validation_onchange_form = (e) => {
        if (validEmail == 'emailvalid') {
            if (e.name == 'email') {
                const emailvalidator = new RegExp('/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/')
                if (validator.isEmail(e.value)) {
                    setvalidEmail('')
                } else {
                    setvalidEmail('emailvalid')
                    setsubmitbtn(true)
                }
            }
        }
        else
            if (validPhone == 'phonevalid') {
                if (e.name == 'phone') {
                    if (validator.isMobilePhone(e.value, 'en-IN')) {

                        setvalidPhone('')
                    } else {
                        setvalidPhone('phonevalid')
                        setsubmitbtn(true)
                    }
                }
            }
            else
                if (validmessage == 'messagevalid') {
                    if (e.name == 'message') {
                        var valid_message = /[a-zA-Z]+\s+[a-zA-Z]+/g;
                        if (valid_message.test(e.value)) {

                            setvalidmessage('')
                        } else {
                            setvalidmessage('messagevalid')
                            setsubmitbtn(true)
                        }
                    }
                } else
                    if (validName == 'name') {
                        if (e.name == 'name') {
                            const accname = new RegExp('[a-z]{3}');
                            if (accname.test(e.value)) {
                                setvalidName('')
                            } else {
                                setvalidName('name')
                                setsubmitbtn(true)
                            }
                        }
                    }
    }


    return (
        <div>
            {loading ? <Loading /> : ""}
            <Header />
           
            <div className="container">

                <div className="my-4">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 px-5">
                            <div className="  np-mob">

                                <h1 className="h1 royal-blue-text text-align-left">Get in Touch</h1>
                                <div><p className="banner-desc  contact-para">Need to touch with us? Either fill out the form with your inquiry , bug reports or Feedback -- we're here for it all. </p></div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 pr-5 mob-cont-2">
                            <h4 className="mob-con ">Message Us</h4>
                            <form >
                                <div className='row mx-1'>
                                    <div className='col-12 np-mob pr-1 pl-0 mb-2 col-sm-6 name-inpt'>
                                        <input value={name} onChange={e => { validation_onchange_form(e.target); setname(e.target.value) }} name='name' className='col-12' placeholder='Name*' onBlur={(e) => validationform(e.target)} />
                                        {validName == 'name' ?
                                            <label className='valid-error'>Name is too short.full name must contain minimum 3 charachter</label>
                                            : " "}


                                    </div>
                                    <div className='col-12 np-mob pl-1 mb-2 px-0 col-sm-6'>
                                        <input value={phone} onChange={e => { validation_onchange_form(e.target); setphone(e.target.value) }} name='phone' onBlur={(e) => validationform(e.target)} className='col-12' placeholder='Phone*' />
                                        {validPhone == 'phonevalid' ?
                                            <label className='valid-error'>Enter valid Phone Number</label>
                                            : ' '}
                                    </div>
                                    <div className='col-12 np-mob pl-1 mb-2 px-0'>
                                        <input value={email} onChange={e => { validation_onchange_form(e.target); setemail(e.target.value) }} name='email' onBlur={(e) => validationform(e.target)} className='col-lg-12 col-sm-12 pb-1' placeholder='Email*' />
                                        {validEmail == 'emailvalid' ?

                                            <label className='valid-error'>Enter valid email address </label>
                                            : ' '}
                                    </div>
                                </div>
                                <h4 className='mt-4 mb-3'>Message</h4>
                                <div>
                                    <textarea onChange={e => { validation_onchange_form(e.target); setmessage(e.target.value)  }} value={message} name='message' onBlur={(e) => validationform(e.target)} placeholder='Write in here*' rows='7' className='col-lg-12 col-sm-12 mb-3  input-border' />
                                    {validmessage == 'messagevalid' ?

                                        <label className='valid-error'> Message is too short. minimum two word contian</label>
                                        : ' '}
                                </div>
                                <BasicButton name="SEND MESSAGE" function_call={() => Feedback(phone, email, message, name)(informDispatch)} submitbtn={submitbtn} />
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Contactus;


