import {
  getcart,
  carts,
  cart_status,
  stock_data,
  stock_error,
  cart_loading,
  cart_name

} from '../../actionTypes';

const cart = (state, { payload, type }) => {
  switch (type) {
    case cart_loading:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_loading: payload,
        },
      };

    case stock_error:
      return {
        ...state,
        cart: {
          ...state.cart,
          error: payload,
          //cart_loading: true,
        },
      };
    case stock_data:
      return {
        ...state,
        cart: {
          ...state.cart,
          stock: payload,
        },
      };
    case cart_status:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_status: payload,
        },
      };
    case carts:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_data: payload,
        },
      };
    case getcart:
      return {
        ...state,
        cart: {
          ...state.cart,
          get_data: payload,
        },
      };
    case cart_name:
      return {
        ...state,
        cart: {
          ...state.cart,
          cartname: payload,
        },
      };
    // case NAVIGATION_LOADING:
    //   return {
    //     ...state,
    //     nav_load: {
    //       ...state.cart,
    //       data: payload,
    //     },
    //   };

    // case ADDED_TO_CART:
    //   return {
    //     ...state,
    //     cart: {
    //       ...state.cart,
    //      cart_loading: false,
    //       id_cart: payload,
    //     },
    //   };

    //   case VIEW_CART:
    //   return {
    //     ...state,
    //     cart: {
    //       ...state.cart,
    //      cart_loading: false,
    //       data: payload,
    //     },
    //   };

    //   case REMOVING_FROM_CART:
    //   return {
    //     ...state,
    //     cart: {
    //       ...state.cart,
    //       error: false,
    //      cart_loading: true,
    //     },
    //   };

    //   case REMOVED_FROM_CART:
    //     return {
    //       ...state,
    //       cart: {
    //         ...state.cart,
    //        cart_loading: false,
    //         data: payload,
    //       },
    //     };

    //     case QUANTITY_UPDATING:
    //   return {
    //     ...state,
    //     cart: {
    //       ...state.cart,
    //       error: false,
    //      cart_loading: true,
    //     },
    //   };

    //   case QUANTITY_UPDATED:
    //     return {
    //       ...state,
    //       cart: {
    //         ...state.cart,
    //        cart_loading: false,
    //         data: payload,
    //       },
    //     };

    // case ADD_TO_CART_ERROR:
    //   return {
    //     ...state,
    //     cart: {
    //       ...state.cart,
    //      cart_loading: false,
    //       error: payload,
    //     },
    //   };
    default:
      return state;
  }
};

export default cart;
