import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { GlobalContext } from '../../Context/Provider';
import createHistory from 'history/createBrowserHistory'
import Axios from 'axios'
import { getCart } from '../../Context/actions/cart/cart';
import './orderconfirmation.css'
import 'react-accessible-accordion/dist/fancy-example.css';
import Header from '../../component/header/Header'
import Footer from '../../component/footer/Footer'
import { BASE } from '../../api/api';
const OrderConfirmation = () => {
    const historys = createHistory()

    let history = useHistory()
    const {
        addressDispatch,
        addressState: {
            address: { address, deladdress },
        },
        cartDispatch,
        cartState: {
            cart: { cart_status, cart_loading, cartname, getcart, get_data },
        },
        paymentDispatch,
        paymentState: {
            payment: { pay_loading }
        }
    } = useContext(GlobalContext);
    const [html, sethtml] = useState('')
    const [result, setresult] = useState('Loading')
    const [sales_id, setsales_id] = useState('')
    const [amt_, setamt_] = useState('Loading')

    function oder_confirmation() {
        let order__id = localStorage.getItem("order_id")
        var user_tocken = localStorage.getItem('tocken')

        var data = JSON.stringify(
            {
                "order_id": order__id
            }
        );


        var config = {
            method: 'post',
            url: BASE+'/api/method/afs_integration.afs_integration.doctype.afs_settings.afs_settings.payment_status',
            headers: {
                'Authorization': `Bearer ${user_tocken}`,
                'Content-Type': 'application/json',
            },
            data: data
        };
        Axios(config)
            .then(function (response) {
                setresult(response.data.message.result)
                setsales_id(response.data.message.reference)
                setamt_(response.data.message.merchantAmount)
                // setTimeout(() => {
                //     history.push('/')
                // }, 15000);
            })
            .catch(function (error) {
                setresult("ERROR")
                // setTimeout(() => {
                //     history.push('/')
                // }, 15000);
            });


    }


    useEffect(() => {
        getCart()(cartDispatch);
        const script = document.createElement("script");
        script.src = "https://afs.gateway.mastercard.com/checkout/version/61/checkout.js";
        script.async = true;
        document.head.appendChild(script);
        if (localStorage.getItem("order_id") != null) {
            oder_confirmation()
        }
    }, []);
    useEffect(() => {
        historys.listen((location, action) => {

        });
        if (get_data) {
            sethtml(get_data.shipping_address)
        }
        if (localStorage.getItem("order_id") != null) {
        } else {
        }
    });


    return (
        <div>
            <Header />
            <div id="profile-edit" className="profile-edit">
                <div className="shipping-list-container container ">
                    <h3 className="h3-bold  border-1  border-dark border-bottom pb-2">Order Status </h3>
                    {
                        result == "Loading" ?
                            (
                                <div></div>
                            )
                            : ""
                    }
                    {
                        result == "ERROR" ?
                            (
                                <div class="border border-1 border-dark rounded bg-light py-5 d-flex align-self-center d-flex justify-content-center align-self-center align-items-center" >
                                    <div class="d-flex w-50 rounded justify-content-center align-self-center align-content-center align-items-center"
                                    >
                                        <div>
                                            <div className='d-flex justify-content-center'>
                                                <div class="circle-border"></div>
                                                <div class="circle">
                                                    <div class="error"></div>
                                                </div>
                                            </div>

                                            <h1 class="order-response-title text-center">Payment Failed</h1>
                                            <br />
                                            <label><Link className="" to="/" href="#"><strong> Click here</strong> </Link>if you are automatically redirect to {window.location.hostname}</label>

                                        </div>
                                    </div>
                                </div>
                            )
                            : ""
                    }
                    {
                        result == "SUCCESS" ?
                            (
                                <div class="border border-1 border-dark rounded bg-light py-5 d-flex align-self-center d-flex justify-content-center align-self-center align-items-center" >
                                    <div class="d-flex w-50 rounded justify-content-center align-self-center align-content-center align-items-center"
                                    >
                                        <div>
                                            <div className='d-flex justify-content-center'>
                                                <div class="check-container">
                                                    <div class="check-background">
                                                        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <div class="check-shadow"></div>
                                                </div>
                                            </div>

                                            <h1 class="order-response-title text-center">Order Success</h1>
                                            <br />

                                            <p class="order-reponse-desc pb-5">Your payment of {amt_}BHD for sales order id {sales_id} on Zoomcart.com was successful.</p>
                                            <br />
                                            <label><Link className="text-center" to="/" href="#"><strong> Click here</strong> </Link>if you are not automatically redirect to {window.location.hostname}</label>
                                        </div>
                                    </div>
                                </div>
                            )
                            : ""
                    }

                </div>

            </div>
            <Footer />
        </div>
    );
}

export default OrderConfirmation;
